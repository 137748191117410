import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CKEditorModule } from 'ngx-ckeditor';

import { DocumentEditFormComponent } from './document-edit-form/document-edit-form.component';
import { DocumentFormComponent } from './document-form/document-form.component';
import { DocumentsListComponent } from './documents-list/documents-list.component';
import { DocumentsRoutingModule } from './documents-routing.module';

@NgModule({
    imports: [
        CommonModule,
        DocumentsRoutingModule,
        CommonModule,
        NgxDatatableModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgbModule,
        CKEditorModule,
        TranslateModule
    ],
    declarations: [DocumentsListComponent, DocumentFormComponent, DocumentEditFormComponent],
    exports: [DocumentsListComponent]
})

export class DocumentsModule {}
