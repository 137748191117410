import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { DomainsHostingsDataService } from '../services/domains-hostings-data.service';

@Component({
  selector: 'app-set-ns',
  templateUrl: './set-ns.component.html',
  styleUrls: ['./set-ns.component.scss']
})
export class SetNsComponent implements OnInit {

    public domainName;
    public nsControls = [];
    public nsList = [];

    public typicalNs = [
        {
            title: 'default', data: [
                { name: 'ns.master.parkovka.ua', index: 'ns1' },
                { name: 'ns.parkovka.ua', index: 'ns2' },
                { name: 'ns2.parkovka.ua', index: 'ns3' }
            ], localName: 'DOMAINS.SET_TYPICAL_NS'
        },
        {
            title: 'parkovka', data: [
                { name: 'ns1.vhost1-de.parkovka.ua', index: 'ns1' },
                { name: 'ns2.vhost1-de.parkovka.ua', index: 'ns2' }
            ], localName: 'DOMAINS.SET_PARKOVKA_NS'
        },
        {
            title: 'imena', data: [
                { name: 'ns1.imena.com.ua', index: 'ns1' },
                { name: 'ns2.imena.com.ua', index: 'ns2' },
                { name: 'ns3.imena.com.ua', index: 'ns3' }
            ], localName: 'DOMAINS.SET_IMENA_NS'
        },
        {
            title: 'ukraine', data: [
                { name: 'ns14.inhostedns.com', index: 'ns1' },
                { name: 'ns24.inhostedns.net', index: 'ns2' },
                { name: 'ns34.inhostedns.org', index: 'ns3' }
            ], localName: 'DOMAINS.SET_UKRAINE_NS'
        }
    ];

    constructor(public service: DomainsHostingsDataService,
        public activeModal: NgbActiveModal,
        public toastr: ToastrService,
        public spinner: NgxSpinnerService,
        public translate: TranslateService
    ) { }

    ngOnInit() {
        this.service.getDomainDetails(this.domainName).subscribe(
            data => {
                console.log(data);
                if (data.epp && data.epp.ns) {
                    for (const ns of data.epp.ns) {
                        this.nsControls.push(new FormControl(ns.name));
                    }
                    this.nsList = data.epp.ns;
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    public setTypical(item) {
        const newControls = [];
        const newNsList = [];
        for (const ns of item.data) {
            newControls.push(new FormControl(ns.name));
            newNsList.push({ name: ns.name, index: ns.index });
        }
        this.nsControls = newControls;
        this.nsList = newNsList;
    }

    public addNsRow() {
        this.nsControls.push(new FormControl());
    }

    public deleteNsRow(index) {
        this.nsControls = this.nsControls.filter((v, i) => i !== index);
    }

    public save() {
        this.spinner.show();
        const result = [];
        for (const control of this.nsControls) {
            result.push({ name: control.value });
        }
        this.service.setNs(this.domainName, {
            ns: result
        }).subscribe(
            data => {
                this.spinner.hide();
                this.translate.get('DOMAINS.NS_SET').subscribe((res: string) => {
                    this.toastr.success(res);
                });
                console.log(data);
            },
            err => {
                this.spinner.hide();
                console.log(err);
                if (err.status === 400) {
                    this.translate.get('DOMAINS.WRONG_NS').subscribe((res: string) => {
                        this.toastr.error(`${res}\n${err.error ? err.error.message : ''}`);
                    });
                } else {
                    this.translate.get('DOMAINS.ERROR_SETTING_NS').subscribe((res: string) => {
                        this.toastr.error(`${res}\n${err.error ? err.error.message : ''}`);
                    });
                }
            }
        );
    }

    public close() {
        this.activeModal.close();
    }
}
