import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { UserEditorComponent } from '../../shared/components/user-editor/user-editor.component';
import { UserListService } from '../services/user-list.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  public filter_form: FormGroup;
  
  @ViewChild('operationsTmpl', {static: true})
  opTmpl: TemplateRef<any>;
  @ViewChild('headerTmpl', {static: true}) 
  headerTmpl: TemplateRef<any>;

  public rows = [];
  public columns = [];
  public page = '0';
  
  constructor(
    public service: UserListService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    public formBuilder: FormBuilder
  ) {
    this.filter_form = this.formBuilder.group({
        fio: [''],
        phone: [''],
        mail: ['']
    });

    const filters = this.service.filters;
    if (filters) {
        this.filter_form.patchValue(filters, { emitEvent: false });
    }
  }

  ngOnInit() {
    this.columns = [
        { headerTemplate: this.headerTmpl, name: 'UTILS.FULL_NAME', prop: 'fullname', maxWidth: 100 },
        { headerTemplate: this.headerTmpl, name: 'COMMON.PHONE', prop: 'field_phone' },
        { headerTemplate: this.headerTmpl, name: 'Email', prop: 'mail' },
        {
            headerTemplate: this.headerTmpl, 
            name: 'COMMON.OPERATIONS',
            cellTemplate: this.opTmpl,
            maxWidth: 180,
            cellClass: 'dropmenu'
        }
    ];

    this.service.items.subscribe(items => {
      console.log(items);
      this.rows = items;
    });

    this.route.queryParams.subscribe(params => {
        this.page = params['page'];
        this.goForIt();
    });

    this.filter_form.valueChanges
      .pipe(
          debounceTime(400),
          distinctUntilChanged()
      )
      .subscribe(values => {
          console.log(values);
          this.page = '0';
          this.goForIt();
      });
  }

  goForIt() {
    const views_uri = '/admin/profit/users-full/list/';
    let filters = {};
    filters = this.filter_form.value;
    this.service.filters = this.filter_form.value;
    console.log(filters);
    this.service.fetch(this.page, '', filters, views_uri);
  }

  fetchPage(pager) {
    this.page = pager.offset;
    this.goForIt();

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
          page: pager.offset
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  openEditor(user?) {
    if (user) {
      this.router.navigate(['/users', 'view', user.uid]);
    } else {
      const modalRef = this.modalService.open(UserEditorComponent, {backdrop: 'static'});
      modalRef.result.then(
          result => {
              this.goForIt();
          },
          reason => {
              console.log(reason);
          }
      );
    }
  }

  clearFilters() {
    this.filter_form.reset();
  }
}
