import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-store';

import { BaseService } from '../../shared/services/base.service';

@Injectable({
  providedIn: 'root'
})

export class UserServicesService  extends BaseService {
  static ngInjectableDef = undefined;
  

  constructor(public http: HttpClient, public storage: LocalStorageService) {
    super(http, storage)
  }

  public fetchServices(path, page = '0', filters = {}) {
    return this.http.get<any>(this.host + path, {params: { _format: 'json', page, ...filters}});
  }
}
