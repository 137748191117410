import { IntlTelInputNgModule } from "intl-tel-input-ng";
import { CKEditorModule } from "ngx-ckeditor";
import { NgxSpinnerModule } from "ngx-spinner";
import { WebStorageModule } from "ngx-store";
import { ToastrModule } from "ngx-toastr";
import { UiSwitchModule } from "ngx-ui-switch";

import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
} from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JwtModule } from "@auth0/angular-jwt";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { LoginModule } from "./login/login.module";
import { MailModule } from "./mail/mail.module";
import { LoaderInterceptor } from "./shared/LoaderInterceptor";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import { AuthService } from "./shared/auth/auth.service";
import { SafeHtmlPipe } from "./shared/pipe/safe-html.pipe";
import { SharedModule } from "./shared/shared.module";
import { UsersModule } from "./users/users.module";

export function tokenGetter() {
    return localStorage.getItem("access_token_crm");
}

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
];

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        SharedModule,
        HttpClientModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: ["ideabox.name", "localhost"],
                disallowedRoutes: [],
            },
        }),
        ToastrModule.forRoot(),
        NgbModule,
        LoginModule,
        NgSelectModule,
        UiSwitchModule,
        AppRoutingModule,
        IntlTelInputNgModule.forRoot(),
        NgxSpinnerModule,
        MailModule,
        CKEditorModule,
        WebStorageModule.forRoot(),
        UsersModule,
    ],
    providers: [AuthService, AuthGuard, httpInterceptorProviders],
    bootstrap: [AppComponent],
})
export class AppModule {}
