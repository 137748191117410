import { NgxSpinnerService } from "ngx-spinner";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { TableColumn } from "@swimlane/ngx-datatable";

import { ToastrService } from "../../../../node_modules/ngx-toastr";
import { AuthService } from "../../shared/auth/auth.service";
import { DrupalizeObject } from "../../shared/common/drupalize-object";
import { UnDrupalizeObject } from "../../shared/common/undrupalize-object";
import { Application } from "../../trademarks/models/ApplicationsListData";
import { DocumentEditFormComponent } from "../document-edit-form/document-edit-form.component";
import { DocumentFormComponent } from "../document-form/document-form.component";
import { Document } from "../models/Document";
import { GetDocumentsService } from "../services/get-documents.service";

@Component({
    selector: "app-documents-list",
    templateUrl: "./documents-list.component.html",
    styleUrls: ["./documents-list.component.scss"],
})
export class DocumentsListComponent implements OnInit {
    @Input()
    appId: string;
    @Input()
    serviceType: string;
    @Input()
    serviceId: string;
    @Input()
    userId = null;

    @Output()
    addedComment = new EventEmitter<any>();

    @ViewChild("operationsTmpl", { static: true })
    opTmpl: TemplateRef<any>;
    @ViewChild("tmNameTmpl", { static: true })
    tmNameTmpl: TemplateRef<any>;
    @ViewChild("typeTmpl", { static: true })
    typeTmpl: TemplateRef<any>;
    @ViewChild("dateTmpl", { static: true })
    dateTmpl: TemplateRef<any>;
    @ViewChild("vchasnoStatus", { static: true })
    vchasnoStatus: TemplateRef<any>;
    @ViewChild("headerTmpl", { static: true })
    headerTmpl: TemplateRef<any>;

    public filter_form: FormGroup;
    public page = "0";
    public rows = [];
    public columns: Array<TableColumn>;
    public views_uri = "/admin/profit/document/list/";
    public types = [{ label: "All", value: "All" }];
    public vchasnoTypes = [
        { label: "DOCUMENTS.VCHASNO_ALL", value: "all" },
        { label: "DOCUMENTS.VCHASHO_SIGNED", value: "1" },
        { label: "DOCUMENTS.VCHASNO_NOT_SIGNED", value: "0" },
    ];

    public urgent = new FormControl(false);

    public skip_filters = false;
    public includeFilters;

    constructor(
        public docsService: GetDocumentsService,
        public route: ActivatedRoute,
        public router: Router,
        public formBuilder: FormBuilder,
        public modalService: NgbModal,
        public toastr: ToastrService,
        public spinner: NgxSpinnerService,
        public authService: AuthService,
        public translate: TranslateService
    ) {
        this.filter_form = this.formBuilder.group({
            contain: [""],
            type: ["All", Validators.required],
            uid: [""],
            vchasno_id: [false],
            vchasno_signed: ["all"],
        });

        const filters = this.docsService.filters;
        if (filters) {
            this.filter_form.patchValue(filters);
            this.urgent.patchValue(filters.urgent);
        }
    }

    ngOnInit() {
        this.docsService.items.subscribe((items) => {
            this.rows = items;
        });
        this.route.queryParams.subscribe((params) => {
            this.page = params["page"];
            this.goForIt();

            if (params["n"] && params["q"] && params["t"]) {
                const doc_data = {
                    tm_name: params["n"],
                    application_id: params["q"],
                    service: params["q"],
                    reference: {
                        target_type: "application",
                        target_id: params["q"],
                        target_label: params["n"],
                    },
                    type: params["t"],
                };
                const modalRef = this.modalService.open(DocumentFormComponent, {
                    size: "lg",
                    backdrop: "static",
                });
                modalRef.componentInstance.document = new Document(doc_data);
                modalRef.result.then(
                    (result) => {
                        this.router.navigate([]);
                    },
                    (reason) => {
                        console.log(reason);
                    }
                );
            }
        });

        this.docsService.getTypes().subscribe(
            (m) => (this.types = m),
            (e) => console.log(e)
        );

        if (this.appId) {
            this.columns = [
                {
                    headerTemplate: this.headerTmpl,
                    name: "DOCUMENTS.COL_TYPE",
                    prop: "type_name",
                    cellTemplate: this.typeTmpl,
                },
                {
                    headerTemplate: this.headerTmpl,
                    name: "DOCUMENTS.COL_DATE",
                    prop: "document_date",
                    canAutoResize: false,
                    cellTemplate: this.dateTmpl,
                },
                {
                    headerTemplate: this.headerTmpl,
                    name: "DOCUMENTS.COL_ACTIONS",
                    cellTemplate: this.opTmpl,
                    minWidth: 255,
                    cellClass: "dropmenu",
                    canAutoResize: false,
                },
            ];
        } else {
            this.columns = [
                {
                    headerTemplate: this.headerTmpl,
                    name: "DOCUMENTS.COL_TYPE",
                    prop: "type_name",
                    cellTemplate: this.typeTmpl,
                },
                {
                    headerTemplate: this.headerTmpl,
                    name: "DOCUMENTS.COL_APP_NUMBER",
                    prop: "application_number",
                    canAutoResize: false,
                },
                {
                    headerTemplate: this.headerTmpl,
                    name: "DOCUMENTS.COL_APP",
                    prop: "tm_name",
                    cellTemplate: this.tmNameTmpl,
                },
                {
                    headerTemplate: this.headerTmpl,
                    name: "DOCUMENTS.COL_UPLOADED",
                    prop: "document_date",
                    canAutoResize: false,
                },
                {
                    headerTemplate: this.headerTmpl,
                    name: "DOCUMENTS.COL_CREATED",
                    prop: "created",
                    canAutoResize: false,
                },
                {
                    headerTemplate: this.headerTmpl,
                    name: "DOCUMENTS.COL_ACTIONS",
                    cellTemplate: this.opTmpl,
                    minWidth: 255,
                    cellClass: "dropmenu",
                    canAutoResize: false,
                },
            ];
        }

        this.filter_form.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe((values) => {
                this.page = "0";
                this.goForIt();
            });

        this.urgent.valueChanges.subscribe(
            (data) => {
                this.page = "0";
                this.goForIt();
            },
            (err) => console.log(err)
        );
    }

    goForIt() {
        let filters = { ...this.filter_form.value };
        if (this.skip_filters) {
            console.log("Filters skiped");
        }
        if (
            typeof this.appId === "undefined" &&
            typeof this.serviceId === "undefined"
        ) {
            this.docsService.filters = this.filter_form.value;
        } else {
            filters = {
                uid: "",
                contain: "",
                type: "",
            };
        }

        if (this.skip_filters) {
            filters = {
                uid: "",
                contain: "",
                type: "",
            };
        }
        if (!this.skip_filters && this.includeFilters) {
            filters = {};
            const filterFormValue = this.filter_form.value;
            for (const key in filterFormValue) {
                if (this.includeFilters.indexOf(key) !== -1) {
                    filters[key] = filterFormValue[key];
                }
            }
        }
        if (filters && filters["vchasno_id"] === false) {
            delete filters["vchasno_id"];
        } else if (filters && filters["vchasno_id"]) {
            filters["vchasno_id"] = "false";
        }
        if (filters && filters["vchasno_signed"] === "all") {
            delete filters["vchasno_signed"];
        }
        if (this.serviceType) {
            this.docsService.fetchForService(
                this.page,
                this.serviceId,
                this.serviceType,
                this.views_uri,
                filters
            );
        } else {
            this.docsService.fetch(
                this.page,
                this.appId,
                this.views_uri,
                filters,
                this.urgent.value ? "urgent7" : ""
            );
        }
    }

    fetchPage(pager) {
        this.page = pager.offset;
        this.goForIt();

        if (typeof this.appId === "undefined") {
            // Set page
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                    page: pager.offset,
                },
                queryParamsHandling: "merge",
                skipLocationChange: false,
            });
        }
    }

    openEditor(doc?: Document) {
        if (
            typeof this.serviceId !== "undefined" &&
            typeof doc === "undefined"
        ) {
            this.docsService.getOne(this.serviceId, this.serviceType).subscribe(
                (data) => {
                    const service: any = UnDrupalizeObject.format(data);
                    const doc_data = {
                        tm_name: service.tm_name ? service.tm_name : "",
                        country: service.country ? service.country : "",
                        reference: {
                            target_type: this.serviceType,
                            target_id: this.serviceId,
                            target_label: service.tm_name
                                ? service.tm_name
                                : service.small_title
                                ? service.small_title
                                : "",
                        },
                    };
                    const modalRef = this.modalService.open(
                        DocumentFormComponent,
                        { backdrop: "static", size: "lg" }
                    );
                    modalRef.componentInstance.document = new Document(
                        doc_data
                    );
                    modalRef.componentInstance.applicationType = service.type?.target_id;
                    modalRef.result.then(
                        (result) => {
                            this.goForIt();
                        },
                        (reason) => {
                            this.goForIt();
                        }
                    );
                },
                (e) => console.log(e)
            );
        } else if (
            typeof this.appId !== "undefined" &&
            typeof doc === "undefined"
        ) {
            this.docsService.getOne(this.appId, "application").subscribe(
                (data) => {
                    const application = new Application(
                        UnDrupalizeObject.format(data)
                    );
                    const doc_data = {
                        tm_name: application.tm_name,
                        application_id: this.appId,
                        reference: {
                            target_type: "application",
                            target_id: this.appId,
                            target_label: application.tm_name,
                        },
                    };
                    const modalRef = this.modalService.open(
                        DocumentFormComponent,
                        { size: "lg", backdrop: "static" }
                    );
                    modalRef.componentInstance.document = new Document(
                        doc_data
                    );
                    modalRef.result.then(
                        (result) => {
                            this.goForIt();
                        },
                        (reason) => {
                            this.goForIt();
                        }
                    );
                },
                (e) => console.log(e)
            );
        } else {
            const modalRef = this.modalService.open(DocumentFormComponent, {
                size: "lg",
                backdrop: "static",
            });
            modalRef.componentInstance.document = doc;
            modalRef.result.then(
                (result) => {
                    this.goForIt();
                },
                (reason) => {
                    this.goForIt();
                }
            );
        }
    }

    viewDoc(doc: Document) {
        window.open(doc.fileViewURL, "_blank");
    }

    deleteDoc(doc) {
        this.docsService.delete(doc.id, "document").subscribe(
            (m) => {
                this.translate
                    .get("DOCUMENTS.DOCUMENT_DELETED")
                    .subscribe((res: string) => {
                        this.toastr.success(res);
                    });
                this.goForIt();
            },
            (e) => {
                this.translate
                    .get("DOCUMENTS.DOCUMENT_NOT_DELETED")
                    .subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                this.toastr.error(e.error ? e.error.message : "");
                console.log(e);
            }
        );
    }

    sendDoc(doc) {
        this.docsService
            .operation(doc.id, "sendDocument", "document")
            .subscribe(
                (m) => {
                    this.translate
                        .get("DOCUMENTS.DOCUMENTS_SENT")
                        .subscribe((res: string) => {
                            this.toastr.success(res);
                        });
                    this.goForIt();
                },
                (e) => {
                    this.translate
                        .get("DOCUMENTS.ERROR_SENDING_DOCUMENT")
                        .subscribe((res: string) => {
                            this.toastr.error(res);
                        });
                    this.toastr.error(e.error ? e.error.message : "");
                    console.log(e);
                }
            );
    }

    createRequest() {
        this.spinner.show();
        this.docsService
            .operation(Number(this.appId), "createZesRequest", "application")
            .subscribe(
                (m) => {
                    this.spinner.hide();
                    this.translate
                        .get("DOCUMENTS.REQUEST_CREATED")
                        .subscribe((res: string) => {
                            this.toastr.success(res);
                        });
                    this.goForIt();
                },
                (e) => {
                    this.spinner.hide();
                    this.translate
                        .get("DOCUMENTS.ERROR_CREATING_REQUEST")
                        .subscribe((res: string) => {
                            this.toastr.error(res);
                        });
                    this.toastr.error(e.error ? e.error.message : "");
                    console.log(e);
                }
            );
    }

    sendNotifications(doc) {
        this.docsService
            .operation(doc.id, "sendNotifications", "document")
            .subscribe(
                (m) => {
                    this.translate
                        .get("DOCUMENTS.DOCUMENTS_SENT")
                        .subscribe((res: string) => {
                            this.toastr.success(res);
                        });
                    this.goForIt();
                },
                (e) => {
                    this.translate
                        .get("DOCUMENTS.ERROR_SENDING_DOCUMENT")
                        .subscribe((res: string) => {
                            this.toastr.error(res);
                        });
                    this.toastr.error(e.error ? e.error.message : "");
                    console.log(e);
                }
            );
    }

    resetReminers(doc) {
        this.docsService
            .operation(doc.id, "resetReminers", "document")
            .subscribe(
                (m) => {
                    this.translate
                        .get("DOCUMENTS.REMINDER_UPDATED")
                        .subscribe((res: string) => {
                            this.toastr.success(res);
                        });
                    this.goForIt();
                },
                (e) => {
                    this.translate
                        .get("DOCUMENTS.ERROR_UPDATING_REMINDER")
                        .subscribe((res: string) => {
                            this.toastr.error(res);
                        });
                    this.toastr.error(e.error ? e.error.message : "");
                    console.log(e);
                }
            );
    }

    generateFile(doc) {
        this.spinner.show();
        this.docsService
            .operation(doc.id, "generateFile", "document")
            .subscribe(
                (m) => {
                    this.translate
                        .get("DOCUMENTS.DOCUMENT_GENERATED")
                        .subscribe((res: string) => {
                            this.toastr.success(res);
                        });
                    this.spinner.hide();
                    this.goForIt();
                },
                (e) => {
                    this.translate
                        .get("DOCUMENTS.ERROR_GENERATING_DOCUMENT")
                        .subscribe((res: string) => {
                            this.toastr.error(res);
                        });
                    this.toastr.error(e.error ? e.error.message : "");
                    console.log(e);
                    this.spinner.hide();
                }
            );
    }

    uploadToZes(doc) {
        this.spinner.show();
        this.docsService
            .operation(doc.id, "uploadToSftp", "document")
            .subscribe(
                (m) => {
                    this.translate
                        .get("DOCUMENTS.UPLOADED_TO_ZES")
                        .subscribe((res: string) => {
                            this.toastr.success(res);
                        });
                    this.spinner.hide();
                    this.goForIt();
                },
                (e) => {
                    this.translate
                        .get("DOCUMENTS.ERROR_UPLOADING_TO_ZES")
                        .subscribe((res: string) => {
                            this.toastr.error(res);
                        });
                    this.toastr.error(e.error ? e.error.message : "");
                    console.log(e);
                    this.spinner.hide();
                }
            );
    }

    makeSeen(doc) {
        this.spinner.show();
        this.docsService.operation(doc.id, "seen", "document").subscribe(
            (m) => {
                this.translate
                    .get("DOCUMENTS.MARKED_AS_SEEN")
                    .subscribe((res: string) => {
                        this.toastr.success(res);
                    });
                this.spinner.hide();
                this.goForIt();
            },
            (e) => {
                this.translate
                    .get("DOCUMENTS.ERROR_CHANGING_STATUS")
                    .subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                this.toastr.error(e.error ? e.error.message : "");
                console.log(e);
                this.spinner.hide();
            }
        );
    }

    setStates(doc) {
        this.spinner.show();
        this.docsService.operation(doc.id, "setStates", "document").subscribe(
            (m) => {
                this.translate
                    .get("DOCUMENTS.APP_STATUS_SET")
                    .subscribe((res: string) => {
                        this.toastr.success(res);
                    });
                this.spinner.hide();
                this.goForIt();
            },
            (e) => {
                this.translate
                    .get("DOCUMENTS.ERROR_SETTING_APP_STATUS")
                    .subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                this.toastr.error(e.error ? e.error.message : "");
                console.log(e);
                this.spinner.hide();
            }
        );
    }

    generateApplicationDocs() {
        if (typeof this.appId !== "undefined") {
            this.spinner.show();
            this.docsService
                .operation(+this.appId, "generateContract", "application")
                .subscribe(
                    (m) => {
                        this.goForIt();
                        this.spinner.hide();
                        this.translate
                            .get("DOCUMENTS.DOCS_CREATED")
                            .subscribe((res: string) => {
                                this.toastr.success(res);
                            });
                    },
                    (e) => {
                        this.translate
                            .get("DOCUMENTS.ERROR_GENERATING_DOCUMENT")
                            .subscribe((res: string) => {
                                this.toastr.error(res);
                            });
                        this.toastr.error(e.error ? e.error.message : "");
                        console.log(e);
                    }
                );
        }
    }

    sendDocsToClient() {
        if (typeof this.appId !== "undefined") {
            this.spinner.show();
            this.docsService
                .operation(+this.appId, "sendDocuments", "application")
                .subscribe(
                    (m) => {
                        this.spinner.hide();
                        this.translate
                            .get("DOCUMENTS.DOCUMENTS_SENT")
                            .subscribe((res: string) => {
                                this.toastr.success(res);
                            });
                        this.goForIt();
                    },
                    (e) => {
                        this.spinner.hide();
                        this.translate
                            .get("DOCUMENTS.ERROR_SENDING_DOCUMENT")
                            .subscribe((res: string) => {
                                this.toastr.error(res);
                            });
                        this.toastr.error(e.error ? e.error.message : "");
                        console.log(e);
                    }
                );
        }
    }

    sendToViber(document) {
        this.spinner.show();
        this.docsService
            .operation(document.id, "sendToViber", "document")
            .subscribe(
                (m) => {
                    this.spinner.hide();
                    this.translate
                        .get("DOCUMENTS.POA_SENT")
                        .subscribe((res: string) => {
                            this.toastr.success(res);
                        });

                    this.translate
                        .get("DOCUMENTS.POA_SENT_ON_VIBER")
                        .subscribe((res: string) => {
                            this.toastr.success(res);
                            const message = res;
                            const payload = DrupalizeObject.format({
                                user_id: { target_id: this.authService.uid },
                                message,
                                event_type: "user",
                                service: {
                                    target_type: this.serviceType
                                        ? this.serviceType
                                        : "application",
                                    target_id: this.serviceId
                                        ? this.serviceId
                                        : this.appId,
                                },
                            });
                            this.docsService
                                .save("", payload, "trademark_event")
                                .subscribe(
                                    (m) => {
                                        this.addedComment.emit();
                                    },
                                    (e) => {}
                                );
                            this.goForIt();
                        });
                },
                (e) => {
                    this.spinner.hide();
                    this.translate
                        .get("DOCUMENTS.ERROR_SENDING_DOCUMENT")
                        .subscribe((res: string) => {
                            this.toastr.error(res);
                        });
                    this.toastr.error(e.error ? e.error.message : "");
                    console.log(e);
                }
            );
    }

    fetchDocsFromZes() {
        if (typeof this.appId !== "undefined") {
            this.spinner.show();
            this.docsService.fetchDocsFromZes().subscribe(
                (m) => {
                    this.spinner.hide();
                    this.translate
                        .get("DOCUMENTS.DOCUMENTS_LOADED")
                        .subscribe((res: string) => {
                            this.toastr.success(res);
                        });
                    this.goForIt();
                },
                (e) => {
                    this.translate
                        .get("DOCUMENTS.ERROR_LOADING")
                        .subscribe((res: string) => {
                            this.toastr.error(res);
                        });
                    this.toastr.error(e.error ? e.error.message : "");
                    console.log(e);
                    this.spinner.hide();
                }
            );
        }
    }

    recreateDoc(doc) {
        doc.recreate();
        this.goForIt();
    }

    setFilter(type) {
        this.filter_form.get("type").patchValue(type);
    }

    editDoc(doc) {
        if (typeof doc.document_text === "undefined") {
            // load
            if (typeof doc.id !== "undefined") {
                this.docsService.getOne(doc.id, "document").subscribe(
                    (res) => {
                        const undrupalized: any = UnDrupalizeObject.format(res);
                        const editable_document = new Document(undrupalized);
                        let options: NgbModalOptions = {
                            size: "sm",
                            backdrop: "static",
                        };
                        if (editable_document.editable) {
                            options = { size: "lg", backdrop: "static" };
                        }
                        const modalRef = this.modalService.open(
                            DocumentEditFormComponent,
                            options
                        );
                        modalRef.componentInstance.document = editable_document;
                        modalRef.result.then(
                            (result) => {
                                this.goForIt();
                            },
                            (reason) => {
                                this.goForIt();
                            }
                        );
                    },
                    (e) => {
                        console.log(e);
                    }
                );
            } else {
                this.translate
                    .get("DOCUMENTS.NO_DOC_TO_EDIT")
                    .subscribe((res: string) => {
                        this.toastr.error(res);
                    });
            }
        } else {
            const modalRef = this.modalService.open(DocumentEditFormComponent, {
                size: "lg",
                backdrop: "static",
            });
            modalRef.componentInstance.document = doc;
            modalRef.result.then(
                (result) => {
                    this.goForIt();
                },
                (reason) => {
                    this.goForIt();
                }
            );
        }
    }

    clearFilters() {
        this.filter_form.patchValue(
            {
                contain: "",
                type: "All",
                uid: "",
                vchasno_id: false,
                vchasno_signed: "all",
            },
            { emitEvent: false }
        );
    }

    withCountry(row) {
        const countryTypes = [
            "t_8_nr",
            "t_9_nr",
            "certificat_nr",
            "t_8mr",
            "t_9mr",
            "f_9mr",
            "tt_mr",
            "tt_nr",
        ];
        return countryTypes.includes(row.type) && row.country;
    }
}
