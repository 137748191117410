import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { DrupalizeObject } from '../../shared/common/drupalize-object';
import { GetCountriesService } from '../../shared/services/get-countries.service';
import { Domain } from '../models/Domain';
import { DomainContact } from '../models/DomainContact';
import { DomainsHostingsDataService } from '../services/domains-hostings-data.service';

@Component({
  selector: 'app-domain-details',
  templateUrl: './domain-details.component.html',
  styleUrls: ['./domain-details.component.scss']
})
export class DomainDetailsComponent implements OnInit {
    public domainId;
    public domain;

    public contactId = '';
    public contact;
    public allCountries = [];

    public disclose = false;

    public domain_form: FormGroup;

    public isCollapsedPerson = true;
    public isCollapsedData = true;
    public isCollapsedAddress = true;

    public contactPersonFields = [
        { name: 'COMMON.FULL_NAME', form_name: 'name' },
        { name: 'COMMON.SURNAME', form_name: 'surname' },
        { name: 'COMMON.FORENAME', form_name: 'forename' },
        { name: 'COMMON.PERSON', form_name: 'person' },
        { name: 'COMMON.ORGANIZATION', form_name: 'organization' },
        { name: 'COMMON.COMPANY_CODE', form_name: 'company_code' },
    ]

    public contactFields = [
        { name: 'Email', form_name: 'contact_email' },
        { name: 'COMMON.FAX', form_name: 'fax' },
        { name: 'COMMON.PHONE', form_name: 'phone' },
    ]

    public contactAddressFields = [
        { name: 'COMMON.COUNTRY', form_name: 'country' },
        { name: 'COMMON.REGION', form_name: 'state' },
        { name: 'COMMON.POSTAL_CODE', form_name: 'postal_code' },
        { name: 'COMMON.CITY', form_name: 'city' },
        { name: 'COMMON.STREET', form_name: 'street' },
        { name: 'COMMON.HOUSE', form_name: 'house_num' },
        { name: 'COMMON.APT', form_name: 'flat' },
    ];

    constructor(
        public formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        public domainsService: DomainsHostingsDataService,
        public toastr: ToastrService,
        public spinner: NgxSpinnerService,
        public countriesService: GetCountriesService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        this.domain_form = this.formBuilder.group({
            free_domain: [false],
            certificate_id: [''],
            city: [''],
            company_code: [''],
            contact_email: [''],
            country: [''],
            fax: [''],
            flat: [''],
            forename: [''],
            house_num: [''],
            name: [''],
            organization: [''],
            person: [''],
            phone: [''],
            postal_code: [''],
            state: [''],
            street: [''],
            surname: [''],
        });

        this.countriesService.getAllCountries()
            .subscribe(
                data => {
                    this.allCountries = data;
                },
                err => {
                    console.log(err);
                }
            );

        if (this.domainId) {
            this.domainsService.getOne(this.domainId, 'domain').subscribe(
                data => {
                    console.log(data);
                    this.domain = Domain.getInstanceFromDrupal(data);
                    if (this.domain.domain_contact && this.domain.domain_contact.target_id) {
                        this.contactId = this.domain.domain_contact.target_id;
                        this.domainsService.getOne(this.contactId, 'domain_contact').subscribe(
                            contactData => {
                                this.contact = DomainContact.getInstanceFromDrupal(contactData);
                                console.log(this.contact);
                                console.log(this.contact.disclose);
                                this.domain_form.patchValue(this.contact, { emitEvent: false });
                                if (this.domain.tm) {
                                    this.domain_form.get('certificate_id').patchValue(this.domain.tm, { emitEvent: false });
                                }
                                this.domain_form.get('free_domain').patchValue(this.domain.free_domain, { emitEvent: false });
                            },
                            err => {
                                this.translate.get('DOMAINS.ERROR_LOADING_DOMAIN_DATA').subscribe((res: string) => {
                                    this.toastr.error(res);
                                });
                                this.toastr.error(err.error ? err.error.message : '');
                                console.log(err);
                            }
                        );
                    }
                },
                err => {
                    this.translate.get('DOMAINS.ERROR_LOADING_DOMAIN_DATA').subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                    this.toastr.error(err.error ? err.error.message : '');
                    console.log(err);
                }
            );
        }
    }

    public cancel() {
        this.activeModal.close({ registered: false });
    }

    public save() {
        const formValue = this.domain_form.value;
        let payload = {};
        for (const key in formValue) {
            if (formValue.hasOwnProperty(key) && key !== 'id' && key !== 'certificate_id' && key !== 'free_domain') {
                payload[key] = formValue[key];
            }
        }
        payload = DrupalizeObject.format(payload);
        console.log(payload)
        // save changes in domain's contact
        this.spinner.show();
        this.domainsService.save(this.contact.id, payload, 'domain_contact')
            .subscribe(
                contactData => {
                    console.log(contactData);
                    // if certificate number or free status of domain has changed, save it too
                    const domainTmId = this.domain_form.get('certificate_id').value;
                    const isFree = this.domain_form.get('free_domain').value;
                    if (this.domain.tm !== domainTmId || this.domain.free_domain !== isFree) {
                        const certPayload = {
                            tm: [{ value: domainTmId }],
                            free_domain: [{ value: isFree }]
                        };
                        this.domainsService.save(this.domain.id, certPayload, 'domain')
                            .subscribe(
                                domainData => {
                                    console.log(domainData);
                                    // register domain
                                    this.register();
                                },
                                err => {
                                    this.spinner.hide();
                                    console.log(err);
                                    this.translate.get('DOMAINS.ERROR_SAVING_CERT_NUM').subscribe((res: string) => {
                                        this.toastr.error(res);
                                    });
                                    this.toastr.error(err.error ? err.error.message : '');
                                }
                            );
                    } else {
                        // register domain
                        this.register();
                    }
                },
                err => {
                    this.spinner.hide();
                    console.log(err);
                    this.translate.get('DOMAINS.ERROR_SAVING_CONTACT').subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                    this.toastr.error(err.error ? err.error.message : '');
                }
            );
    }

    public register() {
        this.domainsService.operation(this.domain.id, 'registerDomain', 'domain')
            .subscribe(
                data => {
                    this.spinner.hide();
                    console.log(data);
                    this.translate.get('DOMAINS.DOMAIN_REGISTERED', {domain: this.domain.name}).subscribe((res: string) => {
                        this.toastr.success(res);
                    });
                    this.activeModal.close({ registered: true });
                },
                err => {
                    this.spinner.hide();
                    this.translate.get('DOMAINS.ERROR_REGISTERING_DOMAIN').subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                    this.toastr.error(err.error ? err.error.message : '');
                    console.log(err);
                }
            );
    }
}
