import { DocumentsModule } from "app/documents/documents.module";
import { DomainsHostingsModule } from "app/domains-hostings/domains-hostings.module";
import { InvoicesModule } from "app/invoices/invoices.module";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { UserEditorComponent } from "../shared/components/user-editor/user-editor.component";
import { SharedModule } from "../shared/shared.module";
import { UserResolver } from "./services/user.resolver";
import { UserApplicationsComponent } from "./user-details/user-applications/user-applications.component";
import { UserDetailsComponent } from "./user-details/user-details.component";
import { UserServiceListComponent } from "./user-details/user-service-list/user-service-list.component";
import { UsersListComponent } from "./users-list/users-list.component";
import { UsersRoutingModule } from "./users-routing.module";
import { UsersComponent } from "./users/users.component";

@NgModule({
    imports: [
        CommonModule,
        UsersRoutingModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgxDatatableModule,
        InvoicesModule,
        DomainsHostingsModule,
        DocumentsModule,
    ],
    declarations: [
        UsersListComponent,
        UserDetailsComponent,
        UserApplicationsComponent,
        UserApplicationsComponent,
        UserServiceListComponent,
        UsersComponent,
    ],
    providers: [UserResolver],
    exports: [UsersComponent],
})
export class UsersModule {}
