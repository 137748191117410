import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IntlTelInputNgModule } from 'intl-tel-input-ng';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginPageComponent } from './login-page/login-page.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IntlTelInputNgModule.forRoot(),
        TranslateModule
    ],
    declarations: [LoginPageComponent, ForgotPasswordComponent],
    exports: [LoginPageComponent]
})
export class LoginModule { }
