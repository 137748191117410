import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-mail-client',
    templateUrl: './mail-client.component.html',
    styleUrls: ['./mail-client.component.scss']
})
export class MailClientComponent implements OnInit, OnDestroy {
    wrapper: any;
    constructor(@Inject(DOCUMENT) document) {
        this.wrapper = document.getElementById('main-wrapper');
        this.wrapper.classList.add('nav-collapsed');
        this.wrapper.classList.add('hide-app-navbar');
    }
    ngOnInit() { }

    ngOnDestroy(): void {
        this.wrapper.classList.remove('nav-collapsed');
        this.wrapper.classList.remove('hide-app-navbar');
    }
}
