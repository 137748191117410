import { Component, OnInit } from '@angular/core';
import { BaseService } from 'app/shared/services/base.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-invoice-chooser',
  templateUrl: './invoice-chooser.component.html',
  styleUrls: ['./invoice-chooser.component.scss']
})
export class InvoiceChooserComponent implements OnInit {
  public serviceId;
  public serviceType;
  public invoices = [];

  constructor(
      public activeModal: NgbActiveModal,
      public service: BaseService,
      public toastr: ToastrService,
      public spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.service.getAll(`/admin/profit/invoice/list/${this.serviceType}/${this.serviceId}`).subscribe(
      data => this.invoices = data.rows.map((item) => {
        return {...item, control: new FormControl(false)};
      }),
      err => console.log(err)
    );
  }

  confirmSelection() {
    const selected = [];
    for (const invoice of this.invoices) {
      if (invoice.control.value === true) {
        selected.push(invoice);
      }
    }
    this.activeModal.close({selected});
  }

}