import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-invoice-service-info',
  templateUrl: './invoice-service-info.component.html',
  styleUrls: ['./invoice-service-info.component.scss']
})
export class InvoiceServiceInfoComponent implements OnInit {
    public invoiceName;
    public content;

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() {
    }

    public close() {
        this.activeModal.close();
    }

}
