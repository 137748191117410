import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { DomainDetailsEditComponent } from '../domains-hostings/domain-details-edit/domain-details-edit.component';
import { DomainsHostingsModule } from '../domains-hostings/domains-hostings.module';
import { SharedModule } from '../shared/shared.module';
import { InvoiceFormComponent } from './invoice-form/invoice-form.component';
import { InvoiceServiceInfoComponent } from './invoice-service-info/invoice-service-info.component';
import { InvoicesListComponent } from './invoices-list/invoices-list.component';
import { InvoicesRoutingModule } from './invoices-routing.module';
import { RefundFormComponent } from './refund-form/refund-form.component';

@NgModule({
    imports: [
        CommonModule,
        NgxDatatableModule,
        InvoicesRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgSelectModule,
        DomainsHostingsModule,
        TranslateModule,
        SharedModule
    ],
    declarations: [InvoicesListComponent, InvoiceFormComponent, RefundFormComponent, InvoiceServiceInfoComponent],
    exports: [InvoicesListComponent]
})
export class InvoicesModule {}
