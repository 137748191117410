import { RouteInfo } from "./sidebar.metadata";

export const ROUTES_DESIGN: RouteInfo[] = [
    {
        path: "/design/logo",
        title: "SIDEBAR.LOGOTYPES",
        icon: "ft-image",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/design/naming",
        title: "SIDEBAR.NAMINGS",
        icon: "ft-file-text",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/design/brandbook",
        title: "SIDEBAR.BRANDBOOKS",
        icon: "ft-book",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/design/identity",
        title: "SIDEBAR.IDENTITY",
        icon: "ft-package",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/design/portfolio/logo",
        title: "SIDEBAR.LOGO_PORTFOLIO",
        icon: "ft-briefcase",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
];

export const ROUTES_DOMAINS: RouteInfo[] = [
    {
        path: "/domains-hostings/domains",
        title: "SIDEBAR.DOMAINS",
        icon: "ft-globe",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/domains-hostings/hostings",
        title: "SIDEBAR.HOSTINGS",
        icon: "ft-server",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
];

export const ROUTES_IP: RouteInfo[] = [
    {
        path: "/intellect-property/copyrights",
        title: "SIDEBAR.COPYRIGHT",
        icon: "icon-book-open",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/intellect-property/industrial-designs",
        title: "SIDEBAR.INDUSTRIAL_DESIGNS",
        icon: "icon-chemistry",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/intellect-property/patents",
        title: "SIDEBAR.PATENTS",
        icon: "icon-graduation",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
];

export const ROUTES_TM: RouteInfo[] = [
    {
        path: "/dashboard",
        title: "SIDEBAR.DASHBOARD",
        icon: "ft-home",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/emailclient/app",
        title: "SIDEBAR.MAIL",
        icon: "ft-mail",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "",
        title: "SIDEBAR.TRADEMARKS",
        icon: "ft-layout",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
            {
                path: "/trademarks/applications",
                title: "SIDEBAR.APPLICATIONS",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/trademarks/report",
                title: "SIDEBAR.DIGEST_APPLICATIONS",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/trademarks/disabled",
                title: "SIDEBAR.DISABLED",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/trademarks/refund",
                title: "SIDEBAR.REFUNDS",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "",
                title: "SIDEBAR.REGISTERED_TM",
                icon: "",
                class: "has-sub",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [
                    {
                        path: "/control/trademark-list",
                        title: "SIDEBAR.ALL_TM",
                        icon: "",
                        class: "",
                        badge: "",
                        badgeClass: "",
                        isExternalLink: false,
                        submenu: [],
                    },
                    {
                        path: "/control/trademark-list/expiring",
                        title: "SIDEBAR.EXPIRING",
                        icon: "",
                        class: "",
                        badge: "",
                        badgeClass: "",
                        isExternalLink: false,
                        submenu: [],
                    },
                    {
                        path: "/control/trademark-list/expiring-week",
                        title: "SIDEBAR.NEAREST_WEEK",
                        icon: "",
                        class: "",
                        badge: "",
                        badgeClass: "",
                        isExternalLink: false,
                        submenu: [],
                    },
                    {
                        path: "/control/trademark-list/paid",
                        title: "SIDEBAR.PAID",
                        icon: "",
                        class: "",
                        badge: "",
                        badgeClass: "",
                        isExternalLink: false,
                        submenu: [],
                    },
                    {
                        path: "/control/trademark-list/prolonged",
                        title: "SIDEBAR.PROLONGED",
                        icon: "",
                        class: "",
                        badge: "",
                        badgeClass: "",
                        isExternalLink: false,
                        submenu: [],
                    },
                    {
                        path: "/control/trademark-list/refused",
                        title: "SIDEBAR.REFUSED",
                        icon: "",
                        class: "",
                        badge: "",
                        badgeClass: "",
                        isExternalLink: false,
                        submenu: [],
                    },
                ],
            },
        ],
    },
    {
        path: "/invoices/invoices-list",
        title: "SIDEBAR.INVOICES",
        icon: "ft-credit-card",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/documents/documents-list",
        title: "SIDEBAR.DOCUMENTS",
        icon: "ft-file-text",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "control/reminders-list",
        title: "SIDEBAR.CONTROL",
        icon: "ft-monitor",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
            {
                path: "/control/reminders-list",
                title: "SIDEBAR.CONTROL_TERMS",
                icon: "ft-calendar",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/control/t6-list",
                title: "SIDEBAR.NONFORMAL",
                icon: "ft-wind",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/control/t9-list",
                title: "SIDEBAR.PRELIMINARY",
                icon: "ft-alert-triangle",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/control/dead",
                title: "SIDEBAR.DEAD",
                icon: "ft-alert-octagon",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "",
                title: "",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: true,
                submenu: [],
            },
            {
                path: "/control/tdog-list",
                title: "SIDEBAR.PRINT_POA",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/control/upcoming-publications",
                title: "SIDEBAR.UPCOMING_PUBLICATIONS",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/control/not-sended-certificates",
                title: "SIDEBAR.READY_CERTIFICATES",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/trademarks/submit-overdue",
                title: "SIDEBAR.SET_SUBMIT_DATE",
                icon: "",
                class: "letter-spacing-0",
                badge: "",
                badgeClass: "",
                isExternalLink: true,
                submenu: [],
            },
            {
                path: "",
                title: "",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: true,
                submenu: [],
            },
        ],
    },
    {
        path: "",
        title: "SIDEBAR.SPEED_UP",
        icon: "ft-fast-forward",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
            {
                path: "/control/waiting-for-tmin",
                title: "SIDEBAR.NO_TMIN_INVOICE",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/control/speed-up",
                title: "SIDEBAR.SPEED_UP_APPLICATIONS",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
        ],
    },
    {
        path: "",
        title: "SIDEBAR.OBJECTIONS",
        icon: "ft-alert-triangle",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
            {
                path: "/objections/order-list",
                title: "SIDEBAR.ORDERS",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/objections/incoming-list",
                title: "SIDEBAR.INCOMING_OBJECTIONS",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/objections/answered",
                title: "SIDEBAR.OBJECTIONS_ARCHIVE",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
        ],
    },
    {
        path: "",
        title: "SIDEBAR.TRANSFER",
        icon: "ft-users",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
            {
                path: "/transfer-rights/transfer-app",
                title: "SIDEBAR.APP_TRANSFER",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/transfer-rights/transfer-tm",
                title: "SIDEBAR.TM_TRASFER",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/transfer-rights/license",
                title: "SIDEBAR.LICENSE_AGREEMENTS",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/transfer-rights/paid",
                title: "SIDEBAR.TRANSFER_PAID",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/transfer-rights/sent-to-sign",
                title: "SIDEBAR.SENT_TO_SIGN",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/transfer-rights/signed",
                title: "SIDEBAR.SIGNED",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/transfer-rights/finished",
                title: "SIDEBAR.TRANSFER_FINISHED",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
        ],
    },
    {
        path: "/monitoring/orders-list",
        title: "SIDEBAR.MONITORING",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
];

export const ROUTES_UTILS: RouteInfo[] = [
    // {
    //     path: '/control/lost-application-list',
    //     title: 'SIDEBAR.FILED_FORMS',
    //     icon: 'ft-briefcase',
    //     class: '',
    //     badge: '',
    //     badgeClass: '',
    //     isExternalLink: false,
    //     submenu: []
    // },
    {
        path: "",
        title: "SIDEBAR.CALLS",
        icon: "ft-phone-incoming",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
            {
                path: "/control/ats-calls-list",
                title: "SIDEBAR.ATS_CALLS",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/control/ats-calls-summary",
                title: "SIDEBAR.STATISTICS",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
        ],
    },
    {
        path: "/control/search-statistics",
        title: "SIDEBAR.SEARCH_REQUESTS",
        icon: "ft-search",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/control/search-complains",
        title: "SIDEBAR.SEARCH_ERRORS",
        icon: "ft-alert-circle",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/control/reg-codes-list",
        title: "SIDEBAR.REGISTRATION_CODES",
        icon: "ft-hash",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/control/task-list",
        title: "SIDEBAR.TASK_LIST",
        icon: "ft-check-circle",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/potential-clients/calculation",
        title: "SIDEBAR.CALCULATION",
        icon: "icon-calculator",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/potential-clients/leads",
        title: "SIDEBAR.LEADS",
        icon: "ft-users",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/potential-clients/leads-franchise",
        title: "SIDEBAR.LEADS_FRANCHISE",
        icon: "ft-users",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "",
        title: "SIDEBAR.POLLS",
        icon: "ft-help-circle",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
            {
                path: "/potential-clients/poll",
                title: "SIDEBAR.POLLS_LIST",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/potential-clients/poll-answers",
                title: "SIDEBAR.ANSWERS_LIST",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
        ],
    },
    {
        path: "/control/viber-mailing",
        title: "SIDEBAR.VIBER_MAILING",
        icon: "ft-mail",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/discounts/certificates",
        title: "SIDEBAR.GIFT_CERTIFICATES",
        icon: "icon-present",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/tm-sale/list",
        title: "SIDEBAR.TM_SALE",
        icon: "ft-tag",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    // {
    //     path: '/tm-watch/list',
    //     title: 'SIDEBAR.TM_WATCH',
    //     icon: 'ft-eye',
    //     class: '',
    //     badge: '',
    //     badgeClass: '',
    //     isExternalLink: false,
    //     submenu: []
    // },
    {
        path: "/monitoring/list",
        title: "SIDEBAR.TM_MONITORING",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/reviews/list",
        title: "SIDEBAR.REVIEWS",
        icon: "ft-message-square",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/settings/edit",
        title: "SIDEBAR.SETTINGS",
        icon: "ft-settings",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/settings/classes",
        title: "SIDEBAR.CLASSES_SETTINGS",
        icon: "ft-settings",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/sellers/list",
        title: "SIDEBAR.SELLERS",
        icon: "ft-shopping-cart",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "",
        title: "SIDEBAR.TEMPLATES",
        icon: "ft-help-circle",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
            {
                path: "/templates/doc-template/list",
                title: "SIDEBAR.TEMPLATE_DOCUMENTS",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/templates/mail-template/list",
                title: "SIDEBAR.TEMPLATE_MAILS",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
        ],
    },
    {
        path: "/users",
        title: "SIDEBAR.USERS",
        icon: "ft-user",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "",
        title: "SIDEBAR.PARTNERS",
        icon: "ft-globe",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
            {
                path: "/partners/list",
                title: "SIDEBAR.PARTNERS_LIST",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/partners/prices-list",
                title: "SIDEBAR.PRICES",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
        ],
    },
    {
        path: "",
        title: "SIDEBAR.PARTNERS_WIDGET",
        icon: "ft-grid",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
            {
                path: "/widget-partners/list",
                title: "SIDEBAR.WIDGET_PARTNERS_LIST",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/widget-partners/transactions",
                title: "SIDEBAR.WIDGET_TRANSACTIONS",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
        ],
    },
    {
        path: "/profitmark-pro/requests",
        title: "SIDEBAR.PROFITMARK_PRO",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
];

export const ROUTES_ACCOUNTING: RouteInfo[] = [
    {
        path: "/accounting/payments",
        title: "SIDEBAR.ACCOUNTING_PAYMENTS",
        icon: "ft-credit-card",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/accounting/reminders",
        title: "SIDEBAR.ACCOUNTING_REMINDERS",
        icon: "ft-calendar",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
];

export const ROUTES_SEARCH: RouteInfo[] = [
    {
        path: "/search-reports/outer",
        title: "SIDEBAR.EXTERNAL_REPORTS",
        icon: "ft-external-link",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/search-reports/control",
        title: "SIDEBAR.SEARCH_CONTROL",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/search-reports/today",
        title: "SIDEBAR.SEARCH_TODAY",
        icon: "ft-calendar",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/search-reports/review",
        title: "SIDEBAR.SEARCH_ON_REVIEW",
        icon: "ft-check-square",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/search-reports/to-call",
        title: "SIDEBAR.SEARCH_SENT",
        icon: "ft-phone",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/search-reports/monitoring-reports",
        title: "SIDEBAR.MONITORING_REPORTS",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/search-reports/waiting-for-fs",
        title: "SIDEBAR.FS_NOT_SENT",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/search-reports/waiting-for-sin",
        title: "SIDEBAR.NO_INVOICE",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/search-reports/waiting-for-sinp",
        title: "SIDEBAR.WAITING_FOR_PAYMENT",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/search-reports/invoices-list",
        title: "SIDEBAR.PAID_REPORTS",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
];

export const ROUTES_UTILS_MANAGER: RouteInfo[] = [
    {
        path: "/control/search-statistics",
        title: "SIDEBAR.SEARCH_REQUESTS",
        icon: "ft-search",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/control/search-complains",
        title: "SIDEBAR.SEARCH_ERRORS",
        icon: "ft-alert-circle",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/control/reg-codes-list",
        title: "SIDEBAR.REGISTRATION_CODES",
        icon: "ft-hash",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: '/control/task-list',
        title: 'SIDEBAR.TASK_LIST',
        icon: 'ft-check-circle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: "/potential-clients/calculation",
        title: "SIDEBAR.CALCULATION",
        icon: "icon-calculator",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/potential-clients/leads",
        title: "SIDEBAR.LEADS",
        icon: "ft-users",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/potential-clients/leads-franchise",
        title: "SIDEBAR.LEADS_FRANCHISE",
        icon: "ft-users",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "",
        title: "SIDEBAR.POLLS",
        icon: "ft-help-circle",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
            {
                path: "/potential-clients/poll",
                title: "SIDEBAR.POLLS_LIST",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
            {
                path: "/potential-clients/poll-answers",
                title: "SIDEBAR.ANSWERS_LIST",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
        ],
    },
    {
        path: "/control/viber-mailing",
        title: "SIDEBAR.VIBER_MAILING",
        icon: "ft-mail",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/discounts/certificates",
        title: "SIDEBAR.GIFT_CERTIFICATES",
        icon: "icon-present",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/tm-sale/list",
        title: "SIDEBAR.TM_SALE",
        icon: "ft-tag",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    // {
    //     path: '/tm-watch/list',
    //     title: 'SIDEBAR.TM_WATCH',
    //     icon: 'ft-eye',
    //     class: '',
    //     badge: '',
    //     badgeClass: '',
    //     isExternalLink: false,
    //     submenu: []
    // },
    {
        path: "/monitoring/list",
        title: "SIDEBAR.TM_MONITORING",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/reviews/list",
        title: "SIDEBAR.REVIEWS",
        icon: "ft-message-square",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },
    {
        path: "/settings/classes",
        title: "SIDEBAR.CLASSES_SETTINGS",
        icon: "ft-settings",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },

    {
        path: "/profitmark-pro/requests",
        title: "SIDEBAR.PROFITMARK_PRO",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
    },

    {
        path: "",
        title: "SIDEBAR.TEMPLATES",
        icon: "ft-help-circle",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [
            {
                path: "/templates/mail-template/list",
                title: "SIDEBAR.TEMPLATE_MAILS",
                icon: "",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
            },
        ],
    },
];

export const ROUTES = {
    TM: ROUTES_TM,
    DOMAINS: ROUTES_DOMAINS,
    IP: ROUTES_IP,
    DESIGN: ROUTES_DESIGN,
    UTILS: ROUTES_UTILS,
    ACCOUNTING: ROUTES_ACCOUNTING,
    SEARCH: ROUTES_SEARCH,
    UTILS_MANAGERS: ROUTES_UTILS_MANAGER,
};

export const START_PATHS = {
    TM: "trademarks/applications",
    DOMAINS: "domains-hostings/domains",
    IP: "intellect-property/copyrights",
    DESIGN: "design/logo",
    UTILS: "potential-clients/leads",
    ACCOUNTING: "accounting/payments",
    SEARCH: "search-reports/control",
};
