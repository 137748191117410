export class Hosting {
    public name = '';
    public changed = '';
    public created = '';
    public extended = '';
    public hosting_info = '';
    public hosting_package = '';
    public hosting_status = '';
    public id = '';
    public registered = '';
    public user_id = '';
    public user_name = '';
    public user_phone = '';
    public mail = '';
    public user_full_name = '';

    constructor(data) {
        for (const key in data) {
            if (data.hasOwnProperty(key) && data[key]) {
                this[key] = data[key];
            }
        }
    }
}
