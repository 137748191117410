import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from '../../login/login-page/login-page.component';

// Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
    {
        path: 'pages',
        loadChildren: () => import('../../pages/content-layout-page/content-pages.module').then(m => m.ContentPagesModule)
    },
    {
        path: 'login',
        component: LoginPageComponent,
        data: {
            title: 'Login Page'
        }
    }
];
