import { BaseService } from "app/shared/services/base.service";

import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

import { ToastrService } from "../../../../node_modules/ngx-toastr";
import { AuthService } from "../../shared/auth/auth.service";
import { ForgotPasswordComponent } from "../forgot-password/forgot-password.component";

@Component({
    selector: "app-login-page",
    templateUrl: "./login-page.component.html",
    styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnInit {
    public password = new FormControl("");

    public topRole;
    public avatar = 8;
    public phone = "";

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        public modalService: NgbModal,
        private toastr: ToastrService,
        private baseService: BaseService,
        public translate: TranslateService
    ) {
        this.avatar = Math.floor(Math.random() * 8 + 1);
    }

    ngOnInit(): void {
        localStorage.removeItem("access_token_crm");
    }

    telInputObject(object) {}

    phoneChange(phone) {
        this.phone = phone;
    }

    // On submit button click
    onSubmit() {
        console.log("on submit");
        // if (!this.phoneValid) {
        //     this.translate.get('LOGIN.WRONG_PHONE_FORMAT').subscribe((res: string) => {
        //         this.toastr.error(res);
        //     });
        // }
        if (this.phone.length > 0) {
            const number = this.phone.replace(/\+/g, "");
            this.authService.getJWT(number, this.password.value).subscribe(
                (data) => {
                    this.baseService
                        .getUser(localStorage.getItem("duid"))
                        .subscribe((data) => {
                            const lang =
                                data.preferred_langcode?.[0]?.value ?? "en";
                            console.log(data);

                            if (lang !== "uk") {
                                this.translate.use("en");
                            } else {
                                this.translate.use("uk");
                            }

                            if (this.authService.redirectUrl) {
                                this.router.navigate(
                                    this.authService.redirectUrl
                                );
                                this.authService.redirectUrl = null;
                            } else {
                                this.baseService
                                    .getUser(localStorage.getItem("duid"))
                                    .subscribe(
                                        (data) => {
                                            if (data.roles) {
                                                const roles = data.roles.map(
                                                    (r) => r.target_id
                                                );
                                                if (
                                                    roles.indexOf(
                                                        "administrator"
                                                    ) !== -1
                                                ) {
                                                    this.topRole = "admin";
                                                } else if (
                                                    roles.indexOf("manager") !==
                                                    -1
                                                ) {
                                                    this.topRole = "manager";
                                                } else if (
                                                    roles.indexOf(
                                                        "worldwide_manager"
                                                    ) !== -1
                                                ) {
                                                    this.topRole =
                                                        "worldwide_manager";
                                                } else if (
                                                    roles.indexOf(
                                                        "searcher"
                                                    ) !== -1
                                                ) {
                                                    this.topRole = "searcher";
                                                }
                                                if (
                                                    this.topRole === "searcher"
                                                ) {
                                                    this.router.navigate([
                                                        "/search-reports",
                                                        "list",
                                                    ]);
                                                } else {
                                                    this.router.navigate(["/"]);
                                                }
                                            } else {
                                                this.router.navigate(["/"]);
                                            }
                                        },
                                        (err) => {
                                            this.router.navigate(["/"]);
                                        }
                                    );
                            }
                        });
                },
                (err) => {
                    this.translate
                        .get("LOGIN.LOGIN_ERROR")
                        .subscribe((res: string) => {
                            this.toastr.error(res);
                        });
                    console.log("error during login ", err);
                }
            );
        }
    }
    // On Forgot password link click
    onForgotPassword() {
        const modalRef = this.modalService.open(ForgotPasswordComponent, {
            size: "sm",
            centered: true,
        });

        modalRef.result.then(
            (result) => {
                console.log(result);
            },
            (reason) => {
                console.log(reason);
            }
        );
    }
    // On registration link click
    onRegister() {
        // this.router.navigate(['register'], { relativeTo: this.route.parent });
    }
}
