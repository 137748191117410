import { HttpParams } from "@angular/common/http";
// import { HttpParamsOptions } from "@angular/common/http";

export class LoaderInterceptorParams extends HttpParams {
  constructor(
    public interceptorConfig: { showLoader: boolean },
    params?: { [param: string]: string | string[] }
  ) {
    super({ fromObject: params } as any);
  }
}