import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserResolver } from './services/user.resolver';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UsersListComponent } from './users-list/users-list.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'list',
                component: UsersListComponent
            },
            {
                path: 'view/:id',
                component: UserDetailsComponent,
                resolve: { user: UserResolver }
            },
            {
                path: '',
                redirectTo: '/users/list',
                pathMatch: 'full'
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UsersRoutingModule { }

