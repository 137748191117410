import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { DrupalizeObject } from '../../../shared/common/drupalize-object';
import { BaseService } from '../../../shared/services/base.service';
import { GetCountriesService } from '../../../shared/services/get-countries.service';
import { LANGUAGES_OPTIONS } from './models/user-editor-block.constants';

@Component({
  selector: 'app-user-editor-block',
  templateUrl: './user-editor-block.component.html',
  styleUrls: ['./user-editor-block.component.scss']
})
export class UserEditorBlockComponent implements OnInit {

  public userForm: FormGroup;
  @Output() cancel = new EventEmitter<any>();
  @Output() saved = new EventEmitter<{
    uid: number,
    userId: any,
    userCountry: string,
    userName: string
  }>();
  public userCountries = [{ code: 'UA', name: 'Ukraine' }];
  userLanguages = LANGUAGES_OPTIONS;

  constructor(
    public formBuilder: FormBuilder,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public countriesService: GetCountriesService,
    public service: BaseService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      field_surname: ['', Validators.required],
      field_forename: ['', Validators.required],
      field_po_batkovi: ['', Validators.required],
      field_phone: ['', Validators.required],
      field_country: ['UA', Validators.required],
      mail: ['', Validators.required],
      preferred_langcode: ['en'],
    });
    this.setUserCountries();
  }

  createNewUser() {
    if (this.userForm.valid) {
        const payload = DrupalizeObject.format(this.userForm.value);
        payload['name'] = [{value: 'email_registration_' + this.makeid()}];
        payload['status'] = [{value: true}];
        this.spinner.show();
        this.service.save('', payload, 'user').subscribe(
            data => {
              this.saved.emit({
                uid: data.uid[0].value,
                userId: data.uid[0].value,
                userCountry: data.field_country[0].value,
                userName: data.field_surname[0].value + ' ' + data.field_forename[0].value
              });
            },
            err => {
                console.log(err);
                this.translate.get('ALERTS.ERROR_CHANGING_USER').subscribe((res: string) => {
                  this.toastr.error(res + '.\n' +
                  (err.error ? err.error.message : ''));
                });
            },
            () => this.spinner.hide()
        );
    } else {
        this.translate.get('ALERTS.FILL_ALL_FIELDS').subscribe((res: string) => {
            this.toastr.error(res);
        });
    }
  }

  public makeid() {
      let text = '';
      const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      for (let i = 0; i < 5; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      }

      return text;
  }

  public setUserCountries() {
      this.countriesService.getAllCountries().subscribe(
          data => {
              this.userCountries = data;
          },
          err => {
              this.toastr.error(err.error ? err.error.message : '');
              console.log(err);
          }
      )
  }
}
