export interface UserModel {
    id?: number;
    name?: string;
    fio?: string;
}

export class User {
    public id: number;
    public name: string;
    public fio: string;

    constructor(user: UserModel) {
        for (const param in user) {
            if (user.hasOwnProperty(param)) {
                this[param] = user[param];
            }
        }
        if (user['field_surname'] !== undefined && user['field_forename'] !== undefined) {
            this.fio = `${user['field_surname']} ${user['field_forename']}`;
        } else {
            this.fio = this.name;
        }
    }
}
