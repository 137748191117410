import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/services/base.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'ngx-store';
import { Domain } from '../models/Domain';
import { Hosting } from '../models/Hosting';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DomainsHostingsDataService extends BaseService {
    static ngInjectableDef = undefined;

    constructor (
        public http: HttpClient,
        public storage: LocalStorageService
    ) {
        super(http, storage);
    }

    public getDomainZones(filters = {}) {
        const uri = '/admin/profit/domain_zone/list';
        const params = { _format: 'json' };
        return Observable.create(observer => {
            this.http.get<any>(this.host + uri, {
                params: { ...params, ...filters },
                headers: this.headers
            })
            .subscribe(
                m => observer.next(m),
                e => observer.error(e),
                () => observer.complete()
            );
        });
    }

    public removeFilters(key) {
        // const key = this.constructor.name + '__filters';
        this.storage.remove(key);
        console.log('Filter flushed with key', key);
    }

    public retrieveFilters(key) {
        console.log('Fetched filters for: ', this.constructor.name);
        // const key = this.constructor.name + '__filters';
        return this.storage.get(key);
    }

    public saveFilters(flters, key) {
        console.log('Setted filters for: ', this.constructor.name);
        // const key = this.constructor.name + '__filters';
        this.storage.set(key, flters);
    }

    public getDomainDetails(id, dns?): Observable<any> {
        const domainUrl = `/api/v1/domain/${id}`;
        const params = { _format: 'json' };
        return Observable.create(observer => {
            const headers = new HttpHeaders();
            this.http.get<any>(this.host + domainUrl, {
                params: {...params, ...dns},
                headers
            })
                .subscribe(
                    m => observer.next(m),
                    e => observer.error(e),
                    () => observer.complete()
                );
        });
    }

    public setNs(id, payload) {
        const domainUrl = `/api/v1/domain/${id}`;
        return Observable.create(observer => {
            const headers = new HttpHeaders();
            this.http.patch<any>(this.host + domainUrl, payload, {
                params: { _format: 'json' },
                headers
            })
                .subscribe(
                    m => observer.next(m),
                    e => observer.error(e),
                    () => observer.complete()
                );
        });
    }

    public setDNS(id, payload) {
        const domainUrl = `/api/v1/domain/${id}`;
        return Observable.create(observer => {
            const headers = new HttpHeaders();
            this.http.patch<any>(this.host + domainUrl, payload, {
                params: { _format: 'json' },
                headers
            })
                .subscribe(
                    m => observer.next(m),
                    e => observer.error(e),
                    () => observer.complete()
                );
        });
    }

    // public prepereRows(res) {
    //     const out = [];
    //     for (const item of res.rows) {
    //         try {
    //             if (this.model === 'Domain') {
    //                 console.log('pushing domain');
    //                 out.push(new this[this.model](item));
    //                 // out.push(new Domain(item));
    //             } else if (this.model === 'Hosting') {
    //                 console.log('pushing hosting');
    //                 out.push(new this[this.model](item));
    //                 // out.push(new Hosting(item));
    //             }
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     }
    //     res.rows = out;

    //     return res;
    // }
}
