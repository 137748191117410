import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomainsListComponent } from './domains-list/domains-list.component';
import { DomainsHostingsRoutingModule } from './domains-hostings-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HostingsListComponent } from './hostings-list/hostings-list.component';
import { DomainContactEditComponent } from './domain-contact-edit/domain-contact-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgSelectModule } from '@ng-select/ng-select';
import { DomainDetailsComponent } from './domain-details/domain-details.component';
import { NewDomainComponent } from './new-domain/new-domain.component';
import { UserCabinetComponent } from 'app/shared/user-cabinet/user-cabinet.component';
import { SharedModule } from 'app/shared/shared.module';
import { DomainDetailsEditComponent } from './domain-details-edit/domain-details-edit.component';
import { EditDnsComponent } from './edit-dns/edit-dns.component';
import { SetNsComponent } from './set-ns/set-ns.component';

@NgModule({
    imports: [
        CommonModule,
        DomainsHostingsRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgSelectModule,
        NgxDatatableModule,
        SharedModule,
        UiSwitchModule
    ],
    declarations: [
        DomainContactEditComponent,
        DomainsListComponent,
        HostingsListComponent,
        DomainDetailsComponent,
        NewDomainComponent,
        DomainDetailsEditComponent,
        EditDnsComponent,
        SetNsComponent
    ],
    exports: [DomainsListComponent]
})
export class DomainsHostingsModule { }
