import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { InvoicesListComponent } from "./invoices-list/invoices-list.component";
import { PaymentRecordsComponent } from "./payment-records/payment-records.component";

const routes: Routes = [
    {
        path: "",
        children: [
            {
                path: "invoices-list",
                component: InvoicesListComponent,
                data: {
                    title: "Счета",
                },
            },
            {
                path: "payment-records",
                component: PaymentRecordsComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class InvoicesRoutingModule {}
