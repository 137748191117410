import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'app/shared/services/base.service';
import { LocalStorageService } from 'ngx-store';

@Injectable({
  providedIn: 'root'
})
export class UserListService  extends BaseService {
  static ngInjectableDef = undefined;
  // constructor(http: HttpClient) {
  //     super(http);
  // }

  constructor(public http: HttpClient, public storage: LocalStorageService) {
    super(http, storage)
  }

  public prepereRows(res) {
      // const out = [];
      // for (const item of res.rows) {
      //     try {
      //         out.push(new User(item));
      //     } catch (error) {
      //         console.error(error);
      //     }
      // }
      // res.rows = out;

      return res;
  }
}
