import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject, Subscription, concat, of } from "rxjs";
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    switchMap,
    tap,
} from "rxjs/operators";

import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbDateAdapter, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

import { DomainDetailsEditComponent } from "../../domains-hostings/domain-details-edit/domain-details-edit.component";
import { AuthService } from "../../shared/auth/auth.service";
import { DrupalizeObject } from "../../shared/common/drupalize-object";
import { NgbDateDrupalAdapter } from "../../shared/common/ngb-date-drupal-adapter";
import { UnDrupalizeObject } from "../../shared/common/undrupalize-object";
import { User } from "../../users/models/user.model";
import { UsersService } from "../../users/services/users.service";
import { InvoiceFormComponent } from "../invoice-form/invoice-form.component";
import { InvoiceServiceInfoComponent } from "../invoice-service-info/invoice-service-info.component";
import { Invoice, InvoiceModel } from "../models/invoice-model";
import { RefundFormComponent } from "../refund-form/refund-form.component";
import { FetchInvoicesService } from "../services/fetch-invoices.service";

@Component({
    selector: "app-invoices-list",
    templateUrl: "./invoices-list.component.html",
    styleUrls: ["./invoices-list.component.scss"],
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateDrupalAdapter }],
})
export class InvoicesListComponent implements OnInit, OnDestroy {
    @Input()
    id: string;
    @Input()
    type = "all";
    @Input()
    userId = null;
    @Input()
    target_type = "application";
    @Input()
    separateItems = false;
    @ViewChild("operationsTmpl", { static: true })
    opTmpl: TemplateRef<any>;
    @ViewChild("labelTmpl", { static: true })
    labelTmpl: TemplateRef<any>;
    @ViewChild("stateTmpl", { static: true })
    stateTmpl: TemplateRef<any>;
    @ViewChild("descriptionTmpl", { static: true })
    descriptionTmpl: TemplateRef<any>;
    @ViewChild("headerTmpl", { static: true })
    headerTmpl: TemplateRef<any>;

    @Input() events: Subject<any>;
    private eventSubscription: Subscription;

    @Output()
    addedComment = new EventEmitter<any>();

    @Output()
    invoiceListChange = new EventEmitter<any>();

    public searchTerm: FormControl = new FormControl("");
    public invoiceName: FormControl = new FormControl("");
    public invoiceDate: FormControl = new FormControl("");

    public users$: Observable<User[]>;
    public usersLoading = false;
    public usersinput$ = new Subject<string>();

    public filter_form: FormGroup;

    public rows = [];
    public columns = [];
    public page = "0";
    public types = [{ label: "All", value: "All" }];
    public paidTypes = [];

    public sellers = [];

    public itemsSubject;
    public pager = {
        current_page: 0,
        total_items: 0,
        total_pages: 0,
        items_per_page: 50,
    };

    constructor(
        public invoicesService: FetchInvoicesService,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        public formBuilder: FormBuilder,
        public usersService: UsersService,
        public authService: AuthService,
        public translate: TranslateService
    ) {
        this.filter_form = this.formBuilder.group({
            name: [""],
            payed: [""],
            service_type: ["All"],
            user_id: [null],
            paid_status: ["all"],
            seller: ["all"],
            payed_date: this.formBuilder.group({
                min: [""],
                max: [""],
            }),
        });
    }

    ngOnInit() {
        if (this.events) {
            this.eventSubscription = this.events.subscribe((data) => {
                if (data === "updateList") {
                    this.goForIt();
                }
            });
        }

        this.paidTypes = [
            { label: "INVOICES.PAID_UNPAID", value: "all" },
            { label: "INVOICES.PAID", value: "paid" },
            { label: "INVOICES.UNPAID", value: "not_paid" },
        ];
        this.invoicesService.query("/api/v1/seller/list", {}).subscribe(
            (data) => {
                if (data.rows) {
                    this.sellers = [
                        { id: "all", label: "INVOICES.ALL_SELLERS" },
                        ...Object.keys(data.rows).map((key) => data.rows[key]),
                    ];
                }
            },
            (err) => {}
        );
        this.columns = [
            {
                headerTemplate: this.headerTmpl,
                name: "INVOICES.COL_NAME",
                maxWidth: 150,
                cellTemplate: this.labelTmpl,
            },
            {
                headerTemplate: this.headerTmpl,
                name: "INVOICES.COL_AMOUNT",
                prop: "amount",
                maxWidth: 100,
            },
            {
                headerTemplate: this.headerTmpl,
                name: "INVOICES.COL_PURPOSE",
                prop: "invoice_description",
                cellTemplate: this.descriptionTmpl,
            },
            {
                headerTemplate: this.headerTmpl,
                name: "INVOICES.COL_PAID",
                cellTemplate: this.stateTmpl,
                maxWidth: 120,
            },
            {
                headerTemplate: this.headerTmpl,
                name: "INVOICES.COL_ACTIONS",
                cellTemplate: this.opTmpl,
                maxWidth: 180,
                cellClass: "dropmenu",
            },
        ];

        if (this.separateItems) {
            this.itemsSubject = this.invoicesService.getItems();
            this.itemsSubject.subscribe((data) => {
                this.rows = data.rows;
                this.pager = data.pager;
            });
        } else {
            this.invoicesService.items.subscribe((items) => {
                this.rows = items;
            });
        }

        this.route.queryParams.subscribe((params) => {
            this.page = params["page"];
            this.goForIt();
        });

        this.invoicesService.getTypes().subscribe(
            (m) => (this.types = m),
            (e) => console.log(e)
        );

        this.searchTerm.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe((term) => {
                this.page = "0";
                if (term !== "") {
                    this.invoicesService.getServices(term).subscribe(
                        (res) => {
                            const ids = [];
                            for (let x = 0; x < res.length; x++) {
                                ids.push(res[x].id);
                            }
                            if (ids.length) {
                                const views_uri =
                                    "/admin/profit/invoice/list/" +
                                    this.type +
                                    "/";
                                const filters = Object.keys(
                                    this.filter_form.value
                                ).map((item) => this.filter_form.value[item]);
                                this.invoicesService.fetch(
                                    this.page,
                                    ids.join("+"),
                                    ...filters,
                                    views_uri
                                );
                            }
                        },
                        (e) => {}
                    );
                } else {
                    this.goForIt();
                }
            });

        this.filter_form.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe((values) => {
                console.log(values);
                this.page = "0";
                this.goForIt();
            });

        this.users$ = concat(
            of([]), // default items
            this.usersinput$.pipe(
                debounceTime(400),
                distinctUntilChanged(),
                tap(() => (this.usersLoading = true)),
                switchMap((combine) => {
                    this.usersService.fetch(
                        "1",
                        "all",
                        { combine },
                        "/admin/profit/users/list/"
                    );
                    return this.usersService.items.pipe(
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.usersLoading = false))
                    );
                })
            )
        );

        const filters = this.invoicesService.filters;
        if (filters && typeof this.id === "undefined") {
            this.filter_form.patchValue(filters, { emitEvent: false });
        }
    }

    ngOnDestroy(): void {
        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
        }
    }

    goForIt() {
        const views_uri = "/admin/profit/invoice/list/" + this.type + "/";
        let filters = {};
        if (typeof this.id === "undefined") {
            filters = { ...this.filter_form.value };
            this.invoicesService.filters = this.filter_form.value;
        } else if (this.target_type) {
            filters = { service_type: this.target_type };
        }
        if (filters["paid_status"] === "paid") {
            filters["state"] = "payed";
        } else if (filters["paid_status"] === "not_paid") {
            filters["not_state"] = "payed";
        }
        delete filters["paid_status"];
        if (this.userId) {
            filters = { user_id: this.userId };
        }
        if (filters["seller"] === "all") {
            delete filters["seller"];
        }
        filters["payed_date[min]"] =
            this.filter_form.value.payed_date.min ||
            (this.filter_form.value.payed_date.max &&
                new Date("1990-01-01").toISOString().substring(0, 10));
        filters["payed_date[max]"] =
            this.filter_form.value.payed_date.max ||
            (this.filter_form.value.payed_date.min &&
                new Date().toISOString().substring(0, 10));
        filters["payed_date"] = undefined;
        if (this.separateItems) {
            this.invoicesService.fetch(
                this.page,
                this.id,
                filters,
                views_uri,
                this.itemsSubject
            );
        } else {
            this.invoicesService.fetch(this.page, this.id, filters, views_uri);
        }
    }

    fetchPage(pager) {
        this.page = pager.offset;
        this.goForIt();

        if (!this.userId) {
            if (typeof this.id === "undefined") {
                // Set page
                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: {
                        page: pager.offset,
                    },
                    queryParamsHandling: "merge",
                    skipLocationChange: false,
                });
            }
        }
    }

    openEditor(invoice?: InvoiceModel) {
        if (typeof this.id !== "undefined" && typeof invoice === "undefined") {
            this.invoicesService.getOne(this.id, this.target_type).subscribe(
                (data) => {
                    const serviceItem = UnDrupalizeObject.format(data) as any;
                    const tm_name = serviceItem.tm_name;
                    const userId =
                        serviceItem.user_id && serviceItem.user_id.target_id
                            ? serviceItem.user_id.target_id
                            : serviceItem.user_id;
                    const invoice_data = {
                        id: "",
                        name: "{auto}",
                        invoice_description: "",
                        amount: 0.0,
                        currency: "UAH",
                        type: "custom",
                        seller: "marina",
                        payed: null,
                        payer_type: "individual",
                        payer_requisites: "",
                        user_id: userId,
                        service: JSON.stringify({
                            target_label: tm_name,
                            target_id: this.id,
                            target_type: this.target_type,
                        }),
                    };
                    const modalRef = this.modalService.open(
                        InvoiceFormComponent,
                        { backdrop: "static", size: "lg" }
                    );

                    modalRef.componentInstance.invoice = new Invoice(
                        invoice_data
                    );

                    modalRef.result.then(
                        (result) => {
                            this.goForIt();
                            this.invoiceListChange.emit();
                        },
                        (reason) => {
                            this.goForIt();
                        }
                    );
                },
                (e) => console.log(e)
            );
        } else {
            const modalRef = this.modalService.open(InvoiceFormComponent, {
                backdrop: "static",
                size: "lg",
            });
            modalRef.componentInstance.invoice = invoice;

            modalRef.result.then(
                (result) => {
                    this.goForIt();
                    this.invoiceListChange.emit();
                },
                (reason) => {
                    this.goForIt();
                }
            );
        }
    }

    openRefundDialog(invoice?: InvoiceModel) {
        const modalRef = this.modalService.open(RefundFormComponent, {
            size: "sm",
        });
        modalRef.componentInstance.invoice = invoice;

        modalRef.result.then(
            (result) => {
                this.goForIt();
            },
            (reason) => {
                console.log(reason);
            }
        );
    }

    payInvoice(invoice: InvoiceModel) {
        this.spinner.show();
        this.invoicesService.pay(invoice.id).subscribe(
            (m) => {
                this.translate
                    .get("INVOICES.INVOICE_PAID")
                    .subscribe((res: string) => {
                        this.toastr.success(res);
                    });
                invoice.payed = new Date().toString();
                this.goForIt();
                this.invoiceListChange.emit();
            },
            (e) => {
                this.translate
                    .get("INVOICES.ERROR_PAYING_INVOICE")
                    .subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                this.toastr.error(e.error ? e.error.message : "");
                this.spinner.hide();
            },
            () => {
                this.spinner.hide();
            }
        );
    }

    printInvoice(invoice: InvoiceModel) {
        this.invoicesService.printInvoice(Number(invoice.id));
    }

    deleteInvoice(invoice) {
        this.spinner.show();
        this.invoicesService.delete(invoice.id, "invoice").subscribe(
            (m) => {
                this.translate
                    .get("INVOICES.INVOICE_DELETED")
                    .subscribe((res: string) => {
                        this.toastr.success(res);
                    });
                this.goForIt();
                this.invoiceListChange.emit();
            },
            (e) => {
                this.translate
                    .get("INVOICES.INVOICE_NOT_DELETED")
                    .subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                this.toastr.error(e.error ? e.error.message : "");
                this.spinner.hide();
                console.log(e);
            },
            () => {
                this.spinner.hide();
            }
        );
    }

    splitUp() {
        if (typeof this.id !== "undefined") {
            this.spinner.show();
            this.invoicesService
                .operation(+this.id, "splitUp", "application")
                .subscribe(
                    (m) => {
                        this.translate
                            .get("INVOICES.INVOICE_SPLIT")
                            .subscribe((res: string) => {
                                this.toastr.success(res);
                            });
                        this.goForIt();
                        this.invoiceListChange.emit();
                    },
                    (e) => {
                        this.translate
                            .get("INVOICES.ERROR_ON_REQUEST")
                            .subscribe((res: string) => {
                                this.toastr.error(res);
                            });
                        this.toastr.error(e.error ? e.error.message : "");
                        this.spinner.hide();
                        console.log(e);
                    },
                    () => {
                        this.spinner.hide();
                    }
                );
        }
    }

    processInvoice(invoice) {
        this.spinner.show();
        this.invoicesService
            .operation(+invoice.id, "process", "invoice")
            .subscribe(
                (m) => {
                    this.translate
                        .get("INVOICES.PROCESSED")
                        .subscribe((res: string) => {
                            this.toastr.success(res);
                        });
                    this.goForIt();
                    this.invoiceListChange.emit();
                },
                (e) => {
                    this.translate
                        .get("INVOICES.ERROR_ON_REQUEST")
                        .subscribe((res: string) => {
                            this.toastr.error(res);
                        });
                    this.toastr.error(e.error ? e.error.message : "");
                    this.spinner.hide();
                    console.log(e);
                },
                () => {
                    this.spinner.hide();
                }
            );
    }

    generateInvoice() {
        if (typeof this.id !== "undefined") {
            this.spinner.show();
            this.invoicesService
                .operation(+this.id, "createInvoice", this.target_type)
                .subscribe(
                    (m) => {
                        this.translate
                            .get("INVOICES.INVOICE_CREATED")
                            .subscribe((res: string) => {
                                this.toastr.success(res);
                            });
                        this.goForIt();
                        this.invoiceListChange.emit();
                    },
                    (e) => {
                        this.translate
                            .get("INVOICES.ERROR_ON_REQUEST")
                            .subscribe((res: string) => {
                                this.toastr.error(res);
                            });
                        this.toastr.error(e.error ? e.error.message : "");
                        this.spinner.hide();
                        console.log(e);
                    },
                    () => {
                        this.spinner.hide();
                    }
                );
        }
    }

    sendStripe(invoice) {
        this.spinner.show();
        this.invoicesService
            .operation(invoice.id, "sendStripeInvoice", "invoice")
            .subscribe(
                (m) => {
                    this.translate
                        .get("INVOICES.INVOICE_SENT")
                        .subscribe((res: string) => {
                            this.toastr.success(res);
                        });
                    this.goForIt();
                    this.translate
                        .get("INVOICES.SENT_ON_STRIPE")
                        .subscribe((res: string) => {
                            const message = res;
                            const payload = DrupalizeObject.format({
                                user_id: { target_id: this.authService.uid },
                                message,
                                event_type: "user",
                                service: {
                                    target_type: this.target_type
                                        ? this.target_type
                                        : "application",
                                    target_id: this.id,
                                },
                            });
                            this.invoicesService
                                .save("", payload, "trademark_event")
                                .subscribe(
                                    (m) => {
                                        this.addedComment.emit();
                                    },
                                    (e) => {}
                                );
                        });
                },
                (e) => {
                    this.translate
                        .get("INVOICES.ERROR_ON_REQUEST")
                        .subscribe((res: string) => {
                            this.toastr.error(res);
                        });
                    this.toastr.error(e.error ? e.error.message : "");
                    this.spinner.hide();
                    console.log(e);
                },
                () => {
                    this.spinner.hide();
                }
            );
    }

    sendOnViber(invoice) {
        this.spinner.show();
        this.invoicesService
            .operation(invoice.id, "sendToViber", "invoice")
            .subscribe(
                (m) => {
                    this.translate
                        .get("INVOICES.INVOICE_SENT")
                        .subscribe((res: string) => {
                            this.toastr.success(res);
                        });
                    this.goForIt();

                    this.translate
                        .get("INVOICES.SENT_ON_VIBER")
                        .subscribe((res: string) => {
                            const message = res;
                            const payload = DrupalizeObject.format({
                                user_id: { target_id: this.authService.uid },
                                message,
                                event_type: "user",
                                service: {
                                    target_type: this.target_type
                                        ? this.target_type
                                        : "application",
                                    target_id: this.id,
                                },
                            });
                            this.invoicesService
                                .save("", payload, "trademark_event")
                                .subscribe(
                                    (m) => {
                                        this.addedComment.emit();
                                    },
                                    (e) => {}
                                );
                        });
                },
                (e) => {
                    this.translate
                        .get("INVOICES.ERROR_ON_REQUEST")
                        .subscribe((res: string) => {
                            this.toastr.error(res);
                        });
                    this.toastr.error(e.error ? e.error.message : "");
                    this.spinner.hide();
                    console.log(e);
                },
                () => {
                    this.spinner.hide();
                }
            );
    }

    clearDate() {
        this.filter_form.get("payed").patchValue("");
    }

    clearFilters() {
        this.filter_form.reset();
    }

    async downloadInvoices() {
        let filters = {};
        if (typeof this.id === "undefined") {
            filters = { ...this.filter_form.value };
            this.invoicesService.filters = this.filter_form.value;
        } else if (this.target_type) {
            filters = { service_type: this.target_type };
        }
        if (filters["paid_status"] === "paid") {
            filters["state"] = "payed";
        } else if (filters["paid_status"] === "not_paid") {
            filters["not_state"] = "payed";
        }
        delete filters["paid_status"];
        if (this.userId) {
            filters = { user_id: this.userId };
        }
        if (filters["seller"] === "all") {
            delete filters["seller"];
        }
        filters["payed_date[min]"] =
            this.filter_form.value.payed_date.min ||
            new Date("1990-01-01").toISOString().substring(0, 10);
        filters["payed_date[max]"] =
            this.filter_form.value.payed_date.max ||
            new Date().toISOString().substring(0, 10);
        filters["payed_date"] = undefined;
        await this.invoicesService.downloadInvoices(filters).toPromise();
    }

    public openTargetEntity(invoice) {
        // console.log(invoice);
        const type = invoice.service.target_type;
        let path = "";
        switch (type) {
            case "application":
                path = `/trademarks/applications/${invoice.service.target_id}`;
                break;
            case "trademark":
                this.usersService
                    .getOne(invoice.service.target_id, "trademark")
                    .subscribe(
                        (data) => {
                            if (
                                data.application &&
                                data.application.length > 0 &&
                                data.application[0].target_id
                            ) {
                                path = `/trademarks/applications/${data.application[0].target_id}`;
                                this.router.navigate([path]);
                            } else {
                                this.translate
                                    .get("INVOICES.RELATED_APP_NOT_FOUND")
                                    .subscribe((res: string) => {
                                        this.toastr.error(res);
                                    });
                            }
                        },
                        (err) => console.log(err)
                    );
                break;
            case "tm_search":
                this.usersService
                    .getOne(invoice.service.target_id, "tm_search")
                    .subscribe(
                        (data) => {
                            if (
                                data.application &&
                                data.application.length > 0 &&
                                data.application[0].target_id
                            ) {
                                console.log(data.application[0].target_id);
                                path = `/trademarks/applications/${data.application[0].target_id}`;
                                this.router.navigate([path]);
                            }
                        },
                        (err) => console.log(err)
                    );
                break;
            case "domain":
                const domainsModalRef = this.modalService.open(
                    DomainDetailsEditComponent,
                    { size: "lg" }
                );
                domainsModalRef.componentInstance.domainId =
                    invoice.service.target_id;
                domainsModalRef.result.then(
                    (result) => {
                        console.log(result);
                    },
                    (reason) => {
                        console.log(reason);
                    }
                );
                break;
            case "hosting":
                let hostingContent = "";
                this.usersService
                    .getOne(invoice.service.target_id, "hosting")
                    .subscribe(
                        (data) => {
                            if (data) {
                                this.translate
                                    .get([
                                        "INVOICES.HOSTING_DESC_HOSTING",
                                        "INVOICES.HOSTING_DESC_PACKET",
                                        "INVOICES.HOSTING_DESC_CONTACTS",
                                    ])
                                    .subscribe((res: string) => {
                                        hostingContent +=
                                            res[
                                                "INVOICES.HOSTING_DESC_HOSTING"
                                            ] +
                                            " " +
                                            data.name[0].value;
                                        hostingContent +=
                                            "<br>" +
                                            res[
                                                "INVOICES.HOSTING_DESC_PACKET"
                                            ] +
                                            " " +
                                            data.hosting_package[0].target_id;
                                        this.usersService
                                            .getUser(data.user_id[0].target_id)
                                            .subscribe(
                                                (uData) => {
                                                    hostingContent +=
                                                        "<br>" +
                                                        res[
                                                            "INVOICES.HOSTING_DESC_CONTACTS"
                                                        ] +
                                                        ":<br>" +
                                                        uData.field_forename[0]
                                                            .value +
                                                        uData.field_surname[0]
                                                            .value +
                                                        "<br>" +
                                                        uData.field_phone[0]
                                                            .value +
                                                        "<br>" +
                                                        uData.mail[0].value;
                                                    // console.log(uData);
                                                    const hostingsModalRef =
                                                        this.modalService.open(
                                                            InvoiceServiceInfoComponent,
                                                            { size: "sm" }
                                                        );
                                                    hostingsModalRef.componentInstance.invoiceName =
                                                        invoice.name;
                                                    hostingsModalRef.componentInstance.content =
                                                        hostingContent;
                                                    hostingsModalRef.result.then(
                                                        (result) => {
                                                            console.log(result);
                                                        },
                                                        (reason) => {
                                                            console.log(reason);
                                                        }
                                                    );
                                                },
                                                (error) => console.log(error)
                                            );
                                    });
                            }
                        },
                        (err) => console.log(err)
                    );
                break;
            case "copyright":
                path = `/intellect-property/copyrights/${invoice.service.target_id}`;
                break;
            case "design_patent":
                path = `/intellect-property/industrial-designs/${invoice.service.target_id}`;
                break;
            case "invention":
                path = `/intellect-property/patents/${invoice.service.target_id}`;
                break;
            case "logo":
                path = `/design/logo/${invoice.service.target_id}`;
                break;
            case "naming":
                path = `/design/naming/${invoice.service.target_id}`;
                break;
            case "brandbook":
                path = `/design/brandbook/${invoice.service.target_id}`;
                break;
            case "identity":
                path = `/design/identity/${invoice.service.target_id}`;
                break;
            case "certificat":
                let certificatContent = "";
                this.usersService
                    .getOne(invoice.service.target_id, "certificat")
                    .subscribe(
                        (data) => {
                            if (data) {
                                this.translate
                                    .get([
                                        "INVOICES.CERT_DESC_CERTIFICATE",
                                        "INVOICES.CERT_DESC_CODE",
                                        "INVOICES.CERT_DESC_OWNER",
                                        "INVOICES.CERT_DESC_CONTACT",
                                    ])
                                    .subscribe((res: string) => {
                                        certificatContent +=
                                            res[
                                                "INVOICES.CERT_DESC_CERTIFICATE"
                                            ] +
                                            ": " +
                                            data.name[0].value +
                                            "<br>";
                                        certificatContent +=
                                            res["INVOICES.CERT_DESC_CODE"] +
                                            ": " +
                                            data.code[0].value +
                                            "<br>";
                                        certificatContent +=
                                            res["INVOICES.CERT_DESC_OWNER"] +
                                            ": " +
                                            data.certificat_owner[0].value +
                                            "<br>";
                                        certificatContent +=
                                            res["INVOICES.CERT_DESC_CONTACT"] +
                                            ": " +
                                            data.contact_person[0].value +
                                            "<br>" +
                                            data.phone[0].value;
                                        const certificatModalRef =
                                            this.modalService.open(
                                                InvoiceServiceInfoComponent,
                                                { size: "sm" }
                                            );
                                        certificatModalRef.componentInstance.invoiceName =
                                            invoice.name;
                                        certificatModalRef.componentInstance.content =
                                            certificatContent;
                                        certificatModalRef.result.then(
                                            (result) => {
                                                console.log(result);
                                            },
                                            (reason) => {
                                                console.log(reason);
                                            }
                                        );
                                    });
                            }
                        },
                        (err) => console.log(err)
                    );
                break;
            case "objection":
                path = `/objections/view/${invoice.service.target_id}`;
                break;
            case "tm_transfer":
                path = `/transfer-rights/view/${invoice.service.target_id}`;
                break;
        }
        // console.log(path);
        if (path !== "") {
            this.router.navigate([path]);
        }

        // 1: {value: "no_service", label: "No service"}
        // 4: {value: "service", label: "Служба"}
        // 7: {value: "monitoring", label: "Monitoring"}

        // 16: {value: "certificat", label: "Certificat"}
        // 5: {value: "hosting", label: "Hosting"}
        // 6: {value: "domain", label: "Домен"}
        // 3: {value: "tm_search", label: "TM Search"}
        // 2: {value: "application", label: "TM application"}
        // 8: {value: "copyright", label: "Copyright"}
        // 9: {value: "design_patent", label: "Design patent"}
        // 10: {value: "invention", label: "Invention"}
        // 11: {value: "logo", label: "Логотип"}
        // 12: {value: "naming", label: "Naming"}
        // 13: {value: "brandbook", label: "Brandbook"}
        // 14: {value: "identity", label: "Айдентика"}
        // 15: {value: "trademark", label: "Trademark"}
    }

    clearPayedDate(field: string) {
        this.filter_form.get("payed_date").get(field).patchValue("");
    }

    clearAllPayedDate() {
        this.filter_form.get("payed_date").get("min").patchValue("");
        this.filter_form.get("payed_date").get("max").patchValue("");
    }

    setPayedToday() {
        const today = new Date();
        this.filter_form
            .get("payed_date")
            .get("min")
            .patchValue(today.toISOString().substring(0, 10));
        this.filter_form
            .get("payed_date")
            .get("max")
            .patchValue(today.toISOString().substring(0, 10));
    }
}
