import { UnDrupalizeObject } from '../../shared/common/undrupalize-object';

export class DomainContact {
    public auth_info;
    public changed: number;
    public city: string;
    public company_code;
    public contact_email: string;
    public contact_type: string;
    public country: string;
    public created: number;
    public disclose: boolean;
    public epp_id;
    public fax;
    public flat: string;
    public forename: string;
    public house_num: string;
    public id: number;
    public langcode: string;
    public name: string;
    public organization: string;
    public person: string;
    public phone;
    public postal_code: string;
    public state: string;
    public status: boolean;
    public street: string;
    public surname: string;
    public user_id;
// 0: {target_id: 27, target_type: "user", target_uuid: "d267dfbd-d9f1-4d35-8e2d-19d48b1dec53", url: "/ru/user/27"}
// length: 1
// __proto__: Array(0)
    public uuid: string;

    static getInstanceFromDrupal(drupalData) {
        return new DomainContact(UnDrupalizeObject.format(drupalData));
    }

    constructor(data) {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                this[key] = data[key];
            }
        }
    }
}
