export class UnDrupalizeObject {
    static format<T>(drupalObj: any) {
        const undrupalObj = {} as T;
        const untouchable = [
            "applicants",
            "applicant",
            "national_database",
            "wipo_database",
        ];
        const hasValueProp = ["classes_details"]; // params that contain their own (non-drupal) 'value' property
        for (const param in drupalObj) {
            if (drupalObj.hasOwnProperty(param)) {
                if (drupalObj[param] instanceof Array) {
                    if (untouchable.indexOf(param) !== -1) {
                        undrupalObj[param] = drupalObj[param];
                    } else if (
                        drupalObj[param].length === 1 &&
                        param !== "classes"
                    ) {
                        if (
                            drupalObj[param][0].value !== undefined &&
                            hasValueProp.indexOf(param) === -1
                        ) {
                            undrupalObj[param] = drupalObj[param][0].value;
                        } else {
                            undrupalObj[param] = drupalObj[param][0];
                        }
                    } else if (
                        drupalObj[param].length > 1 ||
                        param === "classes"
                    ) {
                        const cleanArray = [];
                        for (const item of drupalObj[param]) {
                            cleanArray.push(item.value);
                        }
                        undrupalObj[param] = cleanArray;
                    }
                } else {
                    undrupalObj[param] = drupalObj[param];
                }
            }
        }
        return undrupalObj;
    }

    static fillObject(drupalObj: any, objToFill: any) {
        for (const param in drupalObj) {
            if (drupalObj.hasOwnProperty(param)) {
                if (drupalObj[param] instanceof Array) {
                    if (param === "applicants") {
                        objToFill[param] = drupalObj[param];
                    } else if (drupalObj[param].length === 1) {
                        if (drupalObj[param][0].value !== undefined) {
                            objToFill[param] = drupalObj[param][0].value;
                        } else {
                            objToFill[param] = drupalObj[param][0];
                        }
                    } else if (drupalObj[param].length > 1) {
                        const cleanArray = [];
                        for (const item of drupalObj[param]) {
                            cleanArray.push(item.value);
                        }
                        objToFill[param] = cleanArray;
                    }
                } else {
                    objToFill[param] = drupalObj[param];
                }
            }
        }
        return objToFill;
    }
}

// export class UnDrupalizeObject {
//     static format<T>(drupalObj: any) {
//         const undrupalObj = {} as T;
//         for (const param in drupalObj) {
//             if (drupalObj.hasOwnProperty(param)) {
//                 if (drupalObj[param] instanceof Array) {
//                     if (drupalObj[param].length === 1) {
//                         if (drupalObj[param][0].hasOwnProperty('value')) {
//                             undrupalObj[param] = drupalObj[param][0].value;
//                         } else {
//                             undrupalObj[param] = drupalObj[param][0];
//                         }
//                     } else if (drupalObj[param].length > 1) {
//                         const cleanArray = [];
//                         for (const item of drupalObj[param]) {
//                             if (item.hasOwnProperty('value')) {
//                                 cleanArray.push(item.value);
//                             } else {
//                                 cleanArray.push(item);
//                             }
//                         }
//                         undrupalObj[param] = cleanArray;
//                     }
//                 } else {
//                     undrupalObj[param] = drupalObj[param];
//                 }
//             }
//         }
//         return undrupalObj;
//     }
// }
