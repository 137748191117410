import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { DrupalizeObject } from '../../shared/common/drupalize-object';
import { GetCountriesService } from '../../shared/services/get-countries.service';
import { DomainContact } from '../models/DomainContact';
import { DomainsHostingsDataService } from '../services/domains-hostings-data.service';

@Component({
  selector: 'app-domain-contact-edit',
  templateUrl: './domain-contact-edit.component.html',
  styleUrls: ['./domain-contact-edit.component.scss']
})
export class DomainContactEditComponent implements OnInit {

    public contactId = '';
    public contact;
    public allCountries = [];

    public disclose = false;

    public contact_form: FormGroup;

    public contactPersonFields = [
        { name: 'COMMON.FULL_NAME', form_name: 'name' },
        { name: 'COMMON.SURNAME', form_name: 'surname' },
        { name: 'COMMON.FORENAME', form_name: 'forename' },
        { name: 'COMMON.PERSON', form_name: 'person' },
        { name: 'COMMON.ORGANIZATION', form_name: 'organization' },
        { name: 'COMMON.COMPANY_CODE', form_name: 'company_code' },
    ];

    public contactContactFields = [
        { name: 'Email', form_name: 'contact_email' },
        { name: 'COMMON.FAX', form_name: 'fax' },
        { name: 'COMMON.PHONE', form_name: 'phone' },
    ];

    public contactAddressFields = [
        { name: 'COMMON.COUNTRY', form_name: 'country' },
        { name: 'COMMON.REGION', form_name: 'state' },
        { name: 'COMMON.POSTAL_CODE', form_name: 'postal_code' },
        { name: 'COMMON.CITY', form_name: 'city' },
        { name: 'COMMON.STREET', form_name: 'street' },
        { name: 'COMMON.HOUSE', form_name: 'house_num' },
        { name: 'COMMON.APT', form_name: 'flat' },
    ];

    constructor(
        public formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        public domainsService: DomainsHostingsDataService,
        public toastr: ToastrService,
        public countriesService: GetCountriesService,
        public translate: TranslateService
    ) { }

    ngOnInit() {
        this.contact_form = this.formBuilder.group({
            // auth_info: [''],
            // changed: [''],
            city: [''],
            company_code: [''],
            contact_email: [''],
            // contact_type: [''],
            country: [''],
            // created: [''],
            // disclose: [false],
            // epp_id: [''],
            fax: [''],
            flat: [''],
            forename: [''],
            house_num: [''],
            // id: [''],
            // langcode: [''],
            name: [''],
            organization: [''],
            person: [''],
            phone: [''],
            postal_code: [''],
            state: [''],
            // status: [''],
            street: [''],
            surname: [''],
            // user_id: [''],
            // status: ['', Validators.required]
        });

        this.countriesService.getAllCountries()
            .subscribe(
                data => {
                    this.allCountries = data;
                },
                err => {
                    console.log(err);
                }
            );

        this.domainsService.getOne(this.contactId, 'domain_contact').subscribe(
            data => {
                this.contact = DomainContact.getInstanceFromDrupal(data);
                console.log(this.contact);
                this.contact_form.patchValue(this.contact, { emitEvent: false });
            },
            err => {
                this.translate.get('DOMAINS.ERROR_LOADING_CONTACT_DATA').subscribe((res: string) => {
                    this.toastr.error(res);
                });
                this.toastr.error(err.error ? err.error.message : '');
                console.log(err);
            }
        );
    }

    public cancel() {
        this.activeModal.close({ savedContact: null });
    }

    public saveContact() {
        const formValue = this.contact_form.value;
        let payload = {};
        for (const key in formValue) {
            if (formValue.hasOwnProperty(key) && key !== 'id') {
                payload[key] = formValue[key];
            }
        }
        console.log(payload)
        payload = DrupalizeObject.format(payload, [], false);
        console.log(payload)
        this.domainsService.save(this.contact.id, payload, 'domain_contact')
            .subscribe(
                data => {
                    this.translate.get('DOMAINS.CONTACT_SAVED').subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                    this.activeModal.close({ savedContact: data });
                },
                err => {
                    this.translate.get('DOMAINS.ERROR_SAVING_CONTACT').subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                    this.toastr.error(err.error ? err.error.message : '');
                    console.log(err);
                }
            );
    }
}
