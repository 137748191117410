import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableColumn } from '@swimlane/ngx-datatable';
import { PrettyNamesService } from 'app/shared/services/pretty-names.service';
import { UserServicesService } from 'app/users/services/user-services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

interface Pager {
  current_page: number;
  total_items: number;
  total_pages: number;
  items_per_page: number;
}

@Component({
  selector: 'app-user-service-list',
  templateUrl: './user-service-list.component.html',
  styleUrls: ['./user-service-list.component.scss']
})
export class UserServiceListComponent implements OnInit {
  @ViewChild('statusTmpl', {static: true}) statusTmpl: TemplateRef<any>;
  @ViewChild('viewTmpl', {static: true}) viewTmpl: TemplateRef<any>;
  @ViewChild('prettyTmpl', {static: true}) prettyTmpl: TemplateRef<any>;
  @ViewChild('linkTmpl', {static: true}) linkTmpl: TemplateRef<any>;
  @ViewChild('dateTmpl', {static: true}) dateTmpl: TemplateRef<any>;
  @ViewChild('headerTmpl', {static: true}) headerTmpl: TemplateRef<any>;
  
  @Input() title;
  @Input() serviceType;
  @Input() userId;
  @Input() path;
  @Input() filters;
  @Input() columns: Array<TableColumn>;

  public rows = [];

  public filter_form: FormGroup;

  public page = '0';

  public pager: Pager = {
    current_page: 0,
    total_items: 0,
    total_pages: 0,
    items_per_page: 50
  };

  public prettyValues = {}

  constructor(
      public service: UserServicesService,
      public formBuilder: FormBuilder,
      public route: ActivatedRoute,
      public router: Router,
      public toastr: ToastrService,
      public spinner: NgxSpinnerService,
      public modalService: NgbModal,
      public prettyService: PrettyNamesService
  ) {
    this.prettyValues = {
      ...this.prettyService.prettyStates,
      ...this.prettyService.prettyIdentityValues,
      ...this.prettyService.prettyLogoValues,
      ...this.prettyService.prettyNamingValues,
      ...this.prettyService.prettyPatentTypes,
      ...this.prettyService.prettyCopyrightValues
    }
  }

  ngOnInit() {
    if (this.columns) {
      this.columns = this.columns.map(column => {
        column.headerTemplate = this.headerTmpl;
        if (column['isViewBtn']) {
          delete column['isViewBtn'];
          return {...column, cellTemplate: this.viewTmpl};
        } else if (column['prettify']) {
          delete column['prettify'];
          return {...column, cellTemplate: this.prettyTmpl};
        } else if (column['isLink']) {
          delete column['isLink'];
          return {...column, cellTemplate: this.linkTmpl};
        } else if (column['isDate']) {
          delete column['isDate'];
          return {...column, cellTemplate: this.dateTmpl};
        }
        return column;
      })
    }
    this.goForIt();
  }

  public goForIt() {
    this.service.fetchServices(this.path, this.page, this.filters).subscribe(
      data => {
        this.rows = data.rows;
        if (data.pager) {
          this.pager = data.pager;
        }
      }, err => {}
    );
  }

  public onPageChanged(event) {
    this.page = String(event.offset);
    this.goForIt();
  }

  public activate(event) {
    //
  }

  public goToService(row) {
    const path = this.getServiceLink(row);
    if (path !== '') {
      this.router.navigate([path]);
    }
  }

  public getServiceLink(row) {
    let path = '';
    switch (this.serviceType) {
        case 'application':
          if (row.type === 'ukraine') {
            path = `/trademarks/applications/${row.id}`;
          } else if (row.type === 'national' || row.type === 'usa') {
            path = `/trademarks/international/${row.id}`;
          } else if (row.type === 'madrid') {
            path = `/trademarks/madrid/${row.id}`;
          }
          break;
        case 'trademark':
          path = `/control/trademark/view/${row.id}`;
          break;
        case 'copyright':
          path = `/intellect-property/copyrights/${row.id}`;
          break;
        case 'design_patent':
          path = `/intellect-property/industrial-designs/${row.id}`;
          break;
        case 'invention':
          path = `/intellect-property/patents/${row.id}`;
          break;
        case 'logo':
          path = `/design/logo/${row.id}`;
          break;
        case 'naming':
          path = `/design/naming/${row.id}`;
          break;
        case 'brandbook':
          path = `/design/brandbook/${row.id}`;
          break;
        case 'identity':
          path = `/design/identity/${row.id}`;
          break;
        case 'objection':
          path = `/objections/view/${row.id}`;
          break;
        case 'tm_transfer':
          path = `/transfer-rights/view/${row.id}`;
          break;
    }
    return path;
  }

  public getPrettyValue(value) {
    return this.prettyValues[value] ? this.prettyValues[value] : value;
  }
}
