import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { AuthService } from "../auth/auth.service";
import { BaseService } from "../services/base.service";
import { ReloadService } from "../services/reload.service";
import { SidebarRoutesService } from "../services/sidebar-routes.service";

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
    public modelRadio = "TM";
    public routesType = new FormControl("TM");

    public isSearchManager = false;
    public isNetManager = false;
    public topRole = "";

    langControl = new FormControl();
    langs = [];

    constructor(
        private auth: AuthService,
        private router: Router,
        private location: Location,
        private routesService: SidebarRoutesService,
        private reloadService: ReloadService,
        private baseService: BaseService,
        public translate: TranslateService
    ) {
        this.langs = [...this.translate.getLangs()];
        this.langControl.patchValue(translate.currentLang);
        this.translate.onLangChange.subscribe((langEvent) => {
            if (this.langControl.value !== langEvent.lang) {
                this.langControl.patchValue(langEvent.lang);
            }
        });
        this.langControl.valueChanges.subscribe((lang) => {
            if (this.translate.currentLang !== lang) {
                this.translate.use(lang);
            }
        });

        // route type defines at creation of navbar by current route
        let currentType = this.routesService.getRoutesTypeByRoute(
            this.router.url
        );
        if (!currentType) {
            currentType = this.routesService.defaultRoutesType;
        }
        this.routesType.patchValue(currentType, { emitEvent: false });

        // route type changes after user click on type buttons
        // and reroutes to default path for this route type
        this.routesType.valueChanges.subscribe(
            (data) => {
                this.changeRoutes(data, true);
            },
            (err) => {
                console.log(err);
            }
        );

        // route type changes in responce to url change
        // but only if it differs from previous type
        // and anyways does not reroute to default path
        this.router.events.subscribe(
            (data) => {
                if (data instanceof NavigationEnd) {
                    const oldType = this.routesType.value;
                    let newType = this.routesService.getRoutesTypeByRoute(
                        data.url
                    );
                    if (!newType) {
                        newType = this.routesService.defaultRoutesType;
                    }
                    if (oldType !== newType) {
                        this.routesType.patchValue(newType, {
                            emitEvent: false,
                        });
                        this.changeRoutes(newType, false);
                    }
                }
            },
            (err) => console.log(err)
        );
    }

    ngOnInit() {
        const roles = this.auth.roles;
        if (roles.indexOf("administrator") !== -1) {
            this.topRole = "admin";
        } else if (roles.indexOf("manager") !== -1) {
            this.topRole = "manager";
        } else if (roles.indexOf("worldwide_manager") !== -1) {
            this.topRole = "worldwide_manager";
            this.isNetManager = true;
        } else if (roles.indexOf("searcher") !== -1) {
            this.topRole = "searcher";
            this.isSearchManager = true;
        } else if (roles.indexOf("partner_manager") !== -1) {
            this.topRole = "partner_manager";
        }
    }

    logout() {
        this.auth.logout();
    }

    reload() {
        this.reloadService.reload();
        // if (this.reloadService.activeComponent &&
        //     typeof this.reloadService.activeComponent.ngOnInit === 'function'
        // ) {
        //     console.log('Current active component:');
        //     console.log(this.reloadService.activeComponent);
        //     this.reloadService.activeComponent.ngOnInit();
        // }
    }

    goBack() {
        this.location.back();
    }

    refresh() {
        console.log(this.router);
    }

    changeRoutes(code, navigate?: boolean) {
        this.routesService.setNewRoutes(code);
        if (navigate) {
            this.router.navigate([
                this.routesService.getStartPathForRouteType(code),
            ]);
        }
    }

    toChat() {
        window.open("https://my.shark.chat/", "_blank");
    }
}
