export class Document {
    // actual props
    public id: string;
    public service: string; // for doc save
    public type: string; // doc type id
    public folder: string;
    public document_date: string;
    public editable: Boolean;
    public document_text: string;
    // props for doc view
    public search_status: string;
    public tm_name: string;
    public application_id: string;
    public application_number: string;
    public type_name: string;
    public document_description: string;
    public fileViewURL: string;
    public created: string;
    public speed_up_deadline: string;
    // experiments
    public search_id: string;
    public address: string;
    public replay_document: any;
    public sevzes_state: any;
    public confident: string;
    public seen: string;
    public reference: any;
    public attachment: any;
    public app_type: any;
    public country: string;
    public vchasno_signed: boolean;
    public vchasno_id: boolean;
    public application_state: string;
    public application_type: string;

    constructor(doc: any) {
        // console.log(doc);
        // set actual props
        this.id = doc.id;
        this.type = doc.type;
        this.service = doc.service;
        this.reference = doc.reference;
        if (typeof doc.reference !== 'undefined' && typeof doc.reference === 'string') {
            try {
                this.reference = JSON.parse(doc.reference);
            } catch(err) {}
        }
        this.folder = doc.folder !== false ? doc.folder : '';
        this.document_date = doc.document_date;
        this.editable = (doc.editable === '0' || doc.editable === false) ? false : true;
        // set additional props
        this.document_text = doc.document_text;
        this.search_status = doc.search_status;
        this.application_number = doc.application_number !== false ? doc.application_number : '';
        if (this.application_number === '' && this.reference && this.reference.application_number) {
            this.application_number = this.reference.application_number;
        }
        this.document_description = doc.document_description;
        this.tm_name = doc.tm_name;
        if ((!this.tm_name || this.tm_name === '') && this.reference && this.reference.target_label) {
            this.tm_name = this.reference.target_label;
        }
        this.application_id = doc.application_id;
        if ((!this.application_id || this.application_id === '') && this.reference && this.reference.target_id) {
            this.application_id = this.reference.target_id;
        }
        this.type_name = doc.type_name;
        this.fileViewURL = doc.file__target_id;
        if (typeof doc.file !== 'undefined') {
            this.fileViewURL = doc.file.url;
        }
        this.created = doc.created;
        this.speed_up_deadline = doc.speed_up_deadline;
        this.address = doc.address;
        if ( doc.replay_document) {
            this.replay_document = JSON.parse(doc.replay_document);
        }
        this.sevzes_state = doc.sevzes_state;
        this.confident = doc.confident;
        this.seen = doc.seen;
        if (typeof doc.attachment !== 'undefined' && typeof doc.attachment !== 'string') {
            this.attachment = doc.attachment.map(item => {
                const parts = item.split('/');
                return {url: item, name: parts[parts.length-1]};
            })
        } else if (typeof doc.attachment === 'string' && doc.attachment !== '') {
            const attachments = doc.attachment.split(',');
            this.attachment = attachments.map(item => {
                const parts = item.split('/');
                return {url: item, name: parts[parts.length-1]};
            });
        }
        this.app_type = doc.app_type;
        this.country = doc.country;
        this.vchasno_id = doc.vchasno_id;
        this.vchasno_signed = doc.vchasno_signed;
        if (doc.vchasno_signed && doc.vchasno_signed === '0') {
            this.vchasno_signed = false;
        } else if (doc.vchasno_signed && doc.vchasno_signed === '1') {
            this.vchasno_signed = true;
        }
        this.application_state = doc.application_state;
        this.application_type = doc.application_type;
    }
}
