import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../../shared/services/base.service';
import { Document } from '../models/Document';

interface Pager {
    current_page: number;
    total_items: number;
    total_pages: number;
    items_per_page: number;
}

@Injectable({
    providedIn: 'root'
})
export class GetDocumentsService extends BaseService {
    static ngInjectableDef = undefined;

    private documentsURI = '/admin/profit/document/list/';

    /**
     * Fetch documents from Drupal backend view
     *
     * @param page page numbder
     * @param appID application context filter
     * @param contain term for search in applications name/id
     * @param type document type
     * @param uid user id
     * @param uri list uri
     */
    public fetch(
        page = '0',
        appID = 'all',
        uri = '/admin/profit/document/list/',
        filters: any = {
            contain: '',
            type: 'All',
            uid: '',
        },
        reg_period?,
        status?
    ): void {
        const options = { params: { _format: 'json', page, ...filters }, headers: this.headers };
        if (reg_period && reg_period !== '') {
            options.params['reg_period'] = reg_period;
        }
        if (status && status !== '') {
            options.params['status'] = status;
        }
        const pageUrl = `${environment.host}${uri}${appID}`;
        this.http
            .get<any>(pageUrl, options)
            .pipe(
                map(res => {
                    const out = [];
                    for (const doc of res.rows) {
                        try {
                            out.push(new Document(doc));
                        } catch (error) {
                            console.error(error);
                        }
                    }
                    res.rows = out;
                    return res;
                })
            )
            .subscribe(
                data => {
                    this.items.next(data.rows);
                    this.pager = data.pager;
                },
                e => {
                    console.log('Can`t reach server :`(');
                }
            );
    }

    public getTypes(appType?) {
        const condition = {};
        if (appType) {
            condition['procedure_type'] = appType;
        }
        const uri = '/admin/profit/document_type/list';
        return this.http.get<any>(environment.host + uri, { params: { _format: 'json', ...condition }, headers: this.headers });
    }

    public fetchDocsFromZes() {
        const uri = '/admin/profit/document/fetch/sev_zes';
        return this.http.get<any>(environment.host + uri, { params: { _format: 'json' }, headers: this.headers });
    }


    public fetchForService(
        page = '0',
        serviceId = '',
        serviceType = '',
        uri = '/admin/profit/document/list/',
        filters: any = {
            contain: '',
            type: 'All',
            uid: ''
        },
        reg_period?
    ): void {
        const options = { params: { _format: 'json', page, 
        reference_target_type: serviceType, reference_target_id: serviceId, ...filters }, headers: this.headers };
        if (reg_period && reg_period !== '') {
            options.params['reg_period'] = reg_period;
        }
        const pageUrl = `${environment.host}${uri}all`;
        this.http
            .get<any>(pageUrl, options)
            .pipe(
                map(res => {
                    const out = [];
                    for (const doc of res.rows) {
                        try {
                            out.push(new Document(doc));
                        } catch (error) {
                            console.error(error);
                        }
                    }
                    res.rows = out;
                    return res;
                })
            )
            .subscribe(
                data => {
                    this.items.next(data.rows);
                    this.pager = data.pager;
                },
                e => {
                    console.log('Can`t reach server :`(');
                }
            );
    }


    public getServices(contain: string, bundle = 'all', service_type = 'application'): Observable<any> {
        const uri = '/admin/profit/' + service_type + '/list/' + bundle;
        return Observable.create(observer => {
            this.http
                .get<any>(this.host + uri, { params: { _format: 'json', contain }, headers: this.headers })
                .subscribe(
                    m => {
                        if (service_type === 'copyright') {
                            m.rows = m.rows.map(row => {
                                return {
                                    ...row,
                                    tm_name: row.small_title
                                }
                            });
                        }
                        observer.next(m.rows);
                    },
                    e => observer.error(e),
                    () => observer.complete()
                );
        });
    }
}
