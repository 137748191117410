import { GetCountriesService } from "app/shared/services/get-countries.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject, concat, of } from "rxjs";
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    switchMap,
    tap,
} from "rxjs/operators";

import { Component, Input, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { NgbActiveModal, NgbDateAdapter } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

import { ApplicationItem } from "../../control/reminder-form/reminder-form.component";
import { FetchInvoicesService } from "../../invoices/services/fetch-invoices.service";
import { DrupalizeObject } from "../../shared/common/drupalize-object";
import { NgbDateDrupalAdapter } from "../../shared/common/ngb-date-drupal-adapter";
import { UnDrupalizeObject } from "../../shared/common/undrupalize-object";
import { Document } from "../models/Document";
import { GetDocumentsService } from "../services/get-documents.service";

@Component({
    selector: "app-document-form",
    templateUrl: "./document-form.component.html",
    styleUrls: ["./document-form.component.scss"],
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateDrupalAdapter }],
})
export class DocumentFormComponent implements OnInit {
    isReferenseForm = true;
    @Input()
    applicationType: string;
    @Input() document: Document;
    public document_form: FormGroup;
    public title = "DOCUMENTS.DOC_CREATION";
    // TODO Change to Application once had occasion
    public application$: Observable<ApplicationItem[]>;
    public applicationLoading = false;
    public applicationinput$ = new Subject<string>();
    referencedServiceChanged$: Observable<any>;

    public types = [{ code: "UA", name: "Ukraine" }];
    public serviceTypes = [];
    public file: any;

    public countries = [];

    public reg_number = new FormControl("");
    public certDocs = ["certificat_nr", "te", "smr", "certificat_nr_usa"];
    public MB = 1048576;

    get serviceType() {
        return (
            this.document_form.get("reference")?.get("target_type")?.value ??
            "application"
        );
    }

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        public invoicesService: FetchInvoicesService,
        public toastr: ToastrService,
        public docsService: GetDocumentsService,
        public spinner: NgxSpinnerService,
        public countriesService: GetCountriesService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        const today = new Date();
        if (this.isReferenseForm) {
            this.document_form = this.formBuilder.group({
                id: [""],
                name: [""],
                reference: this.formBuilder.group({
                    target_id: [""],
                    target_type: ["trademark", Validators.required],
                }),
                type: ["All", Validators.required],
                file: [""],
                folder: [""],
                country: ["UA"],
                document_date: [today.toISOString().substring(0, 10)],
                application_number: [""],
                submission_date: [""],
                tm_number: [""],
                registration_date: [today.toISOString().substring(0, 10)],
            });
        } else {
            this.document_form = this.formBuilder.group({
                id: [""],
                name: [""],
                service: [""],
                type: ["All", Validators.required],
                file: [""],
                folder: [""],
                country: ["UA"],
                document_date: [today.toISOString().substring(0, 10)],
                application_number: [""],
                submission_date: [""],
                tm_number: [""],
                registration_date: [today.toISOString().substring(0, 10)],
            });
        }

        this.countriesService.getAllCountries().subscribe(
            (data) =>
                (this.countries = [
                    ...data,
                    { code: "EU", name: "European Union" },
                ]),
            (err) => console.log(err)
        );
        this.invoicesService.getServiceTypes().subscribe(
            (data) => (this.serviceTypes = data),
            (err) => console.log(err)
        );
        const base_items = [];
        if (this.document) {
            this.title = "DOCUMENTS.DOCUMENT_EDITING";
            this.document_form.patchValue(this.document);
            if (this.document.reference) {
                base_items.push({
                    id: this.document.reference.target_id,
                    tm_name: this.document.reference.target_label,
                });
            } else {
                base_items.push({
                    id: this.document.application_id,
                    tm_name: this.document.tm_name,
                });
            }
            this.application$ = of(base_items);
        }

        this.docsService.getTypes(this.applicationType).subscribe(
            (m) => (this.types = m),
            (e) => console.log(e)
        );

        this.application$ = concat(
            of(base_items), // default items
            this.applicationinput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.applicationLoading = true)),
                switchMap((term) =>
                    (this.isReferenseForm
                        ? this.docsService.getServices(
                              term,
                              "all",
                              this.serviceType
                          )
                        : this.invoicesService.getServices(term)
                    ).pipe(
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.applicationLoading = false))
                    )
                )
            )
        );

        this.referencedServiceChanged$ = this.document_form
            .get("reference")
            .valueChanges.pipe(tap(console.log));
    }

    saveDocument() {
        if (!this.document_form.valid) {
            this.translate
                .get("ALERTS.FILL_ALL_FIELDS")
                .subscribe((res: string) => {
                    this.toastr.error(res);
                });
            return;
        }
        const form_data = this.document_form.value;
        this.spinner.show();

        if (this.file) {
            this.docsService
                .uploadFile(this.file, null, {
                    showLoader: false,
                    params: { exist_option: "replace" },
                })
                .subscribe(
                    (res) => {
                        form_data.file = res["file_id"];
                        this.saveDocEntity(form_data);
                    },
                    (file_error) => {
                        this.saveDocEntity(form_data);
                        if (file_error.statusText === "No files in request") {
                            // Go empty
                            this.saveDocEntity(form_data);
                        } else {
                            this.translate
                                .get("DOCUMENTS.ERROR_SAVING_FILE")
                                .subscribe((res: string) => {
                                    this.toastr.error(res);
                                });
                            this.spinner.hide();
                        }
                    }
                );
        } else {
            this.saveDocEntity(form_data);
        }
    }

    saveDocEntity(form_data) {
        const reference = [form_data.reference];
        const doc_payload = DrupalizeObject.format(form_data, [
            "application_number",
            "submission_date",
            "tm_number",
            "registration_date",
        ]);
        if (this.isReferenseForm) {
            doc_payload["reference"] = reference;
        }
        if (this.certDocs.indexOf(form_data.type) !== -1) {
            doc_payload["raw_data"] = [
                {
                    value: JSON.stringify({
                        registration_date: form_data.registration_date,
                        registration_number: form_data.tm_number,
                    }),
                },
            ];
        }
        this.docsService
            .save(this.document_form.value.id, doc_payload, "document", {
                showLoader: false,
            })
            .subscribe(
                (m) => {
                    const undrupalized: any = UnDrupalizeObject.format(m);
                    const new_document = new Document(undrupalized);
                    this.translate
                        .get("DOCUMENTS.DOC_SAVED")
                        .subscribe((res: string) => {
                            this.toastr.success(res);
                        });

                    if (form_data.type === "tt") {
                        // update application
                        const application_payload = DrupalizeObject.format({
                            application_number: form_data.application_number,
                            submission_date: `${form_data.submission_date}T12:00:00`,
                            type: "ukraine",
                        });
                        const serviceId =
                            form_data.reference && form_data.reference.target_id
                                ? form_data.reference.target_id
                                : form_data.service;
                        this.docsService
                            .save(
                                serviceId,
                                application_payload,
                                "application",
                                { showLoader: false }
                            )
                            .subscribe(
                                (application_response) => {
                                    this.translate
                                        .get("DOCUMENTS.APP_UPDATED")
                                        .subscribe((res: string) => {
                                            this.toastr.success(res);
                                        });
                                },
                                (e) => {
                                    console.log(e);
                                    this.toastr.error(
                                        e.error ? e.error.message : ""
                                    );
                                },
                                () => {
                                    this.activeModal.close(new_document);
                                    this.spinner.hide();
                                }
                            );
                    } else if (form_data.type === "smr" && form_data.service) {
                        const application_payload = DrupalizeObject.format({
                            field_registration_number: this.reg_number.value,
                            type: "madrid",
                        });
                        const serviceId =
                            form_data.reference && form_data.reference.target_id
                                ? form_data.reference.target_id
                                : form_data.service;
                        this.docsService
                            .save(
                                serviceId,
                                application_payload,
                                "application",
                                { showLoader: false }
                            )
                            .subscribe(
                                (application_response) => {
                                    this.translate
                                        .get("DOCUMENTS.APP_UPDATED")
                                        .subscribe((res: string) => {
                                            this.toastr.success(res);
                                        });
                                },
                                (e) => {
                                    console.log(e);
                                    this.toastr.error(
                                        e.error ? e.error.message : ""
                                    );
                                },
                                () => {
                                    this.activeModal.close(new_document);
                                    this.spinner.hide();
                                }
                            );
                    } else {
                        this.activeModal.close(new_document);
                        this.spinner.hide();
                    }
                },
                (e) => {
                    console.log(e);
                    this.translate
                        .get("DOCUMENTS.ERROR_SAVING_DOC")
                        .subscribe((res: string) => {
                            this.toastr.error(res);
                        });
                    this.toastr.error(e.error ? e.error.message : "");
                    this.spinner.hide();
                }
            );
    }

    keepFiles(files) {
        if (files.item(0).size > this.MB * 20) {
            this.file = null;
            this.document_form.get("file").patchValue(null);
            this.translate
                .get("DOCUMENTS.FILE_TOO_BIG")
                .subscribe((res: string) => {
                    this.toastr.error(res);
                });
            return;
        }
        this.file = files.item(0);
    }
}
