import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseService } from 'app/shared/services/base.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import { Reminder } from '../../../control/models/reminder.model';
import { DrupalizeObject } from '../../common/drupalize-object';
import { NgbDateDrupalAdapter } from '../../common/ngb-date-drupal-adapter';

export interface ApplicationItem {
    id: number;
    tm_name: string;
}

@Component({
    selector: 'app-reminder-form',
    templateUrl: './reminder-form.component.html',
    styleUrls: ['./reminder-form.component.scss'],
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateDrupalAdapter }]
})
export class ReminderFormComponent implements OnInit {
    @Input() reminder: Reminder;
    public reminder_form: FormGroup;
    public title = 'CONTROL.CREATE_REMINDER';
    // TODO Change to Application once had occasion
    public application$: Observable<ApplicationItem[]>;
    public applicationLoading = false;
    public applicationinput$ = new Subject<string>();
    public types = [{ label: 'All', value: 'All' }];

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        public toastr: ToastrService,
        public spinner: NgxSpinnerService,
        public baseService: BaseService,
        public translate: TranslateService
    ) {
        this.reminder_form = this.formBuilder.group({
            id: [''],
            notify_date: ['', Validators.required],
            type: ['', Validators.required],
            service: ['', Validators.required]
        });
    }

    ngOnInit() {
        const base_items = [];
        if (this.reminder) {
            this.title = 'CONTROL.EDIT_REMINDER';
            this.reminder_form.patchValue(this.reminder);
            base_items.push({ id: this.reminder.service, tm_name: this.reminder.tm_name });
        }

        this.application$ = concat(
            of(base_items), // default items
            this.applicationinput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.applicationLoading = true)),
                switchMap(term =>
                    this.baseService.getServices({contain: term}).pipe(
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.applicationLoading = false))
                    )
                )
            )
        );

        this.baseService.getTypes('reminder_type').subscribe(m => (this.types = m), e => console.log(e));
    }

    saveReminder() {
        if (!this.reminder_form.valid) {
            this.translate.get('ALERTS.FILL_ALL_FIELDS').subscribe((res: string) => {
                this.toastr.error(res);
            });
            return;
        }
        this.spinner.show();
        const reminderpayload = DrupalizeObject.format(this.reminder_form.value);
        this.baseService.save(this.reminder_form.value.id, reminderpayload, 'reminder').subscribe(
            m => {
                this.translate.get('CONTROL.REMINDER_SAVED').subscribe((res: string) => {
                    this.toastr.success(res);
                });
                this.activeModal.close('After successful save');
            },
            e => {
                console.log(e);
                this.translate.get('CONTROL.ERROR_SAVING_REMINDER').subscribe((res: string) => {
                    this.toastr.error(res);
                });
                this.toastr.error(e.error ? e.error.message : '');
            },
            () => {
                this.spinner.hide();
            }
        );
    }
}
