import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentsListComponent } from './documents-list/documents-list.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'documents-list',
                component: DocumentsListComponent,
                data: {
                    title: 'Документи'
                },
            }
        ]
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentsRoutingModule { }
