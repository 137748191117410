import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'environments/environment';
import { LoaderInterceptorParams } from './LoaderInterceptorParams';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private exceptionPaths = [];
  private host = environment.host;
  constructor(private spinner: NgxSpinnerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.showLoader(req)) {
      this.spinner.show();
      return next.handle(req)
        .pipe(
          finalize(() => {
            this.spinner.hide();
          })
        );
    } else {
      return next.handle(req);
    }
  }

  showLoader(req) {
    if (req.params instanceof LoaderInterceptorParams && !req.params.interceptorConfig.showLoader) {
      return false;
    }
    return (req.method === 'POST' || req.method === 'PATCH' || req.method === 'DELETE') && 
    this.exceptionPaths.indexOf(req.url.slice(this.host.length)) === -1;
  }
}