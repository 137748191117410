import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { DrupalizeObject } from '../../shared/common/drupalize-object';
import { NgbDateDrupalAdapter } from '../../shared/common/ngb-date-drupal-adapter';
import { GetCountriesService } from '../../shared/services/get-countries.service';
import { Domain } from '../models/Domain';
import { DomainContact } from '../models/DomainContact';
import { DomainsHostingsDataService } from '../services/domains-hostings-data.service';

@Component({
  selector: 'app-domain-details-edit',
  templateUrl: './domain-details-edit.component.html',
    styleUrls: ['./domain-details-edit.component.scss'],
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateDrupalAdapter }]
})
export class DomainDetailsEditComponent implements OnInit {

    public domainId;
    public domain;
    public isDomainUA;

    public contactId = '';
    public contact;
    public allCountries = [];

    public disclose = false;

    public domain_form: FormGroup;

    public isCollapsedPerson = true;
    public isCollapsedData = true;
    public isCollapsedAddress = true;

    public user$: Observable<any[]>;
    public userLoading = false;
    public userinput$ = new Subject<string>();
    public base_users: any[] = [];

    public contactPersonFields = [
        { name: 'COMMON.FULL_NAME', form_name: 'name' },
        { name: 'COMMON.SURNAME', form_name: 'surname' },
        { name: 'COMMON.FORENAME', form_name: 'forename' },
        { name: 'COMMON.PERSON', form_name: 'person' },
        { name: 'COMMON.ORGANIZATION', form_name: 'organization' },
        { name: 'COMMON.COMPANY_CODE', form_name: 'company_code' },
    ]

    public contactFields = [
        { name: 'Email', form_name: 'contact_email' },
        { name: 'COMMON.FAX', form_name: 'fax' },
        { name: 'COMMON.PHONE', form_name: 'phone' },
    ]

    public contactAddressFields = [
        { name: 'COMMON.COUNTRY', form_name: 'country' },
        { name: 'COMMON.REGION', form_name: 'state' },
        { name: 'COMMON.POSTAL_CODE', form_name: 'postal_code' },
        { name: 'COMMON.CITY', form_name: 'city' },
        { name: 'COMMON.STREET', form_name: 'street' },
        { name: 'COMMON.HOUSE', form_name: 'house_num' },
        { name: 'COMMON.APT', form_name: 'flat' },
    ];

    constructor(
        public formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        public domainsService: DomainsHostingsDataService,
        public toastr: ToastrService,
        public spinner: NgxSpinnerService,
        public countriesService: GetCountriesService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        this.domain_form = this.formBuilder.group({
            free_domain: [false],
            certificate_id: [''],
            city: [''],
            company_code: [''],
            contact_email: [''],
            country: [''],
            fax: [''],
            flat: [''],
            forename: [''],
            house_num: [''],
            name: [''],
            organization: [''],
            person: [''],
            phone: [''],
            postal_code: [''],
            state: [''],
            street: [''],
            surname: [''],
            registered: [''],
            extended: [''],
            status: [false],
            user_id: [null]
        });

        this.countriesService.getAllCountries()
            .subscribe(
                data => {
                    this.allCountries = data;
                },
                err => {
                    console.log(err);
                }
            );

        if (this.domainId) {
            this.domainsService.getOne(this.domainId, 'domain').subscribe(
                data => {
                    console.log(data);
                    this.domain = Domain.getInstanceFromDrupal(data);
                    const domainParts = this.domain.name.split('.');
                    this.isDomainUA = domainParts.length === 2 && domainParts[1] === 'ua';
                    if (this.domain.domain_contact && this.domain.domain_contact.target_id) {
                        this.contactId = this.domain.domain_contact.target_id;
                        this.domainsService.getOne(this.contactId, 'domain_contact').subscribe(
                            contactData => {
                                this.contact = DomainContact.getInstanceFromDrupal(contactData);
                                console.log(this.contact);
                                console.log(this.contact.disclose);
                                const contact_payload = {...this.contact};
                                delete contact_payload.user_id;
                                this.domain_form.patchValue(contact_payload, { emitEvent: false });
                                if (this.domain.tm) {
                                    this.domain_form.get('certificate_id').patchValue(this.domain.tm, { emitEvent: false });
                                }
                                this.domain_form.get('free_domain').patchValue(this.domain.free_domain, { emitEvent: false });
                                this.domain_form.get('registered').patchValue(this.domain.registered, { emitEvent: false });
                                this.domain_form.get('extended').patchValue(this.domain.extended, { emitEvent: false });
                                this.domain_form.get('status').patchValue(this.domain.status, { emitEvent: false });
                            },
                            err => {
                                this.translate.get('DOMAINS.ERROR_LOADING_DOMAIN_DATA').subscribe((res: string) => {
                                    this.toastr.error(res);
                                });
                                this.toastr.error(err.error ? err.error.message : '');
                                console.log(err);
                            }
                        );
                    } else {
                        this.translate.get('DOMAINS.NO_DOMAIN_CONTACT').subscribe((res: string) => {
                            this.toastr.error(res);
                        });
                        if (this.domain.tm) {
                            this.domain_form.get('certificate_id').patchValue(this.domain.tm, { emitEvent: false });
                        }
                        this.domain_form.get('free_domain').patchValue(this.domain.free_domain, { emitEvent: false });
                        this.domain_form.get('registered').patchValue(this.domain.registered, { emitEvent: false });
                        this.domain_form.get('extended').patchValue(this.domain.extended, { emitEvent: false });
                        this.domain_form.get('status').patchValue(this.domain.status, { emitEvent: false });
                    }
                    console.log(this.domain);
                    this.domain_form.get('user_id').patchValue(this.domain.user_id.target_id);
                    this.domainsService.getUser(this.domain.user_id.target_id).subscribe(
                        data => {
                            const surname = data.field_surname && data.field_surname[0] ? data.field_surname[0].value : '';
                            const forename = data.field_forename && data.field_forename[0] ? data.field_forename[0].value : '';
                            this.base_users.push({
                                fullname: `${surname} ${forename}`, uid: this.domain.user_id.target_id
                            });
                            console.log(this.base_users);
                            this.setUsers();
                            this.userinput$.next('');
                        }
                    )
                },
                err => {
                    this.translate.get('DOMAINS.ERROR_LOADING_DOMAIN_DATA').subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                    this.toastr.error(err.error ? err.error.message : '');
                    console.log(err);
                }
            );
        } else {
            this.setUsers();
        }
    }

    public setUsers() {
        this.user$ = concat(
            of(this.base_users),
            this.userinput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.userLoading = true)),
                switchMap(term =>
                    this.domainsService.getUsers(term).pipe(
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.userLoading = false))
                    )
                )
            )
        );
    }

    public cancel() {
        this.activeModal.close({ registered: false });
    }

    public save() {
        const formValue = this.domain_form.value;
        let payload = {};
        const userId = formValue.user_id;
        for (const key in formValue) {
            if (formValue.hasOwnProperty(key) && key !== 'id' &&
                key !== 'certificate_id' && key !== 'free_domain' &&
                key !== 'registered' && key !== 'extended' && key !== 'status'
            ) {
                payload[key] = formValue[key];
            }
        }
        payload = DrupalizeObject.format(payload, [], false);
        payload['user_id'] = [{target_id: userId}];
        console.log(payload)
        // save changes in domain's contact
        this.spinner.show();
        this.domainsService.save((this.contact && this.contact.id ? this.contact.id : ''), payload, 'domain_contact')
            .subscribe(
                contactData => {
                    // this.spinner.hide();
                    // if certificate number or free status of domain has changed, save it too
                    const domainTmId = this.domain_form.get('certificate_id').value;
                    const isFree = this.domain_form.get('free_domain').value;
                    const registered = this.domain_form.get('registered').value !== '' ? this.domain_form.get('registered').value : null;
                    const extended = this.domain_form.get('extended').value !== '' ? this.domain_form.get('extended').value : null;
                    const status = this.domain_form.get('status').value;
                    // if (this.domain.tm !== domainTmId || this.domain.free_domain !== isFree) {
                        const certPayload = {
                            tm: [{ value: domainTmId }],
                            free_domain: [{ value: isFree }],
                            registered: [{ value: registered }],
                            extended: [{ value: extended }],
                            status: [{ value: status}],
                            user_id: [{ target_id: userId}]
                        };
                        if (!this.contact || !this.contact.id) {
                            this.contact = DomainContact.getInstanceFromDrupal(contactData);
                            this.contactId = this.contact.id;
                            this.domain.domain_contact = {target_id: this.contact.id};
                            certPayload['domain_contact'] = [{ target_id: this.contact.id}];
                        }
                        this.spinner.show();
                        this.domainsService.save(this.domain.id, certPayload, 'domain')
                            .subscribe(
                                domainData => {
                                    this.spinner.hide();
                                    console.log(domainData);
                                    this.translate.get('DOMAINS.DATA_SAVED').subscribe((res: string) => {
                                        this.toastr.success(res);
                                    });
                                },
                                err => {
                                    this.spinner.hide();
                                    console.log(err);
                                    this.translate.get('DOMAINS.ERROR_SAVING_DATA').subscribe((res: string) => {
                                        this.toastr.error(res);
                                    });
                                    this.toastr.error(err.error ? err.error.message : '');
                                }
                            );
                    // } else {
                    //     this.toastr.success('Дані збережені');
                    // }
                },
                err => {
                    this.spinner.hide();
                    console.log(err);
                    this.translate.get('DOMAINS.ERROR_SAVING_CONTACT').subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                    this.toastr.error(err.error ? err.error.message : '');
                }
            );
    }

}
