import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UnDrupalizeObject } from 'app/shared/common/undrupalize-object';
import { BaseService } from 'app/shared/services/base.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-send-viber-message',
  templateUrl: './send-viber-message.component.html',
  styleUrls: ['./send-viber-message.component.scss']
})
export class SendViberMessageComponent implements OnInit {
  private file = null;
  public user_id;
  public user;
  public messageText = new FormControl('');

  constructor(
    public activeModal: NgbActiveModal,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public service: BaseService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    if (this.user_id) {
      this.service.getUser(this.user_id).subscribe(
        data => {
          this.user = UnDrupalizeObject.format(data);
          console.log(this.user);
        }, err => console.log(err)
      )
    }
  }

  public handleFile(event: any) {
    if (event.target.files.length > 0) {
      this.service.uploadFile(event.target.files[0]).subscribe(
        data => {
          this.file = data.file_id;
        },
        err => {
          this.translate.get('ALERTS.ERROR_SAVING_FILE').subscribe((res: string) => {
              this.toastr.error(res);
          });
        }
      );
    } else {
      this.file = null;
    }
  }

  public cancel() {
    this.activeModal.close('cancel');
  }

  public confirm() {
    if (!this.user_id) {
      this.translate.get('ALERTS.USER_NOT_SELECTED').subscribe((res: string) => {
          this.toastr.error(res);
      });
      return;
    }
    if (this.messageText.value.trim() === '') {
      this.translate.get('ALERTS.EMPTY_MESSAGE').subscribe((res: string) => {
          this.toastr.error(res);
      });
      return;
    }
    const payload = {
      text: this.messageText.value.trim(),
      user_id: this.user_id,
    }
    if (this.file) {
      payload['fid'] = Number(this.file);
    }
    this.spinner.show();
    this.service.sendViberMessage(payload).subscribe(
      data => {
        this.spinner.hide();
        this.activeModal.close('sent');
      },
      err => {
        this.spinner.hide();
        this.toastr.error(err.error ? err.error.message : err);
      }
    )
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
