import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UnDrupalizeObject } from 'app/shared/common/undrupalize-object';
import { BaseService } from 'app/shared/services/base.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { GetCountriesService } from '../../services/get-countries.service';

@Component({
  selector: 'app-user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user-editor.component.scss']
})
export class UserEditorComponent implements OnInit {
    public userId;
    public name = '';
    public countries = [];
    public canEdit = true;

    public userForm;

    constructor(
        public service: BaseService,
        public countriesService: GetCountriesService,
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        public toastr: ToastrService,
        public spinner: NgxSpinnerService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        this.userForm = this.formBuilder.group({
            field_phone: ['', Validators.required],
            mail: ['', Validators.required],
            field_forename: [''],
            field_surname: [''],
            field_po_batkovi: [''],
            field_country: ['UA'],
            pass: [''],
            field_viber_number: [''],
            field_person: [''],
            status: [1]
        });
        
        this.countriesService.getAllCountries().subscribe(
            data => {
                this.countries = data;
            },
            err => console.log(err)
        );
        if (this.userId) {
            this.service.getUser(this.userId)
            .subscribe(
                data => {
                    const parsed: any = UnDrupalizeObject.format(data);
                    this.userForm.patchValue(parsed);
                    this.name = parsed.name;
                    console.log(data);
                    if (!this.canEdit) {
                        this.userForm.disable();
                    }
                },
                err => {
                    console.log(err);
                }
            );
        }
    }

    public cancel() {
        this.activeModal.close('cancel');
    }

    public createUser() {
        const payload = this.userForm.value;
        this.spinner.show();
        this.service.registerUser(payload)
            .subscribe(
                data => {
                    this.spinner.hide();
                    this.translate.get('USERS.USER_DATA_SAVED').subscribe((res: string) => {
                        this.toastr.success(res);
                    });
                    this.activeModal.close();
                },
                err => {
                    this.spinner.hide();
                    console.log(err);
                    this.translate.get('USERS.ERROR_SAVING_USER_DATA').subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                    this.toastr.error(err.error ? err.error.message : '');
                }
            );
    }

    public updateUser() {
        const result = {...this.userForm.value};
        this.spinner.show();
        this.service.saveUser(this.userId, result)
            .subscribe(
                data => {
                    this.spinner.hide();
                    this.translate.get('USERS.USER_DATA_SAVED').subscribe((res: string) => {
                        this.toastr.success(res);
                    });
                    this.activeModal.close(UnDrupalizeObject.format(data));
                },
                err => {
                    this.spinner.hide();
                    console.log(err);
                    this.translate.get('USERS.ERROR_SAVING_USER_DATA').subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                    this.toastr.error(err.error ? err.error.message : '');
                }
            );
    }

    public save() {
        if (!this.userForm.valid) {
            this.translate.get('USERS.ENTER_PHONE_AND_EMAIL').subscribe((res: string) => {
                this.toastr.error(res);
            });
            return;
        }
        if (!this.userId) {
            this.createUser();
        } else {
            this.updateUser();
        }
    }
}
