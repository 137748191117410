import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { switchMap, catchError, map } from 'rxjs/operators';
import { TokenData } from './TokenData';
import { of, Observable, interval } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BaseService } from '../services/base.service';

@Injectable()
export class AuthService {
    private oauthPath = '/oauth/token?_format=json';
    public redirectUrl;
    token: string;
    tokenData: TokenData;

    constructor(
        private http: HttpClient,
        private router: Router,
        private helper: JwtHelperService,
        private baseService: BaseService
    ) {
        interval(300000).subscribe(
            m => {
                if (!this.tokenValid()) {
                    localStorage.removeItem('access_token_crm');
                    this.router.navigate(['/login']);
                }
            }
        );
    }

    get uid(): string {
        return localStorage.getItem('duid');
    }

    get roles(): string[] {
        let roles = [];
        try {
            roles = JSON.parse(localStorage.getItem('roles'));
            if (!roles) {
                return [];
            }
        } catch(err) {}
        return roles;
    }

    get isSuperadmin(): boolean {
        let roles = [];
        try {
            roles = JSON.parse(localStorage.getItem('roles'));
            if (!roles) {
                return false;
            }
        } catch(err) {}
        return roles.indexOf('superadmin') !== -1;
    }

    signupUser(email: string, password: string) {
        // your code for signing up the new user
    }

    getJWT(username: string, password: string): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: 'Basic ' + new Buffer(username + `:` + password).toString('base64')
        });
        return this.http.get<any>(environment.host + '/jwt/token', { headers }).pipe(
            map(data => {
                const payload = this.helper.decodeToken(data.token);
                if (payload) {
                    localStorage.setItem('duid', payload.drupal.uid);
                    localStorage.setItem('roles', JSON.stringify(payload.drupal.role));
                }
                localStorage.setItem('access_token_crm', data.token);
                return data;
            })
        );
    }

    refreshJWT(): Observable<any> {
        return this.http.get<any>(environment.host + '/jwt/token').pipe(
            map(data => {
                // console.log(data);
                localStorage.setItem('access_token_crm', data.token);
                const payload = this.helper.decodeToken(data.token);
                if (payload) {
                    localStorage.setItem('roles', JSON.stringify(payload.drupal.role));
                }
                return data;
            })
        );
    }

    getClientJWT(user_id) {
        return this.http.get<any>(environment.host + `/admin/profit/masquerade/${user_id}?_format=json`).pipe(
            map(data => {
                console.log(data);
                // localStorage.setItem('access_token', data.token);
                return data;
            })
        );
    }

    logout() {
        localStorage.removeItem('access_token_crm');

        this.router.navigate(['/login']);
    }

    private tokenExists() {
        const token = localStorage.getItem('access_token_crm');
        if (!token) {
            return false;
        }
        return true;
    }

    private tokenValid() {
        if (this.tokenExists()) {
            const token = localStorage.getItem('access_token_crm');
            const difference = (this.helper.getTokenExpirationDate(token).getTime() - new Date().getTime()) / 60000;
            if (difference < 30 && difference > 0) {
                this.refreshJWT().subscribe(data => {}, e => console.log(e));
            } else if (difference <= 0) {
                localStorage.removeItem('access_token_crm');
                return false;
            }
            return !this.helper.isTokenExpired(token);
        }
        return false;
    }

    isAuthenticated(url): boolean | Observable<boolean> {
        if (this.tokenValid()) {
            return true;
        }

        this.redirectUrl = url;
        this.router.navigate(['/login']);
        return false;
    }

    sendPassword(phone) {
        const passwordUrl = '/api/v1/user'; // `/api/v1/user/reset`;
        // const body = {
        //     phone: phone
        // };
        const body = {
            reset: {
                field_phone: phone
            }
        };
        return this.baseService.getToken().pipe(
            switchMap(token => {
                const headers = new HttpHeaders();
                headers.set('X-CSRF-Token', token as string);
                return Observable.create(observer => {
                    this.http.patch<any>(environment.host + passwordUrl, body, {
                        params: { _format: 'json' },
                        headers
                    })
                        .subscribe(
                            m => observer.next(m),
                            e => observer.error(e),
                            () => observer.complete()
                        );
                });
            })
        );
    }
}
