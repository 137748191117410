import { environment } from "environments/environment.prod";

import { UnDrupalizeObject } from "../../shared/common/undrupalize-object";
import { AppOperationsService } from "../services/app-operations.service";

export class ApplicationsListData {
    public rows: Application[];
    public pager: Pager;

    constructor(data: any, service?: AppOperationsService) {
        this.pager = data.pager;
        this.rows = new Array<Application>();
        const dataRows = data.rows;
        if (dataRows) {
            for (const r of dataRows) {
                const app = new Application(r);
                this.rows.push(app);
            }
        }
    }
}

export class Pager {
    current_page: number;
    total_items: string;
    total_pages: number;
    items_per_page: number;
}

export class PaymentStatus {
    invoice_index: number;
    search_payed: boolean;
    search_ready: boolean;
    fee_payed: boolean;

    constructor(data: string) {
        const parsed = JSON.parse(data);
        this.invoice_index = parsed.invoice_index;
        this.search_payed = parsed.search_payed;
        this.search_ready = parsed.search_ready;
        this.fee_payed = parsed.fee_payed;
    }
}

interface ApplicationLogo {
    alt;
    height;
    target_id;
    target_type;
    target_uuid;
    title;
    url;
    width;
}

interface ApplicationSeller {
    target_id: string;
    target_type: string;
    target_uuid: string;
    url: string;
}

export class Application {
    name: string;
    id: string;
    state: string;
    applicants?: any[];
    applicant_status?: string;
    application_number: string;
    tm_image__target_id: string;
    country: string;
    operations: any;
    user_id;
    tm_name: string;
    tm_type: string;
    created: string;
    application_state: string;
    field_registaration_period: string;
    field_applicants_qty: string;
    field_nationality: string;
    contact_person: string;
    payment_status: PaymentStatus;
    email: string;
    phone: string;
    folder: string;
    tm_image;
    origin: string;
    submission_date?: string;
    seller?: ApplicationSeller;
    image_url?: string;
    discount?: string;
    field_color?: string;
    classes?: any[];
    classes_details?; // value, format, processed
    classes_details_json?;
    research?: boolean;
    type?;
    // refund params
    amount?;
    refund?;
    refund_created?;
    requisites?;
    refund_state?;
    refund_id?;
    refund_screenshot?;
    refund_date?;
    last_log?;
    maskURL?;
    callback?;
    confident?;
    search_result?;
    field_madrid_countries?;
    gift_type?;
    logo_order?;
    using_fb?;
    using_website?;
    credit_pay?;
    application_mode?;
    base_application?;
    tm_image_tmp?;
    activity_details?;
    partner?;
    application_description?;
    with_grant?;

    public static applicationFromDrupal(data: any): Application {
        return UnDrupalizeObject.format<Application>(data);
    }

    public static getFromLostData(lostData, application = new Application()) {
        application.applicant_status =
            lostData.applicant_status === "individual"
                ? "individual"
                : "organization";
        if (lostData.applicants_qty) {
            application.field_applicants_qty = lostData.applicants_qty;
        } else {
            application.field_applicants_qty =
                lostData.applicants && lostData.applicants.length > 1
                    ? "several"
                    : "one";
        }
        application.email = lostData.email;
        application.field_nationality = lostData.nationality;
        if (!lostData.nationality && lostData.field_nationality) {
            application.field_nationality = lostData.field_nationality;
        }
        application.research =
            lostData.presearch === "yes" || lostData.research ? true : false;
        application.field_registaration_period =
            lostData.registaration_period === "std" ||
            lostData.registaration_period === "base"
                ? "base"
                : "urgent7";
        if (
            !lostData.registaration_period &&
            lostData.field_registaration_period
        ) {
            application.field_registaration_period =
                lostData.field_registaration_period === "base"
                    ? "base"
                    : "urgent7";
        }
        application.tm_name = lostData.tm_name;
        if (lostData.tm_color && lostData.tm_color !== "") {
            application.field_color = lostData.tm_color;
        } else {
            application.field_color = lostData.field_color;
        }
        application.tm_type = lostData.tm_type;
        if (lostData.text_classes && lostData.text_classes.length > 0) {
            application.classes = lostData.text_classes.split(",");
        } else {
            application.classes = lostData.classes;
        }
        if (lostData.contact_person) {
            application.contact_person = lostData.contact_person;
        } else if (lostData.person) {
            application.contact_person = lostData.person;
        }

        application.phone = lostData.phone;
        application.user_id = lostData.user_id;
        if (lostData.classes_data) {
            application.classes_details_json = JSON.stringify(
                lostData.classes_data
            );
        }

        // logo logic
        if (lostData.tm_image && lostData.tm_image !== "") {
            application.tm_image = { target_id: lostData.tm_image };
        } else if (lostData.tm_image_file && lostData.tm_image_file !== "") {
            application.tm_image = { target_id: lostData.tm_image_file };
        }

        application.using_website = lostData.using_website;
        application.using_fb = lostData.using_fb;

        application.activity_details = lostData.activity_details;
        if (lostData.country) {
            application.country = lostData.country.toUpperCase();
        }
        if (lostData.seller) {
            application.seller = lostData.seller;
        }
        return application;
    }

    constructor(data?: any) {
        if (data) {
            this.name = data.name;
            this.id = data.id;
            this.field_registaration_period = data.field_registaration_period;
            this.created = data.created;
            this.tm_image__target_id = data.tm_image__target_id;
            this.application_number = data.application_number;
            this.contact_person = data.contact_person;
            this.tm_name = data.tm_name;
            this.tm_type = data.tm_type;
            this.email = data.email;
            this.phone = data.phone;
            this.folder = data.folder;
            this.state = data.state;
            this.application_state = data.application_state;
            this.type = data.type;
            this.origin = data.origin;
            this.refund = data.refund === "1" ? true : false;
            this.using_fb = data.using_fb;
            this.using_website = data.using_website;
            this.credit_pay = data.credit_pay;
            if (data.payment_status) {
                this.payment_status = new PaymentStatus(data.payment_status);
            }
            if (this.tm_image__target_id) {
                this.image_url = `${environment.host}${this.tm_image__target_id}`;
            }
            if (data.submission_date) {
                this.submission_date = data.submission_date;
            }
            if (data.country) {
                this.country = data.country;
            }
            if (data.amount) {
                this.amount = data.amount;
            }
            if (data.refund_created) {
                this.refund_created = data.refund_created;
            }
            if (data.requisites) {
                this.requisites = data.requisites;
            }
            if (data.refund_state) {
                this.refund_state = data.refund_state;
            }
            if (data.refund_id) {
                this.refund_id = data.refund_id;
            }
            if (data.refund_screenshot) {
                this.refund_screenshot = `https://ideabox.name${data.refund_screenshot}`;
            }
            if (data.refund_date) {
                this.refund_date = data.refund_date;
            }
            if (data.applicant_status) {
                this.applicant_status = data.applicant_status;
            }
            if (data.field_madrid_countries) {
                this.field_madrid_countries = data.field_madrid_countries;
            }
            this.user_id = data.user_id;
            this.maskURL = "https://profitmark.ua/switch/" + data.user_id;
            if (data.application_mode) {
                this.application_mode = data.application_mode;
            }
            this.with_grant =
                parseInt(data.with_grant, 10) === 1 ? true : false;
        }
    }
}
