import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InvoicesListComponent } from './invoices-list/invoices-list.component';
import { AuthGuard } from '../shared/auth/auth-guard.service';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'invoices-list',
                component: InvoicesListComponent,
                data: {
                    title: 'Счета'
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InvoicesRoutingModule { }

