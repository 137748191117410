import { Component, OnInit, Renderer2, Inject, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '../auth/auth.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-cabinet',
  templateUrl: './user-cabinet.component.html',
  styleUrls: ['./user-cabinet.component.scss']
})
export class UserCabinetComponent implements OnInit {

    public showSpinner = true;
    public scriptText;
    public userId;
    
    @HostListener('window:message', ['$event'])
    handleMessage(event) {
      if (event.data && event.data.type === 'loggedOut') {
        this.activeModal.close();
      }
    }

    constructor(private sanitizer: DomSanitizer,
        private _renderer2: Renderer2,
        public activeModal: NgbActiveModal,
        @Inject(DOCUMENT) private _document,
        private auth: AuthService) { }

    ngOnInit() {
        setTimeout(() => {
            this.auth.getClientJWT(this.userId).subscribe(
                data => {
                            console.log('got client jwt');
                            console.log(data.jwt);
                    this.injectWizard(data.jwt, this.userId);
                            // window['ProfitApplication'].sendCommand('maskCommand', {'jwt': data.jwt, 'uid': `${this.userId}`});
                            // setTimeout(() => {
                            //     this.showSpinner = false;
                            // }, 500);
                },
                err => {
                            console.log('error getting client jwt');
                            console.log(err);
                }
            )
        }, 100);
    }

    cancel() {
        window['ProfitApplication'].sendCommand('logout');
        this.showSpinner = true;
    }


    public injectWizard(token, userId) {
        const widgetEl = this._document.getElementById('profit_widget_masquerade');
        if (widgetEl) {
            const widget = this._renderer2.createElement('script');
            widget.id = 'profit_widget_masquerade';
            widget.text = `
                (function(d){
                    var f = d.getElementsByTagName('SCRIPT')[0], p = d.createElement('SCRIPT');
                    window.profit_client_id = "697";
                    window.mask_user_id = "${userId}";
                    window.mask_token = "${token}";
                    window.profit_widget_country = "UA";
                    window.profit_widget_height = "";
                    window.profit_widget_width = "full";
                    p.type = 'text/javascript';
                    p.setAttribute('charset','utf-8');
                    p.async = true;
                    p.src = "https://crm.ideabox.name/assets/masking_widget.nocache.js";
                    f.parentNode.insertBefore(p, f);
                }(document));
                `;

            this._renderer2.appendChild(widgetEl, widget);
            this.showSpinner = false;
        }
    }
}
