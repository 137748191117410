import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { DrupalizeObject } from '../../shared/common/drupalize-object';
import { UnDrupalizeObject } from '../../shared/common/undrupalize-object';
import { GetCountriesService } from '../../shared/services/get-countries.service';
import { DomainDetailsComponent } from '../domain-details/domain-details.component';
import { DomainsHostingsDataService } from '../services/domains-hostings-data.service';

@Component({
  selector: 'app-new-domain',
  templateUrl: './new-domain.component.html',
  styleUrls: ['./new-domain.component.scss']
})
export class NewDomainComponent extends DomainDetailsComponent implements OnInit {
    public appId;
    public zones;
    public selectedZone;
    public userId;
    public email;

    constructor(
        public formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        public domainsService: DomainsHostingsDataService,
        public toastr: ToastrService,
        public spinner: NgxSpinnerService,
        public countriesService: GetCountriesService,
        public translate: TranslateService
    ) {
        super(formBuilder, activeModal, domainsService, toastr, spinner, countriesService, translate);
    }

    ngOnInit() {
        super.ngOnInit();
        this.selectedZone = '1';
        this.domain_form.setControl('zone', new FormControl('1', Validators.required));
        this.domain_form.get('zone').valueChanges.subscribe(
            data => {
                console.log(data);
                this.selectedZone = data;
            }
        )
        this.domain_form.setControl('domain_name', new FormControl('', Validators.required));
        this.domainsService.getDomainZones().subscribe(
            zones => {
                this.zones = zones;
            },
            err => console.log(err)
        );
        if (this.appId) {
            this.domainsService.getOne(this.appId, 'application').subscribe(
                appData => {
                    console.log(appData);
                    this.userId = appData.user_id[0].target_id;
                    this.email = appData.email[0].value;
                    if (appData.applicants && appData.applicants.length > 0) {
                        this.domainsService.getOne(appData.applicants[0].target_id, 'applicant')
                            .subscribe(
                                applicantData => {
                                    this.getDataFromApplicant(applicantData);
                                    console.log(applicantData);
                                },
                                err => {
                                    console.log(err);
                                }
                            )
                    }
                },
                err => {
                    console.log(err);
                }
            );
            // patch tm certificate number if exists
            this.domainsService.getAll('/admin/profit/trademark/list', { application: this.appId }).subscribe(
                data => {
                    if (data.rows && data.rows.length > 0) {
                        const tm = data.rows[0];
                        if (tm.registration_number && tm.registration_number.length > 0) {
                            this.domain_form.get('certificate_id').patchValue(tm.registration_number);
                        }
                    }
                }, err => console.log(err)
            );
        }
    }

    public getDataFromApplicant(applicantData) {
        const cleanData = UnDrupalizeObject.format(applicantData);
        this.domain_form.patchValue(cleanData);
        this.domain_form.get('contact_email').patchValue(this.email);
        this.domain_form.get('fax').patchValue(this.domain_form.get('phone').value);
    }

    public isContactData(prop) {
        return prop !== 'certificate_id' && prop !== 'free_domain' && prop !== 'zone' && prop !== 'domain_name';
    }

    public getZoneName(zone) {
        for (const z of this.zones) {
            if (z.id === zone) {
                return z.name;
            }
        }
        return '';
    }

    public save() {
        if (!this.domain_form.valid) {
            this.translate.get('DOMAINS.ENTER_NAME_AND_ZONE').subscribe((res: string) => {
                this.toastr.error(res);
            });
            return;
        }
        const formValue = this.domain_form.value;
        const domainTmId = this.domain_form.get('certificate_id').value;
        const zoneName = this.getZoneName(this.selectedZone);
        if (zoneName === 'ua' && (!domainTmId || domainTmId === '')) {
            this.translate.get('DOMAINS.NO_UA_REG_WITHOUT_TM').subscribe((res: string) => {
                this.toastr.error(res);
            });
            return;
        }
        let payload = {};
        for (const key in formValue) {
            if (formValue.hasOwnProperty(key) && this.isContactData(key)) {
                payload[key] = formValue[key];
            }
        }
        payload = DrupalizeObject.format(payload);
        payload['user_id'] = [{ target_id: this.userId }];
        console.log('contact payload');
        console.log(payload);
        // save changes in domain's contact
        this.spinner.show();
        this.domainsService.save('', payload, 'domain_contact')
            .subscribe(
                contactData => {
                    console.log(contactData);
                    const isFree = this.domain_form.get('free_domain').value;
                    const fullName = `${this.domain_form.value['domain_name']}.${zoneName}`;
                    const domainPayload = {
                        tm: [{ value: domainTmId }],
                        free_domain: [{ value: isFree }],
                        application: [{ target_id: this.appId }],
                        domain: [{ value: this.domain_form.value['domain_name'] }],
                        domain_contact: [{ target_id: contactData.id[0].value }],
                        domain_zone: [{ target_id: this.selectedZone }],
                        name: [{ value: fullName }],
                        origin: [{ value: 'profitmark_crm' }],
                        user_id: [{ target_id: this.userId }]
                    };
                    console.log('domain payload');
                    console.log(domainPayload);
                    this.domainsService.save('', domainPayload, 'domain')
                        .subscribe(
                            domainData => {
                                console.log(domainData);
                                this.spinner.hide();
                                this.activeModal.close({ savedContact: null });
                                // register domain
                                // this.register();
                            },
                            err => {
                                this.spinner.hide();
                                console.log(err);
                                this.toastr.error('Помилка при збереженні даних домену');
                                this.toastr.error(err.error ? err.error.message : '');
                            }
                        );

                    // this.activeModal.close({ savedContact: null });
                },
                err => {
                    this.spinner.hide();
                    console.log(err);
                    this.translate.get('DOMAINS.ERROR_SAVING_CONTACT').subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                    this.toastr.error(err.error ? err.error.message : '');
                }
            );
    }
}
