import { Component, OnInit } from "@angular/core";
// import { RouteInfo } from "./sidebar.metadata";
import { ActivatedRoute, Router } from "@angular/router";

import { AuthService } from "../auth/auth.service";
import { BaseService } from "../services/base.service";
import { SidebarRoutesService } from "../services/sidebar-routes.service";
import { ROUTES_DOMAINS, ROUTES_TM } from "./sidebar-routes.config";

declare var $: any;
@Component({
    // moduleId: module.id,
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public topRole = "";

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private routesService: SidebarRoutesService,
        private baseService: BaseService,
        private auth: AuthService
    ) {}

    ngOnInit() {
        $.getScript("./assets/js/app-sidebar.js");
        this.routesService.routesType.subscribe((data) => {
            if (this.topRole === "searcher") {
                this.menuItems = this.routesService.getRoutesByType("SEARCH");
            } else if (this.topRole === "worldwide_manager") {
                if (
                    data.type === "IP" ||
                    data.type == "DESIGN" ||
                    data.type == "ACCOUNTING"
                ) {
                    this.menuItems = this.routesService.getRoutesByType("TM");
                } else if (data.type === "UTILS") {
                    this.menuItems =
                        this.routesService.getRoutesByType("UTILS_MANAGERS");
                } else {
                    this.menuItems = data.routes;
                }
            } else {
                this.menuItems = data.routes;
            }
        });

        const roles = this.auth.roles;
        if (roles.length > 0) {
            if (roles.indexOf("administrator") !== -1) {
                this.topRole = "admin";
            } else if (roles.indexOf("manager") !== -1) {
                this.topRole = "manager";
            } else if (roles.indexOf("worldwide_manager") !== -1) {
                this.topRole = "worldwide_manager";
            } else if (roles.indexOf("searcher") !== -1) {
                this.topRole = "searcher";
            } else if (roles.indexOf("partner_manager") !== -1) {
                this.topRole = "partner_manager";
            }
            let routes = this.routesService.getRoutesByRoute(this.router.url);
            if (this.topRole === "searcher") {
                if (!routes) {
                    this.router.navigate(["/search-reports", "list"]);
                }
                routes = this.routesService.getRoutesByType("SEARCH");
            } else if (this.topRole === "worldwide_manager") {
                const type = this.routesService.getRoutesTypeByRoute(
                    this.router.url
                );
                if (type === "IP" || type == "DESIGN" || type == "ACCOUNTING") {
                    routes = this.routesService.getRoutesByType("TM");
                }
                if (type === "UTILS") {
                    routes =
                        this.routesService.getRoutesByType("UTILS_MANAGERS");
                }
            }
            if (!routes) {
                routes = this.routesService.getRoutesByType(
                    this.routesService.defaultRoutesType
                );
            }
            this.menuItems = routes;
        } else {
            // set initial routes according to current url
            let routes = this.routesService.getRoutesByRoute(this.router.url);
            if (!routes) {
                routes = this.routesService.getRoutesByType(
                    this.routesService.defaultRoutesType
                );
            }
            this.menuItems = routes;
        }
    }
}
