export class DrupalizeObject {
    static format(payload: any, skip = [], skipEmpty = true) {
        const drupalized_payload = {};
        const dateFields = ["submission_date"];
        for (const param in payload) {
            if (
                payload.hasOwnProperty(param) &&
                (payload[param] !== "" || !skipEmpty) &&
                param !== "id"
            ) {
                if (skip.indexOf(param) !== -1) {
                    continue;
                }
                drupalized_payload[param] = [];
                if (
                    param === "service" ||
                    param === "assign_to" ||
                    param === "seller" ||
                    param === "application" ||
                    param === "applicant" ||
                    param === "object_author" ||
                    param === "representatives" ||
                    param === "id_type"
                ) {
                    if (
                        payload[param] &&
                        payload[param].hasOwnProperty("target_id")
                    ) {
                        drupalized_payload[param].push(payload[param]);
                    } else {
                        if (Array.isArray(payload[param])) {
                            drupalized_payload[param].push(
                                ...payload[param].map((item) => {
                                    return {
                                        target_id: item,
                                    };
                                })
                            );
                        } else {
                            drupalized_payload[param].push({
                                target_id: payload[param],
                            });
                        }
                    }
                } else if (param === "file" || param === "screenshot") {
                    if (payload[param] === null) {
                        drupalized_payload[param] = null;
                    } else if (payload[param].hasOwnProperty("target_id")) {
                        drupalized_payload[param].push(payload[param]);
                    } else {
                        drupalized_payload[param].push({
                            target_id: payload[param],
                        });
                    }
                } else if (param === "type") {
                    drupalized_payload[param].push({
                        target_id: payload[param],
                    });
                } else if (
                    param === "tm_image" ||
                    param === "referrer" ||
                    param === "user_id" ||
                    param === "partner" ||
                    param === "classes_details"
                ) {
                    drupalized_payload[param] = [payload[param]];
                } else if (param === "event_type") {
                    drupalized_payload[param] = [payload[param]];
                } else if (param === "classes") {
                    if (typeof payload[param] === "string") {
                        payload[param] = payload[param]
                            .replace(/\s/g, "")
                            .split(",");
                        if (payload[param].some(isNaN)) {
                            payload[param] = [1];
                        }
                    }

                    const drupArray = [];
                    for (const item of payload[param]) {
                        drupArray.push({ value: item });
                    }
                    drupalized_payload[param] = drupArray;
                } else if (param === "same_tm") {
                    const drupArray = [];
                    for (const item of payload[param]) {
                        drupArray.push({ value: item });
                    }
                    drupalized_payload[param] = drupArray;
                } else if (param === "applicants") {
                    drupalized_payload[param] = payload[param];
                } else if (param === "report_header") {
                    drupalized_payload[param] = [
                        { value: payload[param], format: "full_html" },
                    ];
                } else if (
                    dateFields.indexOf(param) !== -1 &&
                    payload[param] !== null
                ) {
                    drupalized_payload[param].push({
                        value: payload[param] + "+00:00",
                    });
                } else if (param === "items") {
                    const drupArray = [];
                    for (const item of payload[param]) {
                        drupArray.push({
                            ...item,
                            taxable: item.taxable ? 1 : 0,
                        });
                    }
                    drupalized_payload[param] = drupArray;
                } else {
                    drupalized_payload[param].push({
                        value: payload[param],
                    });
                }
            }
        }
        return drupalized_payload;
    }
}
