import { Component, OnInit } from '@angular/core';
import { ReloadService } from '../../shared/services/reload.service';


@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent {

    constructor(public service: ReloadService) {
        // uncomment this if needed autoreload
        // service.startReloadCounter();
    }

    activated(event) {
        this.service.setActiveComponent(event);
    }
}
