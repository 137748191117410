import { ToastrService } from "ngx-toastr";

import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

import { copyToClipboard } from "../../shared/common/copy";
import { FetchInvoicesService } from "../services/fetch-invoices.service";

@Component({
    selector: "app-paylink-form",
    templateUrl: "./paylink-form.component.html",
    styleUrls: ["./paylink-form.component.scss"],
})
export class PaylinkFormComponent implements OnInit {
    paylink_form: FormGroup;

    sellers: { value: string; name: string }[] = [];

    paylink: string = "";
    currencies = [{ value: "UAH" }, { value: "USD" }, { value: "EUR" }];
    saving = false;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        public invoicesService: FetchInvoicesService,
        public toastr: ToastrService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        this.paylink_form = this.formBuilder.group({
            seller_id: ["", Validators.required],
            amount: [0, [Validators.required, Validators.min(0.01)]],
            email: ["", [Validators.required, Validators.email]],
            name: ["", Validators.required],
            description: [""],
            currency: ["EUR", Validators.required],
            return_url: ["https://profitmark.eu"],
        });

        this.invoicesService.query("/api/v1/seller/list", {}).subscribe(
            (data) => {
                if (data.rows) {
                    this.sellers = [
                        ...Object.keys(data.rows).map((key) => ({
                            value: data.rows[key].id,
                            name: data.rows[key].label,
                        })),
                        { value: "", name: "COMMON.NOT_SPECIFIED" },
                    ];
                }
            },
            (err) => {}
        );
    }

    generatePaylink() {
        this.paylink_form.markAllAsTouched();
        if (this.paylink_form.invalid) {
            return;
        }
        this.invoicesService
            .generatePayLink$(this.paylink_form.value)
            .subscribe(
                (data) => {
                    this.paylink = data;
                },
                (err) => {
                    this.toastr.error(err.error.message);
                }
            );
    }

    copyToClipboard() {
        copyToClipboard(this.paylink).then(
            () => {
                this.toastr.success(
                    this.translate.instant("COMMON.COPIED_TO_CLIPBOARD")
                );
            },
            (err) => {
                this.toastr.error("Failed to copy text to clipboard");
            }
        );
    }
}
