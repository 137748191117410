import { Routes } from '@angular/router';

// Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'changelog',
        loadChildren: () => import('../../changelog/changelog.module').then(m => m.ChangeLogModule)
    },
    {
        path: 'full-layout',
        loadChildren: () => import('../../pages/full-layout-page/full-pages.module').then(m => m.FullPagesModule)
    },
    {
        path: 'invoices',
        loadChildren: () => import('../../invoices/invoices.module').then(m => m.InvoicesModule)
    },
    {
        path: 'documents',
        loadChildren: () => import('../../documents/documents.module').then(m => m.DocumentsModule)
    },
    {
        path: 'control',
        loadChildren: () => import('../../control/control.module').then(m => m.ControlModule)
    },
    {
        path: 'emailclient',
        loadChildren: () => import('../../mail/mail.module').then(m => m.MailModule)
    },
    {
        path: 'trademarks',
        loadChildren: () => import('../../trademarks/trademarks.module').then(m => m.TrademarksModule)
    },
    {
        path: 'potential-clients',
        loadChildren: () => import('../../potential-clients/potential-clients.module').then(m => m.PotentialClientsModule)
    },
    {
        path: 'domains-hostings',
        loadChildren: () => import('../../domains-hostings/domains-hostings.module').then(m => m.DomainsHostingsModule)
    },
    {
        path: 'intellect-property',
        loadChildren: () => import('../../intellectual-property/intellectual-property.module').then(m => m.IntellectualPropertyModule)
    },
    {
        path: 'design',
        loadChildren: () => import('../../design-tm/design-tm.module').then(m => m.DesignTmModule)
    },
    {
        path: 'discounts',
        loadChildren: () => import('../../discounts/discounts.module').then(m => m.DiscountsModule)
    },
    {
        path: 'tm-sale',
        loadChildren: () => import('../../tm-for-sale/tm-for-sale.module').then(m => m.TmForSaleModule)
    },
    // {
    //     path: 'tm-watch',
    //     loadChildren: './tm-watch/tm-watch.module#TmWatchModule'
    // },
    {
        path: 'monitoring',
        loadChildren: () => import('../../monitoring/monitoring.module').then(m => m.MonitoringModule)
    },
    {
        path: 'reviews',
        loadChildren: () => import('../../reviews/reviews.module').then(m => m.ReviewsModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('../../settings/settings.module').then(m => m.SettingsModule)
    },
    {
        path: 'users',
        loadChildren: () => import('../../users/users.module').then(m => m.UsersModule)
    },
    {
        path: 'accounting',
        loadChildren: () => import('../../accounting/accounting.module').then(m => m.AccountingModule)
    },
    {
        path: 'profitmark-pro',
        loadChildren: () => import('../../profitmark-pro/profitmark-pro.module').then(m => m.ProfitmarkProModule)
    },
    {
        path: 'objections',
        loadChildren: () => import('../../objections/objections.module').then(m => m.ObjectionsModule)
    },
    {
        path: 'transfer-rights',
        loadChildren: () => import('../../transfer-rights/transfer-rights.module').then(m => m.TransferRightsModule)
    },
    {
        path: 'search-reports',
        loadChildren: () => import('../../search-reports/search-reports.module').then(m => m.SearchReportsModule)
    },
    {
        path: 'templates',
        loadChildren: () => import('../../templates/templates.module').then(m => m.TemplatesModule)
    },
    {
        path: 'partners',
        loadChildren: () => import('../../partners/partners.module').then(m => m.PartnersModule)
    },
    {
        path: 'widget-partners',
        loadChildren: () => import('../../widget-partners/widget-partners.module').then(m => m.WidgetPartnersModule)
    },
    {
        path: 'sellers',
        loadChildren: () => import('../../sellers/sellers.module').then(m => m.SellersModule)
    }
];
