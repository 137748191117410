export interface IRequisites {
    address?: string;
    company_code?: string;
    account?: string;
    bank?: string;
    swift?: string;
    title?: string;
    full_name?: string;
}

export class Requisites {
    address?: string;
    company_code?: string;
    account?: string;
    bank?: string;
    swift?: string;
    title?: string;
    full_name?: string;

    constructor(requisites?: IRequisites) {
        Object.assign(this, requisites);
    }
}
