import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, merge, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '../../shared/auth/auth.service';
import { BaseService } from '../../shared/services/base.service';
import { UserCabinetComponent } from '../../shared/user-cabinet/user-cabinet.component';
import { GetTmDataService } from '../../trademarks/services/get-tm-data.service';
import { DomainContactEditComponent } from '../domain-contact-edit/domain-contact-edit.component';
import { DomainDetailsEditComponent } from '../domain-details-edit/domain-details-edit.component';
import { DomainDetailsComponent } from '../domain-details/domain-details.component';
import { EditDnsComponent } from '../edit-dns/edit-dns.component';
import { Domain } from '../models/Domain';
import { NewDomainComponent } from '../new-domain/new-domain.component';
import { DomainsHostingsDataService } from '../services/domains-hostings-data.service';
import { SetNsComponent } from '../set-ns/set-ns.component';

@Component({
  selector: 'app-domains-list',
  templateUrl: './domains-list.component.html',
  styleUrls: ['./domains-list.component.scss']
})
export class DomainsListComponent implements OnInit {
    @ViewChild('nameTmpl', {static: true}) nameTmpl: TemplateRef<any>;
    @ViewChild('statusTmpl', {static: true}) statusTmpl: TemplateRef<any>;
    @ViewChild('contactTmpl', {static: true}) contactTmpl: TemplateRef<any>;
    @ViewChild('registerTmpl', {static: true}) registerTmpl: TemplateRef<any>;
    @ViewChild('datesTmpl', {static: true}) datesTmpl: TemplateRef<any>;
    @ViewChild('userTmpl', {static: true}) userTmpl: TemplateRef<any>;
    @ViewChild('maskTmpl', {static: true}) maskTmpl: TemplateRef<any>;
    @ViewChild('headerTmpl', {static: true}) headerTmpl: TemplateRef<any>;
    
    @Input() id;
    @Input()
    userId = null;
    public domainsPath = '/admin/profit/domain/list/';
    public domains = [];
    public columns: Array<TableColumn>;
    public registeredTypes;
    public freeTypes;
    public sortTypes;

    public filter_form: FormGroup;

    public page = '0';

    // public userId;
    public savedUserFilter;

    public searchingUsers = false;
    public searchFailedUsers = false;
    public hideSearchingWhenUnsubscribedUsers = new Observable(() => () => this.searchingUsers = false);

    public payedTypes = [
        {label: 'COMMON.PAID_AND_UNPAID', value: 'all'},
        {label: 'COMMON.PAID', value: '1'},
        {label: 'COMMON_UNPAID', value: '0'},
    ];
    public searchUsers;
    
    constructor(
        public domainsService: DomainsHostingsDataService,
        public formBuilder: FormBuilder,
        public route: ActivatedRoute,
        public router: Router,
        public toastr: ToastrService,
        public tmDataService: GetTmDataService,
        public spinner: NgxSpinnerService,
        public modalService: NgbModal,
        public baseService: BaseService,
        public auth: AuthService,
        public translate: TranslateService
    ) {
        this.filter_form = this.formBuilder.group({
            registered_type: ['all', Validators.required],
            name: [''],
            free_type: ['all'],
            sort_by: ['created'],
            sort_order: ['DESC'],
            user: [''],
            payed_invoice: ['all']
        });
        const filters = this.domainsService.retrieveFilters(this.constructor.name);
        if (filters) {
            this.filter_form.patchValue(filters, {emitEvent: false});
            if (filters.user && filters.user !== '') {
                this.domainsService.getUser(filters.user).subscribe(
                    data => {
                        if (data) {
                            this.savedUserFilter = data.field_forename[0].value + ' ' + data.field_surname[0].value;
                        }
                    },
                    err => console.log(err)
                )
            }
        }
        this.searchUsers = (text$: Observable<string>) =>
            text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.searchingUsers = true),
            switchMap(term =>
                this.tmDataService.getUsers(term).pipe(
                    tap(() => this.searchFailedUsers = false),
                    map((result) => {
                        for (const user of result as any[]) {
                            if (user['field_surname'] || user['field_forename']) {
                                user['full_name'] =
                                user['field_surname'] + ' ' + user['field_forename'];
                            } else {
                                user['full_name'] = user['name'];
                            }
                        }
                        return result;
                    }),
                    catchError(() => {
                        this.searchFailedUsers = true;
                        return of([]);
                    }))
                ),
                tap(() => this.searchingUsers = false),
                merge(this.hideSearchingWhenUnsubscribedUsers)
            );
    
    }

    ngOnInit() {
        this.domainsService.model = 'Domain';
        this.domainsService['Domain'] = Domain;
        this.domainsService.items.subscribe(
            data => {
                this.domains = data;
                for (const domain of this.domains) {
                    domain['last_clicked'] = 0;
                }
            },
            err => {
                console.log(err);
            }
        )

        this.route.queryParams.subscribe(params => {
            this.page = params['page'];
            this.loadData(this.page);
        });

        this.registeredTypes = [
            { label: 'DOMAINS.ALL_DOMAINS', value: 'all' },
            { label: 'DOMAINS.REGISTERED', value: 'registered' },
            { label: 'DOMAINS.NOT_REGISTERED', value: 'not_registered' }
        ];

        this.freeTypes = [
            { label: 'DOMAINS.PAID_FREE', value: 'all' },
            { label: 'DOMAINS.FREE_DOMAINS', value: 'free' },
            { label: 'DOMAINS.PAID_DOMAINS', value: 'not_free' }
        ];

        this.sortTypes = [
            { label: 'DOMAINS.CREATION_DATE', value: 'created' },
            { label: 'DOMAINS.REGISTRATION_DATE', value: 'registered' }
        ];

        this.columns = [
            { headerTemplate: this.headerTmpl, name: 'COMMON.NAME', prop: 'name', minWidth: 100, cellTemplate: this.nameTmpl, sortable: false },
            {
                headerTemplate: this.headerTmpl, name: 'DOMAINS.REGISTERES_ACTIVE', prop: 'registered', minWidth: 200,
                maxWidth: 210, cellTemplate: this.datesTmpl, sortable: false
            },
            { headerTemplate: this.headerTmpl, name: '', prop: 'user_name', cellTemplate: this.userTmpl, maxWidth: 40, sortable: false },
            { headerTemplate: this.headerTmpl, name: '', cellTemplate: this.maskTmpl, maxWidth: 40, sortable: false },
            { headerTemplate: this.headerTmpl, name: 'COMMON.CONTACT', prop: 'contact_full_name', cellTemplate: this.contactTmpl, sortable: false },
            { headerTemplate: this.headerTmpl, name: 'DOMAINS.FREE', prop: 'free_domain', cellTemplate: this.statusTmpl, maxWidth: 110, sortable: false },
            { headerTemplate: this.headerTmpl, name: '', cellTemplate: this.registerTmpl, maxWidth: 170, sortable: false, cellClass: 'dropmenu' }
        ];

        this.filter_form.valueChanges
            .pipe(
                debounceTime(400),
                distinctUntilChanged()
            )
            .subscribe(
                result => {
                    this.loadData('0');
                },
                err => {
                    console.log(err);
                }
            );
    }

    public loadData(page = '0', path = this.domainsPath) {
        // if domains list is embedded in application and has app id - ignore filters
        if (this.id && this.userId) {
            this.domainsService.fetch(page, 'all', {userId: this.userId}, path);
        } else if (this.id) {
            this.domainsService.fetch('0', this.id, {}, path);
        } else if (this.userId) {
            this.domainsService.fetch(page, 'all', {userId: this.userId}, path);
        } else {
            this.domainsService.saveFilters(this.filter_form.value, this.constructor.name);
            const regType = this.filter_form.get('registered_type').value;
            const payload = {
                registered_type: this.filter_form.value['registered_type'],
                name: this.filter_form.value['name'],
                sort_by: this.filter_form.value['sort_by'],
                sort_order: this.filter_form.value['sort_order']
            }
            if (this.filter_form.value['free_type'] === 'free') {
                payload['free_domain'] = '1';
            } else if (this.filter_form.value['free_type'] === 'not_free') {
                payload['free_domain'] = '0';
            }
            if (this.filter_form.value['user'] && this.filter_form.value['user'] !== '') {
                payload['userId'] = this.filter_form.value['user'];
            }
            // if (this.filter_form.value['free_domain']) {
            //     payload['free_domain'] = '1';
            // }

            if (this.filter_form.value['payed_invoice'] !== 'all') {
                payload['payed_invoice'] = this.filter_form.value['payed_invoice'];
            }

            if (regType !== 'all') {
                path = `/admin/profit/domain/${regType}/list/`;
                this.domainsService.fetch(page, '', payload, path);
            } else {
                this.domainsService.fetch(page, 'all', payload, path);
            }
        }
    }

    public onPageChanged(event) {
        if (this.userId) {
            this.page = event.offset;
            this.loadData(this.page);
            return;
        }
        console.log(event);
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                page: event.offset
            },
            queryParamsHandling: 'merge',
            skipLocationChange: false
        });
    }

    public resetFilters() {
        this.filter_form.patchValue({
            registered_type: 'all',
            name: '',
            free_type: 'all',
            sort_by: 'created',
            sort_order: 'DESC',
            user: ''
            // free_domain: false
        }, { emitEvent: false });
        this.savedUserFilter = null;
        this.loadData('0');
        this.domainsService.removeFilters(this.constructor.name);
    }

    public register(row) {
        console.log('register domain ' + row.name + ' ' + row.id);
        const modalRef = this.modalService.open(DomainDetailsComponent, {size: 'lg'});
        modalRef.componentInstance.domainId = row.id;
        modalRef.result.then(result => {
            console.log(result);
            this.loadData(this.page);
        }, reason => { console.log(reason) });

        // separate registration logic for ua and non-ua domains

        // if (row.domain_zone === 'ua') {
        //     console.log('ua');
        //     const modalRef = this.modalService.open(DomainDetailsComponent, {size: 'lg'});
        //     modalRef.componentInstance.domainId = row.id;

        //     modalRef.result.then(result => {
        //         console.log(result);
        //         this.loadData(this.page);
        //     }, reason => { console.log(reason) });
        // }
        // else {
        // this.spinner.show();
        // this.domainsService.operation(row.id, 'registerDomain', 'domain')
        //     .subscribe(
        //         data => {
        //             this.spinner.hide();
        //             console.log(data);
        //             this.toastr.success('Домен ' + this.domain.name + ' зареєстровано');
        //             this.loadData(this.page);
        //         },
        //         err => {
        //             this.spinner.hide();
        //             this.toastr.error('Не вдалося зареєструвати домен');
        //             this.toastr.error(err);
        //             console.log(err);
        //         }
        //     );
        // }
    }

    public proposeDomain() {
        if (this.id) {
            this.domainsService.operation(this.id, 'suggestDomain', 'application').subscribe(
                data => {
                    console.log(data);
                    this.translate.get('DOMAINS.DOMAIN_OFFERED').subscribe((res: string) => {
                        this.toastr.success(res);
                    });
                },
                err => {
                    this.translate.get('ALERTS.ERROR').subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                    this.toastr.error(err.error ? err.error.message : '');
                    console.log(err);
                }
            );
        }
    }

    public newDomain() {
        if (this.id) {
            const modalRef = this.modalService.open(NewDomainComponent, {size: 'lg'});
            modalRef.componentInstance.appId = this.id;

            modalRef.result.then(result => {
                console.log(result);
                this.loadData(this.page);
            }, reason => { console.log(reason) });
        }
    }

    public isDoubleClick(prev, current) {
        return current - prev < 500;
    }

    public editContact(event) {
        // console.log(event);
        if (event.type === 'click' && event.column.prop === 'contact_full_name') {
            const now = new Date().getTime();
            if (event.row['last_clicked'] === 0) {
                event.row['last_clicked'] = now;
            } else if (this.isDoubleClick(event.row['last_clicked'], now)) {
                event.row['last_clicked'] = 0;
                event.cellElement.blur();
                this.openContactEditor(event.row);
            } else {
                event.row['last_clicked'] = now;
            }
        }
    }

    public viewDomain(row) {
        const modalRef = this.modalService.open(DomainDetailsEditComponent, {size: 'lg'});
        modalRef.componentInstance.domainId = row.id;
        modalRef.result.then(result => {
            console.log(result);
            this.loadData(this.page);
        }, reason => { console.log(reason) });
    }

    public openContactEditor(domain: Domain) {
        const modalRef = this.modalService.open(DomainContactEditComponent, {size: 'lg'});
        modalRef.componentInstance.contactId = domain.domain_contact_id;

        modalRef.result.then(result => {
            this.loadData(this.page);
        }, reason => { console.log(reason) });
    }

    // USER CHANGE

    public userSelected(event) {
        console.log(event);
        if (event.item && event.item.uid) {
            this.filter_form.get('user').patchValue(event.item.uid);
        }
    }

    usersFormatter = (user) => {
        let name;
        if (user['full_name']) {
            name = user['full_name'];
        } else {
            name = user['name'];
        }
        return (name + '').replace(/&#\d+;/gm, function (s) {
            return String.fromCharCode(Number(s.match(/\d+/gm)[0]));
        }) + ' ' + user['mail'];
    }

    public mask(row) {
        if (this.baseService.isMobile) {
            setTimeout(() => {
                this.auth.getClientJWT(row.user_id).subscribe(
                    data => {
                        console.log('got client jwt');
                        console.log(data.jwt);
                        window.open('https://profitmark.ua/masquerade-widget/' + 
                        row.user_id + "/" + data.jwt, '_blank');
                    },
                    err => {
                        console.log('error getting client jwt');
                        console.log(err);
                    }
                )
            }, 100);
        } else {
            const modalRef = this.modalService.open(UserCabinetComponent, { windowClass: 'cabinetModal' });
            modalRef.componentInstance.userId = row.user_id;
            modalRef.result.then(
                result => {
                    console.log(result);
                },
                reason => {
                    console.log(reason);
                }
            );
        }
    }

    public setNs(row) {
        const modalRef = this.modalService.open(SetNsComponent, { size: 'lg' });
        modalRef.componentInstance.domainName = row.name;
        modalRef.result.then(
            result => {
                console.log(result);
            },
            reason => {
                console.log(reason);
            }
        );
    }

    public editDns(row) {
        const modalRef = this.modalService.open(EditDnsComponent, { windowClass: 'cabinetModal' });
        modalRef.componentInstance.domainName = row.name;
        modalRef.result.then(
            result => {
                console.log(result);
            },
            reason => {
                console.log(reason);
            }
        );
    }

    public createInvoice(domain) {
        this.baseService.operation(domain.id, 'createInvoice', 'domain', { period: 1 }).subscribe(
            data => {
                this.translate.get('ALERTS.INVOICE_CREATED').subscribe((res: string) => {
                    this.toastr.success(res);
                });
            }, err => {
                this.translate.get('ALERTS.ERROR_CREATING_INVOICE').subscribe((res: string) => {
                    this.toastr.error(res + '\n' + err.error ? err.error.message : err);
                });
            }
        );
    }
}
