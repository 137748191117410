import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GetDocumentsService } from 'app/documents/services/get-documents.service';
import { FetchInvoicesService } from 'app/invoices/services/fetch-invoices.service';
import { DrupalizeObject } from 'app/shared/common/drupalize-object';
import { UnDrupalizeObject } from 'app/shared/common/undrupalize-object';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { AccountingPaymentsService } from '../../../accounting/services/accounting-payments.service';
import { NgbDateDrupalAdapter } from '../../common/ngb-date-drupal-adapter';

@Component({
  selector: 'app-payment-request-editor',
  templateUrl: './payment-request-editor.component.html',
  styleUrls: ['./payment-request-editor.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateDrupalAdapter }]
})
export class PaymentRequestEditorComponent implements OnInit {
  @Input() payment;
  @Input() paymentId;
  public payment_form: FormGroup;
  public title = 'ACCOUNTING.PAYMENT_CREATION';
  
  public service$: Observable<any[]>;
  public serviceLoading = false;
  public serviceinput$ = new Subject<string>();

  public doc_types = [{ label: 'All', value: 'All' }];
  public serviceTypes = [];
  public receipt_file: any;
  public invoice_file: any;

  public base_items = [];
  public invoice_base_items = [];
  public reminderType;

  constructor(
      public activeModal: NgbActiveModal,
      private formBuilder: FormBuilder,
      public invoicesService: FetchInvoicesService,
      public toastr: ToastrService,
      public paymentService: AccountingPaymentsService,
      public docsService: GetDocumentsService,
      public spinner: NgxSpinnerService,
      public translate: TranslateService
  ) {
      const today = new Date();
      this.payment_form = this.formBuilder.group({
          id: [''],
          description: [''],
          service:  this.formBuilder.group({
            target_id: [''],
            target_type: ['application']
          }),
          document_type: ['All'],
          invoice_file: [''],
          receipt_file: [''],
          amount: [''],
          pay_due_date: [today.toISOString().substring(0, 10)],
          invoice_number: ['']
      });
  }

  ngOnInit() {
    this.invoicesService.getServiceTypes().subscribe(
      data => this.serviceTypes = data,
      err => console.log(err)
    );
    
    this.paymentService.getTypes('document_type').subscribe(m => (this.doc_types = m), e => console.log(e));

    if (this.paymentId) {
      this.paymentService.getOne(this.paymentId, 'profit_payment_request').subscribe(
        data => {
          this.payment = UnDrupalizeObject.format(data);
          this.title = 'ACCOUNTING.PAYMENT_EDITING';
          this.payment_form.patchValue(this.payment);
          if (this.payment.document_type && this.payment.document_type.target_id) {
            this.payment_form.get('document_type').patchValue(this.payment.document_type.target_id);
          }
          if (this.payment.description.indexOf('Номер рахунку: ') !== -1) {
            const descriptionParts = this.payment.description.split('Номер рахунку: ');
            this.payment_form.get('description').patchValue(descriptionParts[0].slice(0,  descriptionParts[0].length - 1));
            if (descriptionParts[1] && descriptionParts[1].length > 0) {
              this.payment_form.get('invoice_number').patchValue(descriptionParts[1]);
            }
          }
          if (this.payment.service && this.payment.service.target_id) {
            this.paymentService.getOne(this.payment.service.target_id, this.payment.service.target_type).subscribe(
              data => {
                this.base_items.push(UnDrupalizeObject.format(data));
                console.log(this.base_items);
                this.setupServicesObservable();
              }, err => {
                this.setupServicesObservable();
              }
            )
          } else {
            this.setupServicesObservable();
          }
        },
        err => {
          this.setupServicesObservable();
        }
      )
    } else if (this.payment) {
      this.payment_form.patchValue(this.payment);
      if (this.payment.description.indexOf('Номер рахунку: ') !== -1) {
        const descriptionParts = this.payment.description.split('Номер рахунку: ');
        this.payment_form.get('description').patchValue(descriptionParts[0].slice(0,  descriptionParts[0].length - 1));
        if (descriptionParts[1] && descriptionParts[1].length > 0) {
          this.payment_form.get('invoice_number').patchValue(descriptionParts[1]);
        }
      }
      if (this.payment.service && this.payment.service.target_id) {
        this.paymentService.getOne(this.payment.service.target_id, this.payment.service.target_type).subscribe(
          data => {
            const parsed = UnDrupalizeObject.format(data);
            this.base_items.push(parsed);
            console.log(this.base_items);
            this.setupServicesObservable();
          }, err => {
            this.setupServicesObservable();
          }
        );
      } else {
        this.setupServicesObservable();
      }
    } else {
      this.setupServicesObservable();
    }
  }

  setupServicesObservable() {
    this.service$ = concat(
      of(this.base_items), // default items
      this.serviceinput$.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          tap(() => (this.serviceLoading = true)),
          switchMap(term => {
              const args = this.payment_form.value.service.target_type === 'application' ? 
              [] : ['', this.payment_form.value.service.target_type];
              return this.paymentService.getServices({contain: term}, ...args).pipe(
                  catchError(() => of([])), // empty list on error
                  tap(() => (this.serviceLoading = false))
              )
            }
          )
      )
    );
  }

  savePayment() {
      if (!this.payment_form.valid) {
          this.translate.get('ALERTS.FILL_ALL_FIELDS').subscribe((res: string) => {
              this.toastr.error(res);
          });
          return;
      }
      const form_data = {...this.payment_form.value};
      if (form_data.document_type === 's_inp') {
        form_data.description += '\nНомер рахунку: ' + form_data.invoice_number;
      }
      delete form_data.invoice_number;
      this.spinner.show();

      const payload = DrupalizeObject.format(form_data);
      if (form_data.document_type && form_data.document_type !== 'All') {
        payload['document_type'] = [{target_id: form_data.document_type}];
      }
      if (form_data.invoice_file && form_data.invoice_file.target_id) {
        payload['invoice_file'] = [{target_id: form_data.invoice_file.target_id}];
      }
      if (form_data.receipt_file && form_data.receipt_file.target_id) {
        payload['receipt_file'] = [{target_id: form_data.receipt_file.target_id}];
      }

      if (this.invoice_file || this.receipt_file) {
        const files = [];
        if (this.invoice_file) {
          files.push(this.invoice_file);
        }
        if (this.receipt_file) {
          files.push(this.receipt_file);
        }
        this.paymentService.uploadFileArray(files).subscribe(
          res => {
            if (this.invoice_file && this.receipt_file) {
              payload['invoice_file'] = [{target_id: res.files[0]['file_id']}];
              payload['receipt_file'] = [{target_id: res.files[1]['file_id']}];
            } else if (this.invoice_file) {
              payload['invoice_file'] = [{target_id: res.files[0]['file_id']}];
            } else {
              payload['receipt_file'] = [{target_id: res.files[0]['file_id']}];
            }
            this.paymentService.save(this.payment_form.value.id, payload, 'profit_payment_request').subscribe(
              data => {
                this.createSecondPayment(payload);
                this.translate.get('ACCOUNTING.PAYMENT_SAVED').subscribe((res: string) => {
                    this.toastr.success(res);
                });
                this.spinner.hide();
                this.activeModal.close();
              },
              e => {
                this.translate.get('ACCOUNTING.ERROR_SAVING_PAYMENT').subscribe((res: string) => {
                    this.toastr.error(res);
                });
                this.toastr.error(e.error ? e.error.message : '');
                this.spinner.hide();
              }
            );
          }
        )
      } else {
        this.paymentService.save(this.payment_form.value.id, payload, 'profit_payment_request').subscribe(
          data => {
            this.createSecondPayment(payload);
            this.translate.get('ACCOUNTING.PAYMENT_SAVED').subscribe((res: string) => {
                this.toastr.success(res);
            });
            this.spinner.hide();
            this.activeModal.close();
          },
          e => {
            this.translate.get('ACCOUNTING.ERROR_SAVING_PAYMENT').subscribe((res: string) => {
                this.toastr.error(res);
            });
            this.toastr.error(e.error ? e.error.message : '');
            this.spinner.hide();
          }
        );
      }
    }

  setReceiptFile(files) {
    this.receipt_file = files.item(0);
  }

  setInvoiceFile(files) {
    this.invoice_file = files.item(0);
  }

  createSecondPayment(payload) {
    if (!this.payment_form.value.id && this.reminderType && this.reminderType === '_f_31_zbir_za_publikaciyu_ta_mito_za_vidachu') {
      payload['document_type'] = [{target_id: 'pbltc'}];
      this.paymentService.save('', payload, 'profit_payment_request').subscribe(
        data => {},
        err => {}
      );
    }
  }

}
