import { UnDrupalizeObject } from "../../shared/common/undrupalize-object";

export class ApplicantRawData {
    public id;
    public uuid;
    public langcode;
    public user_id: UserId[];
    public name;
    public country;
    public person;
    public organization;
    public company_code;
    public surname;
    public forename;
    public state;
    public city;
    public postal_code;
    public street_type;
    public street;
    public house_num;
    public flat_type;
    public flat;
    public fax;
    public phone;
    public contact_email;
    public status;
    public ib_id;
    public created;
    public changed;
    public metatag;
}

export class Applicant {
    static prettyValues = {
        individual: "Фізична особа",
        organization: "Юридична особа",
        true: "Так",
        false: "Ні",
        street: "вул.",
        boulevard: "бульвар",
        lane: "провул.",
        avenue: "пр-т",
        square: "площа",
        apt: "кв.",
        office: "оф.",
        city: "м.",
        village: "с.",
        hamlet: "селище",
        urban_village: "смт",
    };

    public id;
    public uuid;
    public langcode;
    public user_id: UserId[];
    public name;
    public country;
    public person;
    public organization;
    public company_code;
    public surname;
    public forename;
    public middlename;
    public state;
    public city;
    public settlement_type;
    public postal_code;
    public street_type;
    public street;
    public house_num;
    public flat_type;
    public flat;
    public fax;
    public phone;
    public contact_email;
    public status;
    public ib_id;
    public created;
    public changed;
    public metatag;
    public inn;
    public address?;
    public tax_id?;
    id_type?;
    id_number?;
    id_issue_date?;
    id_issuer?;
    birth_date?;
    nationality?;
    marital_status?;
    profession?;

    static getPrettyValue(uglyValue) {
        if (uglyValue === undefined) {
            return uglyValue;
        }
        if (this.prettyValues.hasOwnProperty(uglyValue)) {
            return this.prettyValues[uglyValue];
        }
        return uglyValue;
    }

    static getProp(prop: any[]) {
        if (prop) {
            return prop;
        }
        return "";
    }

    static getAddresString(applicant: Applicant) {
        let address =
            "" +
            this.getPrettyValue(this.getProp(applicant.street_type)) +
            " " +
            this.getProp(applicant.street) +
            ", " +
            this.getProp(applicant.house_num);
        if (this.getProp(applicant.flat) !== "") {
            address +=
                ", " +
                this.getPrettyValue(this.getProp(applicant.flat_type)) +
                " " +
                this.getProp(applicant.flat);
        }
        address += ", ";
        if (
            this.getPrettyValue(this.getProp(applicant.settlement_type)) !== ""
        ) {
            address +=
                this.getPrettyValue(this.getProp(applicant.settlement_type)) +
                " ";
        }
        address += this.getProp(applicant.city);
        if (this.getProp(applicant.state) !== "") {
            address += ", " + this.getProp(applicant.state) + " обл.";
        }
        address += ", " + this.getProp(applicant.postal_code);
        return address;
    }

    public static applicantFromDrupal(data: any) {
        const applicant = UnDrupalizeObject.fillObject(
            data,
            new Applicant({})
        ) as Applicant;
        if (
            applicant.person === "legal_entity" ||
            applicant.person === "legal_entity_vat"
        ) {
            applicant.person = "legal";
        }
        return applicant;
    }

    public static getFromLostData(lostData, applicant = new Applicant()) {
        if (lostData.person) {
            applicant.person = lostData.person;
        } else if (lostData.applicant_status) {
            applicant.person = lostData.applicant_status;
        }

        applicant.city = lostData.city;

        if (lostData.settlement_type) {
            applicant.settlement_type = lostData.settlement_type;
        } else if (lostData.field_flat_type) {
            applicant.flat_type = lostData.field_flat_type;
        }

        if (lostData.flat_type) {
            applicant.flat_type = lostData.flat_type;
        } else if (lostData.field_flat_type) {
            applicant.flat_type = lostData.field_flat_type;
        }

        if (lostData.phone) {
            applicant.phone = lostData.phone;
        } else if (lostData.field_phone) {
            applicant.phone = lostData.field_phone;
        }

        if (lostData.flat) {
            applicant.flat = lostData.flat;
        } else if (lostData.flat_num) {
            applicant.flat = lostData.flat_num;
        }

        applicant.house_num = lostData.house_num;

        if (lostData.postal_code) {
            applicant.postal_code = lostData.postal_code;
        } else if (lostData.index) {
            applicant.postal_code = lostData.index;
        }

        applicant.street = lostData.street;
        applicant.street_type = lostData.street_type;
        applicant.surname = lostData.surname;
        if (lostData.forename) {
            applicant.forename = lostData.forename;
        } else if (lostData.applicant_name) {
            applicant.forename = lostData.applicant_name;
        }
        if (lostData.middlename) {
            applicant.middlename = lostData.middlename;
        } else if (lostData.applicant_patronymic) {
            applicant.middlename = lostData.applicant_patronymic;
        }

        if (lostData.state) {
            applicant.state = lostData.state;
        } else if (lostData.region) {
            applicant.state = lostData.region;
        }

        if (lostData.company_wrapper) {
            applicant.organization = lostData.company_wrapper.company_name;
            applicant.company_code = lostData.company_wrapper.edrpou;
            applicant.inn = lostData.company_wrapper.field_inn;
        }
        if (lostData.organization) {
            applicant.organization = lostData.organization;
        }
        if (lostData.company_code) {
            applicant.company_code = lostData.company_code;
        }
        if (!lostData.country) {
            applicant.country = "UA";
        } else {
            applicant.country = lostData.country;
        }
        if (lostData.contact_email) {
            applicant.contact_email = lostData.contact_email;
        }
        return applicant;
    }

    constructor(data?) {
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key) && data[key]) {
                    this[key] = data[key];
                }
            }
            this.address = Applicant.getAddresString(this);
        }
    }
}

class UserId {
    target_id: number;
    target_type: string;
    target_uuid: string;
    url: string;

    constructor(data: any) {
        this.target_id = data.target_id;
        this.target_type = data.target_type;
        this.target_uuid = data.target_uuid;
        this.url = data.url;
    }
}
