import { Injectable } from '@angular/core';
import { BaseService } from 'app/shared/services/base.service';

interface Pager {
  current_page: number;
  total_items: number;
  total_pages: number;
  items_per_page: number;
}

@Injectable({
  providedIn: 'root'
})

export class AccountingPaymentsService extends BaseService {
  static ngInjectableDef = undefined;

  public prepereRows(res) {
    const out = [];
    for (const item of res.rows) {
      let newItem = item;
      if (item.service_json) {
        try {
          const parsed = JSON.parse(item.service_json);
          newItem = {...newItem, service_json: parsed};
        } catch(err) {
          console.log('Error parsing service_json');
        }
      }
      if (item.payment_data && typeof item.payment_data === 'string') {
        try {
          const parsed = JSON.parse(item.payment_data);
          newItem = {...newItem, payment_data: parsed};
        } catch(err) {
          console.log('Error parsing payment_data');
        }
      }
      out.push(newItem);
    }
    res.rows = out;
    return res;
  }
}
