import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { TableColumn } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Hosting } from '../models/Hosting';
import { DomainsHostingsDataService } from '../services/domains-hostings-data.service';

@Component({
  selector: 'app-hostings-list',
  templateUrl: './hostings-list.component.html',
  styleUrls: ['./hostings-list.component.scss']
})
export class HostingsListComponent implements OnInit {
    @ViewChild('statusTmpl', {static: true}) statusTmpl: TemplateRef<any>;
    @ViewChild('registerTmpl', {static: true}) registerTmpl: TemplateRef<any>;
    @ViewChild('contactTmpl', {static: true}) contactTmpl: TemplateRef<any>;
    @ViewChild('datesTmpl', {static: true}) datesTmpl: TemplateRef<any>;
    @ViewChild('headerTmpl', {static: true}) headerTmpl: TemplateRef<any>;

    public hostingsPath = '/admin/profit/hosting/list/';
    public hostings = [];
    public columns: Array<TableColumn>;
    public registeredTypes;

    public filter_form: FormGroup;

    public page = '0';

    constructor(public hostingsService: DomainsHostingsDataService,
        public formBuilder: FormBuilder,
        public route: ActivatedRoute,
        public toastr: ToastrService,
        public spinner: NgxSpinnerService,
        public router: Router) {
            this.filter_form = this.formBuilder.group({
                registered_type: ['all', Validators.required],
                name: [''],
                contact: ['']
            });

            const filters = this.hostingsService.retrieveFilters(this.constructor.name);
            if (filters) {
                this.filter_form.patchValue(filters, {emitEvent: false});
            }
        }

    ngOnInit() {
        this.hostingsService.model = 'Hosting';
        this.hostingsService['Hosting'] = Hosting;
        this.hostingsService.items.subscribe(
            data => {
                this.hostings = data;
            },
            err => {
                console.log(err);
            }
        )

        this.route.queryParams.subscribe(params => {
            this.page = params['page'];
            this.loadData(this.page);
        });

        this.registeredTypes = [
            { label: 'DOMAINS.ALL_HOSTINGS', value: 'all' },
            { label: 'DOMAINS.REGISTERED_HOSTINGS', value: 'registered' },
            { label: 'DOMAINS.NOT_REGISTERED_HOSTINGS', value: 'not_registered' }
        ];

        this.columns = [
            { headerTemplate: this.headerTmpl, name: 'COMMON.NAME', prop: 'name', maxWidth: 80 },
            { headerTemplate: this.headerTmpl, name: 'DOMAINS.PACKAGE', prop: 'hosting_package', maxWidth: 80 },
            // { name: 'Зареєстрован', prop: 'registered' },
            // { name: 'Діє до', prop: 'extended' },
            { headerTemplate: this.headerTmpl, name: 'DOMAINS.REGISTERES_ACTIVE', prop: 'registered', minWidth: 200, maxWidth: 210, cellTemplate: this.datesTmpl},
            { headerTemplate: this.headerTmpl, name: 'COMMON.OWNER', prop: 'user_name' },
            { headerTemplate: this.headerTmpl, name: 'COMMON.CONTACT', prop: 'contact_full_name', cellTemplate: this.contactTmpl, minWidth: 180},
            { headerTemplate: this.headerTmpl, name: '', cellTemplate: this.registerTmpl, maxWidth: 150 }
        ];

        this.filter_form.valueChanges
            .pipe(
                debounceTime(400),
                distinctUntilChanged()
            )
            .subscribe(
                result => {
                    this.onFiltersChange();
                },
                err => {
                    console.log(err);
                }
            );
    }

    public loadData(page = '0', path = this.hostingsPath) {
        this.hostingsService.saveFilters(this.filter_form.value, this.constructor.name);
        const regType = this.filter_form.get('registered_type').value;
        if (regType !== 'all') {
            path = `/admin/profit/hosting/${regType}/list/`;
        }
        this.hostingsService.fetch(page, '', this.filter_form.value, path);
    }

    public onFiltersChange() {
        console.log('on filters changed');
        this.loadData('0');
    }

    public resetFilters() {
        this.filter_form.patchValue({
            registered_type: 'all',
            name: '',
            contact: ''
        }, { emitEvent: false });
        this.loadData('0');
        this.hostingsService.removeFilters(this.constructor.name);
    }

    public onPageChanged(event) {
        console.log(event);
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                page: event.offset
            },
            queryParamsHandling: 'merge',
            skipLocationChange: false
        });
    }

    public create(hosting) {
        console.log('create hosting ' + hosting.name + ' ' + hosting.id);
        // this.spinner.show();
        // this.hostingsService.operation(hosting.id, 'createHosting', 'hosting')
        //     .subscribe(
        //         data => {
        //             this.spinner.hide();
        //             console.log(data);
        //             this.toastr.success('Хостинг зареєстровано');
        //             this.loadData(this.page);
        //         },
        //         err => {
        //             this.spinner.hide();
        //             this.toastr.error('Не вдалося створити хостинг');
        //             this.toastr.error(err);
        //             console.log(err);
        //         }
        //     );
    }
}
