import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-classes-editor',
  templateUrl: './classes-editor.component.html',
  styleUrls: ['./classes-editor.component.scss']
})
export class ClassesEditorComponent implements OnInit {

    public allClasses;
    public selectedClasses;
    public classes_form: FormGroup;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        public toastr: ToastrService,
        public translate: TranslateService
    ) {
        this.allClasses = [];
        for (let i = 1; i <= 45; i++) {
            this.allClasses.push(i);
        }

        this.classes_form = this.formBuilder.group({
            classes: new FormArray(this.getClassesForms(['']))
        });
    }

    ngOnInit() {
        if (this.selectedClasses) {
            this.classes_form.setControl('classes', new FormArray(this.getClassesForms(this.selectedClasses)));
        }
    }

    private getClassesForms(appClasses: string[]) {
        const result = [];
        for (const cl of this.allClasses) {
            result.push(new FormControl(appClasses.indexOf(String(cl)) !== -1));
        }
        return result;
    }

    private getClassesFromForm(formClasses: boolean[]) {
        const result = [];
        for (let i = 0; i < formClasses.length; i++) {
            if (formClasses[i]) {
                result.push(String(i + 1));
            }
        }
        return result;
    }

    public cancel() {
        this.activeModal.close('cancel');
    }

    public save() {
        const result = this.getClassesFromForm(this.classes_form.value['classes']);
        if (result.length === 0) {
            this.translate.get('TRADEMARKS.NO_CLASS_CHOSEN').subscribe((res: string) => {
                this.toastr.error(res);
            });
            return;
        }
        this.activeModal.close(result);
    }
}
