import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-are-you-sure',
  templateUrl: './are-you-sure.component.html',
  styleUrls: ['./are-you-sure.component.scss']
})
export class AreYouSureComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  public cancel() {
    this.activeModal.close('cancel');
  }

  public confirm() {
    this.activeModal.close('confirm');
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
