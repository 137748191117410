import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MailClientComponent } from './mail-client/mail-client.component';

const routes: Routes = [
    {
    path: '',
    children: [
        {
            path: 'app',
            component: MailClientComponent,
            data: {
                title: 'Почтовый клиент'
            }
        }
    ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MailRoutingModule { }
