import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReloadService {
    private activeComponent;
    public lastChangeTime = 0;
    public reloadInterval = 60000; // 1 min

    constructor() {}

    public reload() {
        if (this.activeComponent &&
            typeof this.activeComponent.ngOnInit === 'function'
        ) {
            console.log('Current active component:');
            console.log(this.activeComponent);
            this.activeComponent.ngOnInit();
        }
    }

    public setActiveComponent(newActiveComponent) {
        this.activeComponent = newActiveComponent;
        this.lastChangeTime = new Date().getTime();
    }

    public startReloadCounter() {
        setInterval(() => {
            if (new Date().getTime() - this.lastChangeTime > this.reloadInterval) {
                this.reload();
            }
        }, this.reloadInterval);
    }
}
