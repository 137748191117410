import { UnDrupalizeObject } from '../../shared/common/undrupalize-object';

export class Domain {
    public name = '';
    public domain_zone = '';
    public registered = '';
    public extended = '';
    public user_id = '';
    public user_name = '';
    public application = '';
    public tm = '';
    public domain_contact = '';
    public domain_contact_id = '';
    public status = '';
    public id = '';
    public contact_full_name = '';
    public free_domain = false;

    static getInstanceFromDrupal(drupalData) {
        return new Domain(UnDrupalizeObject.format(drupalData));
    }

    constructor(data) {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                this[key] = data[key];
            }
        }
    }
}
