import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MailRoutingModule } from './mail-routing.module';
import { MailClientComponent } from './mail-client/mail-client.component';

@NgModule({
  imports: [
    CommonModule,
    MailRoutingModule
  ],
  declarations: [MailClientComponent]
})
export class MailModule { }
