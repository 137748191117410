import { LocalStorageService } from 'ngx-store';
import { Observable, ReplaySubject, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { ROUTES, START_PATHS } from '../sidebar/sidebar-routes.config';
import { RouteInfo } from '../sidebar/sidebar.metadata';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarRoutesService {
    public routesType: ReplaySubject<{routes: RouteInfo[], type: string}> = new ReplaySubject<{routes: RouteInfo[], type: string}>();
    public defaultRoutesType = 'TM';
    public topRole;

    constructor(
        private storage: LocalStorageService,
        private baseService: BaseService
    ) {}

    public setTopRole(): Observable<any> {
        return Observable.create(observer => {
            this.baseService.getUser(localStorage.getItem('duid'))
                .subscribe(
                    data => {
                        if (data.roles) {
                            const roles = data.roles.map(r => r.target_id);
                            if (roles.indexOf('admin') !== -1) {
                              this.topRole = 'admin';
                            } else if (roles.indexOf('manager') !== -1) {
                              this.topRole = 'manager';
                            } else if (roles.indexOf('worldwide_manager') !== -1) {
                              this.topRole = 'worldwide_manager';
                            } else if (roles.indexOf('searcher') !== -1) {
                              this.topRole = 'searcher';
                            } else if (roles.indexOf("partner_manager") !== -1) {
                                this.topRole = "partner_manager";
                            }
                        }
                        observer.next(this.topRole);
                    },
                    e => observer.error(e),
                    () => observer.complete()
                );
            }
        );
    }

    public setNewRoutes(type) {
        this.routesType.next({routes: ROUTES[type], type});
        // this.storage.set(this.constructor.name, type);
    }

    public getRoutesByType(type) {
        return ROUTES[type];
    }

    public getStartPathForRouteType(routeType) {
        return START_PATHS[routeType];
    }

    public getRoutesByRoute(route: string) {
        const type = this.getRoutesTypeByRoute(route);
        if (type) {
            return ROUTES[type];
        }
        return null;
    }

    public getRoutesTypeByRoute(route: string) {
        for (const key in ROUTES) {
            if (ROUTES.hasOwnProperty(key)) {
                for (const routes of ROUTES[key]) {
                    if (this.findRoute(routes, route)) {
                        return key;
                    }
                }
            }
        }
        return null;
    }

    private findRoute(routes: RouteInfo, route: string) {
        if (routes.path !== '' && route.indexOf(routes.path) === 0) {
            return true;
        } else if (routes.submenu.length > 0) {
            for (const item of routes.submenu) {
                if (this.findRoute(item, route)) {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    }
}
