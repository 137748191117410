import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CKEditorComponent } from 'ngx-ckeditor';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';

import { FetchInvoicesService } from '../../invoices/services/fetch-invoices.service';
import { DrupalizeObject } from '../../shared/common/drupalize-object';
import { UnDrupalizeObject } from '../../shared/common/undrupalize-object';
import { Document } from '../models/Document';
import { GetDocumentsService } from '../services/get-documents.service';

@Component({
    selector: 'app-document-edit-form',
    templateUrl: './document-edit-form.component.html',
    styleUrls: ['./document-edit-form.component.scss']
})
export class DocumentEditFormComponent implements OnInit, OnDestroy {
    @Input()
    document: Document;
    @ViewChild(CKEditorComponent, {static: true})
    ckEditor: CKEditorComponent;

    public document_form: FormGroup;
    public title = 'DOCUMENTS.DOCUMENT_EDITING';
    public intervalSubscription: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        public invoicesService: FetchInvoicesService,
        public toastr: ToastrService,
        public docsService: GetDocumentsService,
        public spinner: NgxSpinnerService,
        public translate: TranslateService
    ) {
        this.document_form = this.formBuilder.group({
            document_text: ['']
        });
    }

    ngOnInit() {
        if (typeof this.document.document_text === 'undefined') {
            this.docsService.getOne(this.document.id, 'document').subscribe(
                res => {
                    const undrupalized: any = UnDrupalizeObject.format(res);
                    this.document = new Document(undrupalized);

                    this.document_form.patchValue(this.document);
                },
                e => {
                    console.log(e);
                }
            );
        } else {
            this.document_form.patchValue(this.document);
        }
        this.intervalSubscription = interval(60000).subscribe(
            m => {
                this.doSave();
            },
            e => {
                console.log(e);
            }
        );
    }

    ngOnDestroy(): void {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    }

    ngAfterViewChecked() {
        if (this.document.editable) {
            const editor = this.ckEditor.instance;

            editor.config.toolbarGroups = [
                { name: 'document', groups: ['mode', 'document', 'doctools'] },
                { name: 'clipboard', groups: ['clipboard', 'undo'] },
                { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
                { name: 'forms', groups: ['forms'] },
                { name: 'insert', groups: ['insert'] },
                '/',
                { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
                { name: 'links', groups: ['links'] },
                '/',
                { name: 'styles', groups: ['styles'] },
                { name: 'colors', groups: ['colors'] },
                { name: 'tools', groups: ['tools'] },
                { name: 'others', groups: ['others'] },
                { name: 'about', groups: ['about'] }
            ];

            editor.config.removeButtons = 'NewPage,Preview,Print,Save,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,BidiLtr,BidiRtl,Language,Blockquote,Flash,Smiley,Iframe,About,Anchor,PageBreak';
            // editor.config.extraPlugins = 'lineheight';
            editor.config.allowedContent = true;
            editor.config.extraAllowedContent = 'img[width,height]';
            editor.config.disallowedContent = 'img{width,height}';
            editor.config.protectedSource.push(/<pagebreak \/>/g);

            this._addImageUploadBtn();
        }
    }

    public doSave() {
        const form_data = this.document_form.value;
        const doc_payload = DrupalizeObject.format(form_data);

        this.docsService.save(this.document.id, doc_payload, 'document').subscribe(
            m => {
                this.translate.get('DOCUMENTS.DOC_SAVED').subscribe((res: string) => {
                    this.toastr.success(res);
                });
            },
            e => {
                console.log(e);
                this.translate.get('DOCUMENTS.ERROR_SAVING_DOC').subscribe((res: string) => {
                    this.toastr.error(res);
                });
                this.toastr.error(e.error ? e.error.message : '');
            }
        );
    }

    saveDocument() {
        if (!this.document_form.valid) {
            this.translate.get('ALERTS.FILL_ALL_FIELDS').subscribe((res: string) => {
                this.toastr.error(res);
            });
            return;
        }
        const form_data = this.document_form.value;
        const doc_payload = DrupalizeObject.format(form_data);

        this.spinner.show();
        this.docsService.save(this.document.id, doc_payload, 'document').subscribe(
            m => {
                this.docsService.operation(+this.document.id, 'generateFileFromText', 'document').subscribe(res => {
                    this.translate.get('DOCUMENTS.FILE_CREATED').subscribe((res: string) => {
                        this.toastr.success(res);
                    });
                    const undrupalized: any = UnDrupalizeObject.format(res);
                    console.log(undrupalized);

                    // getFile and update document object
                    this.docsService.getFile(undrupalized.file.target_id).subscribe(file_response => {
                        const file_undrupalized: any = UnDrupalizeObject.format(file_response);
                        let url = file_undrupalized.uri;
                        url = url.replace('public://', 'https://ideabox.name/sites/default/files/');
                        this.document.fileViewURL = url;
                        this.spinner.hide();
                    }, err => console.log(err));
                    // this.document = new Document(undrupalized);
                });
                this.translate.get('DOCUMENTS.DOC_SAVED').subscribe((res: string) => {
                    this.toastr.success(res);
                });
            },
            e => {
                console.log(e);
                this.translate.get('DOCUMENTS.ERROR_SAVING_DOC').subscribe((res: string) => {
                    this.toastr.error(res);
                });
                this.toastr.error(e.error ? e.error.message : '');
                this.spinner.hide();
            }
        );
    }

    viewDoc() {
        window.open(this.document.fileViewURL, '_blank');
    }

    markSended() {
        this.docsService.operation(+this.document.id, 'markSended', 'document').subscribe(
            m => {
                this.translate.get('DOCUMENTS.DOC_SENT').subscribe((res: string) => {
                    this.toastr.success(res);
                });
                this.activeModal.close();
            },
            e => {
                this.translate.get('DOCUMENTS.ERROR_SENDING_DOC').subscribe((res: string) => {
                    this.toastr.error(res);
                });
                this.toastr.error(e.error ? e.error.message : '');
                this.toastr.error(e.error ? e.error.message : '');
            }
        );
    }

    saveWordDocument() {
        if (!this.document_form.valid) {
            this.translate.get('ALERTS.FILL_ALL_FIELDS').subscribe((res: string) => {
                this.toastr.error(res);
            });
            return;
        }
        const form_data = this.document_form.value;
        const doc_payload = DrupalizeObject.format(form_data);

        this.spinner.show();
        this.docsService.save(this.document.id, doc_payload, 'document').subscribe(
            m => {
                this.docsService.operation(+this.document.id, 'saveWordDocument', 'document').subscribe(res => {
                    this.translate.get('DOCUMENTS.FILE_CREATED').subscribe((res: string) => {
                        this.toastr.success(res);
                    });
                    const undrupalized: any = UnDrupalizeObject.format(res);
                    console.log(undrupalized);

                    // getFile and update document object
                    this.docsService.getFile(undrupalized.file.target_id).subscribe(file_response => {
                        const file_undrupalized: any = UnDrupalizeObject.format(file_response);
                        let url = file_undrupalized.uri;
                        url = url.replace('public://', 'https://ideabox.name/sites/default/files/');
                        this.document.fileViewURL = url;
                        this.spinner.hide();
                    });
                    // this.document = new Document(undrupalized);
                });
                this.translate.get('DOCUMENTS.DOC_SAVED').subscribe((res: string) => {
                    this.toastr.success(res);
                });
            },
            e => {
                console.log(e);
                this.translate.get('DOCUMENTS.ERROR_SAVING_DOC').subscribe((res: string) => {
                    this.toastr.error(res);
                });
                this.toastr.error(e.error ? e.error.message : '');
                this.spinner.hide();
            }
        );
    }

    sendNotifications() {
        this.docsService.operation(+this.document.id, 'sendNotifications', 'document').subscribe(
            m => {
                this.translate.get('DOCUMENTS.DOC_SENT').subscribe((res: string) => {
                    this.toastr.success(res);
                });
                this.activeModal.close();
            },
            e => {
                this.translate.get('DOCUMENTS.ERROR_SENDING_DOC').subscribe((res: string) => {
                    this.toastr.error(res);
                });
                this.toastr.error(e.error ? e.error.message : '');
            }
        );
    }

    sendDocument() {
        this.docsService.operation(+this.document.id, 'sendDocument', 'document').subscribe(
            m => {
                this.translate.get('DOCUMENTS.DOC_SENT').subscribe((res: string) => {
                    this.toastr.success(res);
                });
                this.activeModal.close();
            },
            e => {
                this.translate.get('DOCUMENTS.ERROR_SENDING_DOC').subscribe((res: string) => {
                    this.toastr.error(res);
                });
                this.toastr.error(e.error ? e.error.message : '');
            }
        );
    }

    _addImageUploadBtn() {
        const editor = this.ckEditor && this.ckEditor.instance;
        if (!editor) {
            return;
        }

        editor.on('instanceReady', function(ev) {
            // Ends self closing tags the HTML4 way, like <br>.
            ev.editor.dataProcessor.htmlFilter.addRules({
                elements: {
                    $: function(element) {
                        // Output dimensions of images as width and height
                        if (element.name == 'img') {
                            var style = element.attributes.style;

                            if (style) {
                                // Get the width from the style.
                                var match = /(?:^|\s)width\s*:\s*(\d+)px/i.exec(style),
                                    width = match && match[1];

                                // Get the height from the style.
                                match = /(?:^|\s)height\s*:\s*(\d+)px/i.exec(style);
                                var height = match && match[1];

                                // Get the float from the style.
                                match = /(?:^|\s)float\s*:\s*(\w+)/i.exec(style);
                                var float = match && match[1];

                                if (width) {
                                    element.attributes.style = element.attributes.style.replace(/(?:^|\s)width\s*:\s*(\d+)px;?/i, '');
                                    element.attributes.width = width;
                                }

                                if (height) {
                                    element.attributes.style = element.attributes.style.replace(/(?:^|\s)height\s*:\s*(\d+)px;?/i, '');
                                    element.attributes.height = height;
                                }
                                if (float) {
                                    element.attributes.style = element.attributes.style.replace(/(?:^|\s)float\s*:\s*(\w+)/i, '');
                                    element.attributes.align = float;
                                }

                            }
                        }

                        if (!element.attributes.style) delete element.attributes.style;

                        return element;
                    }
                }
            });
        });

        editor.addCommand('uploadImage', {
            exec: (editor: any) => {
                // Remove img input.
                [].slice.apply(document.querySelectorAll('.ck-editor-upload-img')).forEach((img: any) => {
                    img.remove();
                });
                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('class', 'ck-editor-upload-img');
                input.style.display = 'none';
                input.addEventListener(
                    'change',
                    e => {
                        const file = (e.target as HTMLInputElement).files[0];
                        if (file) {
                            this.docsService.uploadFile(file).subscribe(
                                res => {
                                    const filepath = res['file_styled_url'];
                                    editor.insertHtml(`<img alt="" src="${filepath}"></img>`);
                                },
                                e => {
                                    this.translate.get('DOCUMENTS.ERROR_LOADING_FILE').subscribe((res: string) => {
                                        this.toastr.error(res);
                                    });
                                    this.toastr.error(e.error ? e.error.message : '');
                                }
                            );
                        }
                    },
                    false
                );
                document.body.appendChild(input);
                input.click();
            }
        });
        editor.ui.addButton('uploadImage', {
            icon: 'http://ideabox.name/sites/default/files/insert-file.png',
            label: 'Upload Image',
            command: 'uploadImage',
            toolbar: 'insert'
        });

    }
}
