import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { InvoiceModel } from "../models/invoice-model";
import { PaymentRecordViewItem } from "../models/paylink-model";
import { PaylinkFormComponent } from "../paylink-form/paylink-form.component";
import { FetchInvoicesService } from "../services/fetch-invoices.service";

@Component({
    selector: "app-payment-records",
    templateUrl: "./payment-records.component.html",
    styleUrls: ["./payment-records.component.scss"],
})
export class PaymentRecordsComponent implements OnInit {
    @ViewChild("dateTpl", { static: true })
    dateTpl: TemplateRef<any>;
    @ViewChild("headerTmpl", { static: true })
    headerTmpl: TemplateRef<any>;

    rows!: PaymentRecordViewItem[];
    pager = {
        current_page: 0,
        total_items: 0,
        total_pages: 0,
        items_per_page: 50,
    };

    columns = [];
    filter_form: FormGroup;

    constructor(
        public invoicesService: FetchInvoicesService,
        public formBuilder: FormBuilder,
        private modalService: NgbModal
    ) {}

    ngOnInit() {
        this.filter_form = this.formBuilder.group({
            created: this.formBuilder.group({
                min: [""],
                max: [""],
            }),
        });

        this.goForIt();

        this.columns = [
            {
                name: "INVOICES.COL_CREATED",
                prop: "created",
                cellTemplate: this.dateTpl,
                headerTemplate: this.headerTmpl,
            },
            {
                name: "INVOICES.COL_AMOUNT",
                prop: "amount",
                headerTemplate: this.headerTmpl,
            },
            {
                name: "INVOICES.COL_CURRENCY",
                prop: "currency",
                headerTemplate: this.headerTmpl,
            },
        ];
    }

    fetchPage(page: number) {
        return this.goForIt(page);
    }

    goForIt(page: number = 0) {
        let filters = {};
        filters["created[min]"] =
            this.filter_form.value.created.min ||
            (this.filter_form.value.created.max &&
                new Date("1990-01-01").toISOString().substring(0, 10));
        filters["created[max]"] =
            this.filter_form.value.created.max ||
            (this.filter_form.value.created.min &&
                new Date().toISOString().substring(0, 10));
        filters["created"] = undefined;
        return this.invoicesService
            .query("/admin/profit/payment_records", {
                page,
            })
            .subscribe((data) => {
                if (data.rows) {
                    this.rows = data.rows;
                    this.pager = data.pager;
                }
            });
    }

    openPaylinkDialog(invoice?: InvoiceModel) {
        const modalRef = this.modalService.open(PaylinkFormComponent, {
            size: "lg",
        });
        modalRef.componentInstance.invoice = invoice;

        modalRef.result.then(
            (result) => {
                this.goForIt();
            },
            (reason) => {
                console.log(reason);
            }
        );
    }

    clearFilters() {
        this.filter_form.reset();
    }

    clearPayedDate(field: string) {
        this.filter_form.get("created").get(field).patchValue("");
    }

    clearAllPayedDate() {
        this.filter_form.get("created").get("min").patchValue("");
        this.filter_form.get("created").get("max").patchValue("");
    }

    setPayedToday() {
        const today = new Date();
        this.filter_form
            .get("created")
            .get("min")
            .patchValue(today.toISOString().substring(0, 10));
        this.filter_form
            .get("created")
            .get("max")
            .patchValue(today.toISOString().substring(0, 10));
    }
}
