import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UsersService } from './users.service';


@Injectable()
export class UserResolver implements Resolve<Observable<any>> {
    constructor(private service: UsersService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.service.getUser(route.params['id']).pipe(
            catchError(err => {
                console.error(err);
                return of('No data');
            })
        );
    }
}

