import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Invoice } from '../models/invoice-model';
import { FetchInvoicesService } from '../services/fetch-invoices.service';

@Component({
    selector: 'app-refund-form',
    templateUrl: './refund-form.component.html',
    styleUrls: ['./refund-form.component.scss']
})
export class RefundFormComponent implements OnInit {
    @Input() invoice: Invoice;
    public amount = new FormControl();
    public saving: Boolean;
    constructor(
        public service: FetchInvoicesService,
        public toastr: ToastrService,
        public activeModal: NgbActiveModal,
        public translate: TranslateService
    ) { }

    ngOnInit() {
        this.service.operation(this.invoice.id, 'getRefundAmount', 'invoice').subscribe(
            res => {
                this.amount.patchValue(res);
            },
            e => {
                console.log(e);
            }
        );
    }

    doRefund() {
        this.service.refundInvoice(this.invoice.id, this.amount.value).subscribe(
            m => {
                console.log(m);
                this.translate.get('INVOICES.INVOICE_CANCELED').subscribe((res: string) => {
                    this.toastr.success(res);
                });
            },
            e => {
                this.translate.get('INVOICES.ERROR_REFUNDING').subscribe((res: string) => {
                    this.toastr.error(res);
                });
                this.toastr.error(e.error ? e.error.message : '');
                console.log(e.error.message);
            }
        );
    }
}
