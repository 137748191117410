import { Observable, of } from "rxjs";
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    merge,
    switchMap,
    tap
} from "rxjs/operators";

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { UserServicesService } from "../services/user-services.service";

@Component({
    selector: "app-users",
    templateUrl: "./users.component.html",
    styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
    public inUserCreation = false;
    public userName = "";
    public searchingUsers = false;
    public searchFailedUsers = false;
    public hideSearchingWhenUnsubscribedUsers = new Observable(
        () => () => (this.searchingUsers = false)
    );

    public searchUsers;

    @Input() user: any;

    @Output() userCreated = new EventEmitter<any>();
    @Output() userCancelled = new EventEmitter<any>();
    @Output() userSelected = new EventEmitter<any>();

    constructor(private readonly service: UserServicesService) {}

    ngOnInit(): void {
        this.searchUsers = (text$: Observable<string>) =>
            text$.pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap(() => (this.searchingUsers = true)),
                switchMap((term) =>
                    this.service.getUsers(term as string).pipe(
                        tap(() => (this.searchFailedUsers = false)),
                        map((result) => {
                            for (const user of result as any[]) {
                                if (
                                    user["field_surname"] ||
                                    user["field_forename"]
                                ) {
                                    user["full_name"] =
                                        user["field_surname"] +
                                        " " +
                                        user["field_forename"];
                                } else {
                                    user["full_name"] = user["name"];
                                }
                            }
                            return result;
                        }),
                        catchError(() => {
                            this.searchFailedUsers = true;
                            return of([]);
                        })
                    )
                ),
                tap(() => (this.searchingUsers = false)),
                merge(this.hideSearchingWhenUnsubscribedUsers)
            );
    }

    public onUserSelected(event) {
        // event.item
        this.userSelected.emit(event.item);
    }

    usersFormatter = (user) => {
        let name;
        if (user["full_name"]) {
            name = user["full_name"];
        } else {
            name = user["name"];
        }
        return (
            (name + "").replace(/&#\d+;/gm, function (s) {
                return String.fromCharCode(Number(s.match(/\d+/gm)[0]));
            }) +
            " " +
            user["mail"]
        );
    };

    userCreationCancel() {
        this.inUserCreation = false;
    }

    userSaved(savedUser) {
        this.userName = savedUser.userName;
        this.inUserCreation = false;
        this.userCreated.emit(savedUser);
    }
}
