import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { Injectable } from "@angular/core";

import { BaseService } from "../../shared/services/base.service";
import { Invoice } from "../models/invoice-model";

@Injectable({
    providedIn: "root",
})
export class FetchInvoicesService extends BaseService {
    static ngInjectableDef = undefined;

    public prepereRows(res) {
        const out = [];
        for (const item of res.rows) {
            try {
                out.push(new Invoice(item));
            } catch (error) {
                console.error(error);
            }
        }
        res.rows = out;

        return res;
    }

    public pay(id: any): Observable<any> {
        const payload = { status: "payed-full" }; // Make full payment operation; 'payed' - only set state
        const uri = "/api/v1/invoice/" + id;
        return Observable.create((observer) => {
            this.http
                .patch<any>(this.host + uri, payload, {
                    params: { _format: "json" },
                    headers: this.headers,
                })
                .subscribe(
                    (m) => {
                        observer.next(m);
                    },
                    (e) => observer.error(e),
                    () => observer.complete()
                );
        });
    }

    printInvoice(id: number) {
        window.open(this.host + "/user/invoices/" + id + "/print", "_blank");
    }

    downloadInvoices(filters: any) {
        const uri = "/admin/profit/invoices/views_export";
        return this.http
            .get<any>(this.host + uri, {
                params: filters,
                headers: this.headers,
                responseType: "blob" as "json",
            })
            .pipe(
                tap((data: any) => {
                    const blob = new Blob([data], { type: "application/zip" });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    const date = new Date().toISOString().slice(0, 10);
                    link.download = `invoices_export_${date}.zip`;
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                }),
                map(() => true)
            );
    }

    public refundInvoice(id, amount = ""): Observable<any> {
        const payload = {
            action: "refund",
            amount,
        };

        const uri = "/admin/profit/invoice/" + id + "/operations";

        return Observable.create((observer) => {
            this.http
                .patch(this.host + uri, payload, {
                    params: { _format: "json" },
                    headers: this.headers,
                })
                .subscribe(
                    (m) => observer.next(m),
                    (e) => observer.error(e),
                    () => observer.complete()
                );
        });
    }

    /**
     * Outdated?
     * TODO delete
     *
     * @param contain term for view exposed filter
     * @param type entity bundle for context filter
     */
    public searchApplication(contain: string, type = "all"): Observable<any> {
        const uri = "/admin/profit/application/list_paged/" + type;
        return Observable.create((observer) => {
            this.http
                .get<any>(this.host + uri, {
                    params: { _format: "json", contain },
                    headers: this.headers,
                })
                .subscribe(
                    (m) => observer.next(m),
                    (e) => observer.error(e),
                    () => observer.complete()
                );
        });
    }

    /**
     * Search services by service name/profitmark ID
     *
     * @param contain contain filter for view exposed filter
     * @param bundle entity bundle for context filter
     * @param service_type entity type for context filter
     */
    public getServices(
        contain: string,
        bundle = "all",
        service_type = "application"
    ): Observable<any> {
        console.log("service type " + service_type);
        const uri = "/admin/profit/" + service_type + "/list/" + bundle;
        return Observable.create((observer) => {
            this.http
                .get<any>(this.host + uri, {
                    params: { _format: "json", contain },
                    headers: this.headers,
                })
                .subscribe(
                    (m) => {
                        for (const r of m.rows) {
                            if (!r.target_label && r.name) {
                                r["target_label"] = r.name;
                            }
                            if (!r.target_label && r.tm_name) {
                                r["target_label"] = r.tm_name;
                            }
                            if (!!r.target_label && r.small_title_clean) {
                                r["target_label"] = r.small_title_clean;
                            }
                        }
                        observer.next(m.rows);
                    },
                    (e) => observer.error(e),
                    () => observer.complete()
                );
        });
    }

    public getServiceTypes() {
        return this.http.get<any>(
            this.host + "/admin/profit/service_type/list",
            { params: { _format: "json" }, headers: this.headers }
        );
    }

    public getTypes() {
        const uri = "/admin/profit/service_type/list";
        return this.http.get<any>(environment.host + uri, {
            params: { _format: "json" },
            headers: this.headers,
        });
    }
}
