import { Applicant } from "app/applicants/models/Applicant";
import { environment } from "environments/environment";
import { LocalStorageService } from "ngx-store";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { DrupalizeObject } from "../../shared/common/drupalize-object";
import { BaseService } from "../../shared/services/base.service";
import { Application } from "../models/ApplicationsListData";
import { AppOperationsService } from "./app-operations.service";

@Injectable({
    providedIn: "root",
})
export class GetTmDataService extends BaseService {
    static ngInjectableDef = undefined;

    // test paths
    private usersPath = "/admin/profit/users/list/";

    public applicationsListPath = "/admin/profit/application_full/list/";
    private reportAppsListPath = "/admin/profit/application_full/report/list/";
    private disabledAppsListPath =
        "/admin/profit/application_full/disabled/list/";
    public refundAppsListPath = "/admin/profit/application_full/refund/list/";
    private docDebtorAppsListPath =
        "/admin/profit/application_full/docdebtor/list/";
    private paidAppsListPath = "/admin/profit/application_full/paid/list/";
    private unpaidAppsListPath = "/admin/profit/application_full/unpaid/list/";

    private worldAppsListPath = "/admin/profit/application/international/list/";
    private worldAppsPaidListPath =
        "/admin/profit/application/international/paid/list/";
    private worldAppsUnpaidListPath =
        "/admin/profit/application/international/unpaid/list/";
    private worldAppsRefundListPath =
        "/admin/profit/application/international/refund/list/";

    private applicantsPath = "/admin/profit/applicants";

    private trademarkUrl = environment.host + "/admin/profit/application/";
    private registeredURL =
        environment.host + "/admin/profit/trademark/registered?_format=json";
    private registeredEntity = environment.host + "/admin/profit/trademark/";
    private applicantURL = environment.host + "/admin/profit/applicant/";
    private apiUsername = "dasha";
    private apiPassword = "dasha12";

    private httpOptions = {
        headers: new HttpHeaders({
            Authorization:
                "Basic " +
                new Buffer(`${this.apiUsername}:${this.apiPassword}`).toString(
                    "base64"
                ),
        }),
    };

    public typeAll = "all";
    public typeDisabled = "no";
    public typeRefund = "refund";
    public typeDebtors = "debtor";
    public typeDocDebtors = "doc_debtors";
    // public typeNotCalled = 'empty';
    public paid = "paid";
    public unpaid = "unpaid";
    public report = "report";
    // public waitForLogo = 'wait_for_logo';

    constructor(public http: HttpClient, public storage: LocalStorageService) {
        super(http, storage);
    }

    public getAllCount() {
        const uri = this.applicationsListPath + "all";
        const params = { _format: "json", page: "0" };
        return this.http.get<any>(this.host + uri, {
            params: { ...params },
            headers: this.headers,
        });
    }

    public getAllPayedCount() {
        const uri = this.paidAppsListPath + "all";
        const params = { _format: "json", page: "0" };
        return this.http.get<any>(this.host + uri, {
            params: { ...params },
            headers: this.headers,
        });
    }

    public getLastLog(type, id) {
        const options = { params: { _format: "json" }, headers: this.headers };
        const pageUrl = `${this.host}/admin/profit/trademark_event_last/${type}/${id}`;
        return Observable.create((observer) => {
            this.http.get<any>(pageUrl, options).subscribe(
                (m) => {
                    // console.log(m);
                    if (m && m.length > 0 && m[0].message) {
                        observer.next(m[0].message);
                    } else {
                        observer.next("");
                    }
                },
                (e) => observer.error(e),
                () => observer.complete()
            );
        });
    }

    public getApplicants(contain?: string) {
        const pageUrl = `${this.host}${this.applicantsPath}`;
        let params;
        if (contain && contain !== "") {
            params = { _format: "json", combine: contain };
        } else {
            params = { _format: "json" };
        }
        return Observable.create((observer) => {
            this.http
                .get<any>(pageUrl, {
                    params: params,
                    // headers: this.headers
                })
                .subscribe(
                    (m) => observer.next(m),
                    (e) => observer.error(e),
                    () => observer.complete()
                );
        });
    }

    public getUser(id: string): Observable<any> {
        const uri = "/user/" + id;
        return this.http.get<any>(this.host + uri, {
            params: { _format: "json" },
            headers: this.headers,
        });
    }

    public getUsers(contain?: string): Observable<any> {
        const pageUrl = `${this.host}${this.usersPath}`;
        let params;
        if (contain && contain !== "") {
            params = { _format: "json", user: contain };
        } else {
            params = { _format: "json" };
        }
        return Observable.create((observer) => {
            this.http
                .get<any>(pageUrl, {
                    params: params,
                    // headers: this.headers
                })
                .subscribe(
                    (m) => {
                        for (const item of m) {
                            item["fio"] =
                                item.field_forename + " " + item.field_surname;
                        }
                        observer.next(m);
                    },
                    (e) => observer.error(e),
                    () => observer.complete()
                );
        });
    }

    public fetchWorldApplications(
        page = "0",
        type = this.typeAll,
        filters = {}
    ): void {
        let uri;
        let id = "all";
        switch (type) {
            case this.paid:
                uri = this.worldAppsPaidListPath;
                break;
            case this.unpaid:
                uri = this.worldAppsUnpaidListPath;
                break;
            case this.typeDocDebtors:
                // DOC DEBTORS DISPLAYS ALL WORLD APPS FOR NOW!
                uri = this.worldAppsListPath;
                break;
            case this.typeRefund:
                uri = this.worldAppsRefundListPath;
                break;
            default:
                uri = this.worldAppsListPath;
                id = type;
        }
        this.fetch(page, id, filters, uri);
    }

    public fetchApplications(
        page = "0",
        type = this.typeAll,
        filters = {}
    ): void {
        let uri;
        let id = "";
        switch (type) {
            case this.typeDisabled:
                uri = this.disabledAppsListPath;
                break;
            case this.typeRefund:
                uri = this.refundAppsListPath;
                id = "all";
                break;
            case this.typeDocDebtors:
                uri = this.docDebtorAppsListPath;
                break;
            case this.paid:
                uri = this.paidAppsListPath;
                id = "all";
                break;
            case this.unpaid:
                uri = this.unpaidAppsListPath;
                id = "all";
                break;
            case this.report:
                uri = this.reportAppsListPath;
                id = "all";
                break;
            default:
                uri = this.applicationsListPath;
                id = type;
        }
        this.fetch(page, id, filters, uri);
    }

    public prepereRows(res) {
        const rows = new Array<Application>();
        const dataRows = res.rows;
        if (dataRows) {
            for (const r of dataRows) {
                const app = new Application(r);
                rows.push(app);
            }
            res.rows = rows;
        }
        return res;
    }

    // Potentially rewrite to inherit 'getOne()'
    getApplicant(id): Observable<any[] | Applicant> {
        const pageUrl = `${this.applicantURL}${id}?_format=json`;
        return this.http.get<Applicant>(pageUrl, this.httpOptions).pipe(
            map((val) => Applicant.applicantFromDrupal(val)),
            catchError(this.handleError("getApplicant", []))
        );
    }

    // Potentially rewrite to inherit 'getOne()'
    public getApplication(id: string): Observable<Application[]> {
        const pageUrl = `${this.trademarkUrl}${id}?_format=json`;
        return this.http.get(pageUrl, this.httpOptions).pipe(
            map((val) => [Application.applicationFromDrupal(val)]),
            catchError(this.handleError("getApp", []))
        );
    }

    public removeFilters(key) {
        // const key = this.constructor.name + '__filters';
        this.storage.remove(key);
        console.log("Filter flushed with key", key);
    }

    public retrieveFilters(key) {
        console.log("Fetched filters for: ", this.constructor.name);
        // const key = this.constructor.name + '__filters';
        return this.storage.get(key);
    }

    public saveFilters(flters, key) {
        console.log("Setted filters for: ", this.constructor.name);
        // const key = this.constructor.name + '__filters';
        this.storage.set(key, flters);
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = "operation", result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            // this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
