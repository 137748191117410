export const LANGUAGES_OPTIONS = [
    { label: "English", value: "en" },
    { label: "Español", value: "es" },
    { label: "Українська", value: "uk" },
    { label: "Polski", value: "pl" },
    { label: "Português", value: "pt-pt" },
    { label: "Italiano", value: "it" },
    { label: "Français", value: "fr" },
    { label: "Deutsch", value: "de" },
    { label: "العربية", value: "ar" },
    { label: "हिन्दी", value: "hi" },
    { label: "Türkçe", value: "tr" },
    { label: "Nederlands", value: "nl" },
    { label: "Svenska", value: "sv" },
    { label: "Dansk", value: "da" },
    { label: "Norsk", value: "no" },
    { label: "Čeština", value: "cs" },
    { label: "Slovenščina", value: "sl" },
    { label: "Ελληνικά", value: "el" },
    { label: "Română", value: "ro" },
    { label: "Magyar", value: "hu" },
    { label: "Lietuvių", value: "lt" },
];
