import { IRequisites } from "./requisites";

export interface InvoiceModel {
    id?: number | string;
    name?: string;
    amount?: string | number;
    invoice_description?: string;
    payed?: string;
    payment_method?: string;
    state?: string;
    service?: string;
    type?: string;
    seller?: string;
    currency?: string;
    no_vat?: boolean;
}

export interface ServiceModel {
    target_id: number;
    target_type?: string;
    target_label?: string;
}

export interface IInvoiceItem {
    description: string;
    quantity?: number;
    amount: number;
    taxable?: string;
}

export class Invoice {
    public id: number;
    public name: string;
    public amount: string;
    public invoice_description: string;
    public payed: string;
    public payment_method: string;
    public state: string;
    public actions = "";
    public service: ServiceModel;
    public type: string;
    public seller: string;
    public pay_date: string;
    public user_id?;
    public no_vat?: boolean;
    public items?: IInvoiceItem[];
    public requisites?: IRequisites;

    constructor(invoice: InvoiceModel) {
        for (const param in invoice) {
            if (invoice.hasOwnProperty(param)) {
                this[param] = invoice[param];
                if (param === "service") {
                    try {
                        this.service = JSON.parse(invoice[param]);
                    } catch {
                        console.log("Error parsing invoice service");
                    }
                }
                if (param === "requisites") {
                    try {
                        this.requisites = JSON.parse(invoice[param]);
                    } catch {
                        console.log("Error parsing invoice service");
                    }
                }
                if (param === "items") {
                    try {
                        const i = `[${invoice[param]}]`
                        this.items = JSON.parse(i);
                    } catch {
                        console.log("Error parsing invoice service");
                    }
                }
                if (param === "no_vat") {
                    if (!invoice[param] || (invoice[param] as any) === "0") {
                        this[param] = false;
                    } else {
                        this[param] = true;
                    }
                }
            }
        }
    }
}
