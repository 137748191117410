import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { DomainsHostingsDataService } from '../services/domains-hostings-data.service';

@Component({
  selector: 'app-edit-dns',
  templateUrl: './edit-dns.component.html',
  styleUrls: ['./edit-dns.component.scss']
})
export class EditDnsComponent implements OnInit {
    public domainName;
    // public dnsControls = [];
    public dnsControlsList = [];
    public dnsList = [];
    public soa: {
        expire: '',
        refresh: '',
        retry: '',
        ttl: ''
    };
    public recordTypes = ['AAAA', 'SRV', 'CNAME', 'MX', 'TXT', 'A'];
    public typeSelector = new FormControl('A');

    constructor(
        private formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        public toastr: ToastrService,
        public spinner: NgxSpinnerService,
        public service: DomainsHostingsDataService,
        public translate: TranslateService
    ) { }

    ngOnInit() {
        this.service.getDomainDetails(this.domainName, { dns: '1' }).subscribe(
            data => {
                console.log(data);
                // data = {
                //     dns: {
                //         records: [{ child: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0] }],
                //         soa: {
                //             expire: 182423424,
                //             refresh: 2465967657,
                //             retry: 323424,
                //             ttl: 434454569
                //         }
                //     }
                // };
                if (data.dns && data.dns.records) {
                    // create list of dns records controls
                    for (const dns of data.dns.records) {
                        for (const item of dns.child) {
                            this.dnsControlsList.push(this.createDnsControl(item, item.type));
                            // this.dnsControlsList.push(this.createDummyDnsControl());
                        }
                    }
                    // set raw dns data
                    this.dnsList = data.dns.records.slice(1, data.dns.records.length);
                }
                if (data.dns && data.dns.soa) {
                    // set soa data
                    this.soa = data.dns.soa;
                }
                // this.resize.measureCabinet();
            },
            err => {
                console.log(err);
            }
        );
    }

    public createDummyDnsControl() {
        const form = this.formBuilder.group({
            index: ['123', Validators.required],
            name: ['test', Validators.required],
            type: ['TXT', Validators.required],
            priority: ['2', Validators.required],
            value: ['qwe', Validators.required]
        });
        return {
            form: form,
            type: 'TXT'
        };
    }

    public createDnsControl(item, type) {
        const form = this.formBuilder.group({
            index: [item.index, Validators.required],
            name: [item.name, Validators.required],
            type: [item.type, Validators.required],
            priority: [item.priority, Validators.required],
            value: [item.value, Validators.required]
        });
        return {
            form: form,
            type: type
        };
    }

    public addRecord() {
        const type = this.typeSelector.value;
        const controls = [];
        let form = this.formBuilder.group({
            index: [-1],
            name: ['', Validators.required],
            type: [type],
            priority: ['', Validators.required],
            value: ['', Validators.required]
        });
        if (type !== 'MX') {
            form = this.formBuilder.group({
                index: [-1],
                name: ['', Validators.required],
                type: [type],
                value: ['', Validators.required]
            });
        }
        controls.push({
            form: form,
            type: type
        });
        this.dnsControlsList = [...controls, ...this.dnsControlsList];
    }

    public save(item, index) {
        console.log(item);
        console.log(index);
        const savedItem = item.form.value;
        // savedItem['type'] = item.type;
        if (Number(savedItem.index) !== -1) {
            // if item exists, only its properties changed
            // find it and change
            const child = this.findChild(item.type, Number(savedItem.index));
            child.value = savedItem.value;
            child.name = savedItem.name;
            if (item.type === 'MX') {
                child.priority = savedItem.priority;
            }
        } else {
            // if item is new - just add it
            this.addChild(savedItem);
        }

        this.saveAll();
    }

    private addChild(item) {
        let added = false;
        // try to find existing type of records in dns data
        // and add item as its child
        for (const record of this.dnsList) {
            if (record.name === item.type) {
                const index = record.child.length;
                item.index = index;
                record.child.push(item);
                added = true;
            }
        }
        // if record of item type was not found - create it
        if (!added) {
            item.index = 0;
            this.dnsList.push({
                name: item.type,
                child: [item]
            });
        }
    }

    private findChild(type, index) {
        for (const record of this.dnsList) {
            if (record.name === type) {
                return record.child[index];
            }
        }
        return null;
    }

    private deleteChild(type, index) {
        let record;
        for (const r of this.dnsList) {
            if (r.name === type) {
                record = r;
                break;
            }
        }
        if (record) {
            // delete element from record child and refresh child indexes
            record.child = record.child.filter((v, i) => i !== index);
            for (let i = 0; i < record.child.length; i++) {
                record.child[i].index = i;
            }
            // if deleted child was last in record - delete record
            if (record.child.length === 0) {
                this.dnsList = this.dnsList.filter((v) => v.name !== record.name);
            }
        }
    }

    public delete(item, index) {
        console.log(item);
        console.log(index);
        const deletedItem = item.form.value;
        if (Number(deletedItem.index) !== -1) {
            // if item exists
            // find it and delete
            this.deleteChild(item.type, Number(deletedItem.index));
            this.recreateDnsControlsList();
            this.saveAll();
        } else {
            // if item is new - just delete it from controls
            this.dnsControlsList = this.dnsControlsList.filter((v, i) => i !== index);
        }
    }

    public recreateDnsControlsList() {
        const unsavedRecords = [];
        for (const control of this.dnsControlsList) {
            if (Number(control.form.value.index) === -1) {
                unsavedRecords.push(control);
            }
        }
        const newControls = unsavedRecords;
        for (const record of this.dnsList) {
            for (const child of record.child) {
                newControls.push(this.createDnsControl(child, child.type));
            }
        }
        this.dnsControlsList = newControls;
    }

    public saveAll() {
        const payload = {
            dns: {
                records: this.dnsList,
                soa: this.soa
            }
        };
        console.log(payload);
        this.service.setDNS(this.domainName, payload).subscribe(
            data => {
                this.spinner.hide();
                this.translate.get('DOMAINS.DNS_SAVED').subscribe((res: string) => {
                    this.toastr.success(res);
                });
            },
            err => {
                this.spinner.hide();
                console.log(err);
                if (err.status === 400) {
                    this.translate.get('DOMAINS.WRONG_DNS_DATA').subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                } else {
                    this.translate.get('DOMAINS.ERROR_EDITING_DNS').subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                }
            }
        );
    }

    public close() {
        this.activeModal.close();
    }
}
