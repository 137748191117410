import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrettyNamesService {
  public host: string = environment.host;
  public headers = new HttpHeaders();

  public prettyStates = {};

  public prettyPatentTypes = {
    pm: 'Корисна модель',
    iz: 'Винахід',
    device: 'Пристрій',
    method: 'Спосіб'
  }

	public prettyNamingValues = {
    ten: '10',
    twenty: '20',
    thirty: '30',
    ua: 'Україна',
    ua_eu: 'Україна та ЄС',
    cis: 'СНД',
    cis_eu: 'СНД та ЄС',
    latin: 'Латиниця',
    cyrillic: 'Кирилиця',
    official: 'Офіційний',
    pro: 'Професійний',
    authority: 'Авторитетний',
    nonformal: 'Неформальний',
    humor: 'Гумористичний',
    other: 'Інший'
  };

  public prettyIdentityValues = {
    ministyle: 'MiniStyle',
    midstyle: 'MidStyle',
    maxstyle: 'MaxStyle',
    minibook: 'MiniBook',
    midbook: 'MidBook',
    maxsbook: 'MaxBook',
    address: 'Адресный блок',
    card: 'Визитки',
    blank: 'Бланки',
    envelope: 'Конверты',
    folder: 'Папка для бумаг',
    calend: 'Календарь (100х70)',
    pattern: 'Фирменный паттерн',
    badge: 'Бейдж',
    cd: 'Обложка CD-диска',
    euroflaer: 'Еврофлаер',
    sticker: 'Стикеры для записей',
    presentation: 'Шаблон презентации',
    advert_magazine: 'Шаблон рекламной полосы',
    cover_diary: 'Обложка ежедневника',
    pen: 'Ручка',
    cup: 'Офисная чашка',
    clock: 'Настенные часы',
    icon: 'Фирменный значок',
    tshirt: 'Футболка',
    cap: 'Бейсболка',
    flag: 'Настольный флажок',
    lighter: 'Зажигалка',
    citylight: 'Шаблон ситилайта',
    board: 'Шаблон борда (3х6 м)',
    troll3: 'Шаблон тролла (1х3 м)',
    troll6: 'Шаблон тролла (1х6 м)',
    site: 'Сайт-визитка',
    landing: 'Шаблон LandingPage',
    banner728: 'Банер HTML5 (728х90)',
    banner300: 'Банер HTML5 (300х250)',
    identity_package: 'identity_package'
  };

  public prettyLogoValues = {
    normal: 'Стандарт',
    fast: 'Пришвидшений',
    fast_as_lightning: 'Терміновий',
    character: 'Персонаж',
    geometric_figure: 'Геометрична фігура',
    letter: 'Обіграти букву',
    one: '1',
    three: '3',
    six: '6',
    nine: '9'
  };

  public prettyCopyrightValues = {
    music: 'Музична композиція',
    prog: 'Комп\'ютерна програма',
    book: 'Літературний твір',
    build: 'Твір архітектури, містобудування і садово-паркового мистецтва',
    logo: 'Дизайн логотипу',
    web: 'Вихідний код вебсайту',
    db: 'База даних',
    other: 'Інше',
    photo: 'Фото',
    compilation: 'Збірник творів',
    screenplay: 'Сценарій',
    clip: 'Аудіовізуальний твір'
  }

  dangerStates = {
    'no': true,
    'no_money': true,
    'no_fee': true,
    'wait_for_payment': true,
    'debtor': true,
    'wait_for_logo': true,
    'no_additional_word': true,
    'no_risk_letter': true,
    'no_chnage_tm': true,
    'no_after_instructions': true,
    'no_poa': true,
    'no_application': true,
    'no_money_war': true,
    'no_money_after_search': true
  };

  constructor(public http: HttpClient, public translate: TranslateService) {
    this.http.get<any>(this.host + '/' + this.translate.currentLang + '/admin/profit/application_states').subscribe(
      data => {
        if (data) {
          const states = {};
          for(const item of data) {
            states[item.id] = item.name;
          }
          this.prettyStates = { ...states, ...this.prettyStates };
        }
      }, err => {}
    )
  }

  public getPrettyState(rawState) {
    return this.prettyStates[rawState] ? this.prettyStates[rawState] : rawState;
  }
}
