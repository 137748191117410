import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-classes-details-chooser',
  templateUrl: './classes-details-chooser.component.html',
  styleUrls: ['./classes-details-chooser.component.scss']
})
export class ClassesDetailsChooserComponent implements OnInit {

    public classDetails;
    public searchProduct = new FormControl('');
    public products = [];
    public selectedProducts = [];

    constructor(
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit() {
        for (const prod of this.classDetails.data) {
            const control = new FormControl(false);
            const productView = {
                name: prod,
                active: false,
                control: control
            };
            control.valueChanges.subscribe(
                data => {
                    // do smth
                    console.log(data);
                    productView.active = data;
                    if (data) {
                        this.selectedProducts.push(productView);
                    } else {
                        this.selectedProducts = this.selectedProducts.filter(
                            (v) => v.name !== productView.name
                        );
                    }
                    // product.active = false;
                }, err => console.log(err)
            );
            this.products.push(productView);
        }

        for (const selectProd of this.classDetails.selected) {
            for (const prod of this.products) {
                if (prod.name === selectProd.name) {
                    prod.control.patchValue(true);
                }
            }
        }

        this.searchProduct.valueChanges.subscribe(
            data => {
                this.filterProducts(data);
            },
            err => console.log(err)
        );
    }

    public loadProducts(term) {
        // load products
        console.log(term);
    }

    public addControls(rawProducts) {
        for (const product of rawProducts) {
            const control = new FormControl(false);
            control.valueChanges.subscribe(
                data => {
                    // do smth
                    console.log(data);
                    // product.active = false;
                }, err => console.log(err)
            );
            product['control'] = control;
        }
    }

    public deselectProduct(product) {
        product.control.patchValue(false);
    }

    public productCheck(event, product, i) {
        console.log(event);
        console.log(product);
        console.log(i);
    }

    public selectAll() {
        this.deselectAll();
        for (const item of this.products) {
            item.control.patchValue(true);
        }
    }

    public deselectAll() {
        for (const item of this.products) {
            item.control.patchValue(false);
        }
    }

    public confirm() {
        this.activeModal.close({ selected: this.selectedProducts });
    }

    public selectedContain(name) {
        for (const prod of this.selectedProducts) {
            if (prod.name === name) {
                return true;
            }
        }
        return false;
    }

    public filterProducts(filterTerm) {
        const filteredProducts = [];
        for (const prod of this.classDetails.data) {
            if (prod.indexOf(filterTerm) !== -1) {
                const selected = this.selectedContain(prod);
                const control = new FormControl(selected);
                const productView = {
                    name: prod,
                    active: selected,
                    control: control
                };
                control.valueChanges.subscribe(
                    data => {
                        productView.active = data;
                        if (data) {
                            this.selectedProducts.push(productView);
                        } else {
                            this.selectedProducts = this.selectedProducts.filter(
                                (v) => v.name !== productView.name
                            );
                        }
                    }, err => console.log(err)
                );
                filteredProducts.push(productView);
            }
        }
        this.products = filteredProducts;
    }
}
