import { UnDrupalizeObject } from "app/shared/common/undrupalize-object";
import { LANGUAGES_OPTIONS } from "app/shared/components/user-editor-block/models/user-editor-block.constants";
import { GetCountriesService } from "app/shared/services/get-countries.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { UserServicesService } from "../services/user-services.service";

@Component({
    selector: "app-user-details",
    templateUrl: "./user-details.component.html",
    styleUrls: ["./user-details.component.scss"],
})
export class UserDetailsComponent implements OnInit {
    public userId;
    public user;

    public services;

    public countries = [];
    public userForm;
    userLanguages = LANGUAGES_OPTIONS;

    constructor(
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private countriesService: GetCountriesService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private service: UserServicesService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        console.log(this.route.snapshot.data.user);
        if (this.route.snapshot.data.user) {
            this.user = UnDrupalizeObject.format(this.route.snapshot.data.user);
            this.userId = this.user.uid;

            this.services = [
                {
                    title: "TRADEMARKS.APPLICATION_LIST",
                    name: "application",
                    path: "/admin/profit/application_full/list/all",
                    filters: { user_id: this.userId },
                    columns: [
                        {
                            name: "COMMON.NUMBER",
                            prop: "name",
                            sortable: false,
                            isLink: true,
                        },
                        {
                            name: "COMMON.TM_NAME",
                            prop: "tm_name",
                            sortable: false,
                        },
                        {
                            name: "TRADEMARKS.TERM",
                            prop: "field_registaration_period_raw",
                            sortable: false,
                            prettify: true,
                        },
                        {
                            name: "COMMON.STATUS",
                            prop: "state",
                            sortable: false,
                            prettify: true,
                        },
                        {
                            name: "",
                            prop: "",
                            sortable: false,
                            isViewBtn: true,
                        },
                    ],
                },
                {
                    title: "REGISTERED_TMS.TRADEMARKS_LIST",
                    name: "trademark",
                    path: "/admin/profit/trademark/list",
                    filters: { user_id: this.userId },
                    columns: [
                        {
                            name: "COMMON.NUMBER",
                            prop: "tm_name",
                            canAutoResize: false,
                            isLink: true,
                        },
                        {
                            name: "TRADEMARKS.REGISTRATION_NUMBER",
                            prop: "registration_number",
                            canAutoResize: false,
                        },
                        {
                            name: "TRADEMARKS.SUBMIT_DATE",
                            prop: "submission_date",
                            maxWidth: 130,
                            canAutoResize: false,
                            isDate: true,
                        },
                        {
                            name: "COMMON.REGISTRATION_DATE",
                            prop: "registration_date",
                            width: 130,
                            canAutoResize: true,
                            isDate: true,
                        },
                        {
                            name: "COMMON.ACTIVITY_END",
                            prop: "expiration_date",
                            canAutoResize: false,
                            isDate: true,
                        },
                        {
                            name: "",
                            prop: "",
                            sortable: false,
                            isViewBtn: true,
                        },
                    ],
                },
                {
                    title: "IP.COPYRIGHT_LIST_TITLE",
                    name: "copyright",
                    path: "/admin/profit/copyright/list/",
                    filters: { user_id: this.userId },
                    columns: [
                        {
                            name: "COMMON.NUMBER",
                            prop: "name",
                            maxWidth: 100,
                            isLink: true,
                        },
                        {
                            name: "COMMON.NAME",
                            prop: "small_title",
                            minWidth: 130,
                        },
                        {
                            name: "IP.OBJECT",
                            prop: "cp_object",
                            prettify: true,
                        },
                        { name: "IP.FIELD", prop: "branch" },
                        { name: "COMMON.DATE", prop: "created", isDate: true },
                        {
                            name: "",
                            prop: "",
                            sortable: false,
                            isViewBtn: true,
                        },
                    ],
                },
                {
                    title: "IP.IND_DESIGN_LIST_TITLE",
                    name: "design_patent",
                    path: "/admin/profit/industrial_design/list/",
                    filters: { user_id: this.userId },
                    columns: [
                        { name: "COMMON.NUMBER", prop: "name", isLink: true },
                        { name: "COMMON.NAME", prop: "po_name" },
                        // { name: 'Контакти', cellTemplate: this.contactsTmpl, minWidth: 180 },
                        {
                            name: "COMMON.SUBMIT_DATE",
                            prop: "created",
                            isDate: true,
                        },
                        {
                            name: "",
                            prop: "",
                            sortable: false,
                            isViewBtn: true,
                        },
                    ],
                },
                {
                    title: "IP.PATENTS_LIST_TITLE",
                    name: "invention",
                    path: "/admin/profit/invention/list/",
                    filters: { user_id: this.userId },
                    columns: [
                        {
                            name: "COMMON.NUMBER",
                            prop: "name",
                            maxWidth: 90,
                            isLink: true,
                        },
                        { name: "COMMON.NAME", prop: "invention_name" },
                        {
                            name: "IP.INVENTON_TYPE",
                            prop: "invention_type",
                            maxWidth: 120,
                            prettify: true,
                        },
                        {
                            name: "IP.PATENT_TYPE",
                            prop: "patent_type",
                            maxWidth: 120,
                            prettify: true,
                        },
                        // { name: 'Контакти', cellTemplate: this.contactsTmpl },
                        {
                            name: "COMMON.SUBMIT_DATE",
                            prop: "created",
                            maxWidth: 120,
                            isDate: true,
                        },
                        {
                            name: "",
                            prop: "",
                            sortable: false,
                            isViewBtn: true,
                        },
                    ],
                },
                {
                    title: "DESIGN.LOGO_LIST_TITLE",
                    name: "logo",
                    path: "/admin/profit/logo/list",
                    filters: { user_id: this.userId },
                    columns: [
                        {
                            name: "COMMON.NUMBER",
                            prop: "name",
                            maxWidth: 110,
                            isLink: true,
                        },
                        { name: "COMMON.NAME", prop: "word" },
                        {
                            name: "TRADEMARKS.TERM",
                            prop: "speed",
                            prettify: true,
                        },
                        // { name: 'Контакти', cellTemplate: this.contactTmpl, minWidth: 150},
                        {
                            name: "COMMON.SUBMIT_DATE",
                            prop: "created",
                            isDate: true,
                        },
                        {
                            name: "",
                            prop: "",
                            sortable: false,
                            isViewBtn: true,
                        },
                    ],
                },
                {
                    title: "DESIGN.NAMING_LIST_TITLE",
                    name: "naming",
                    path: "/admin/profit/naming/list",
                    filters: { user_id: this.userId },
                    columns: [
                        {
                            name: "COMMON.NUMBER",
                            prop: "name",
                            maxWidth: 110,
                            isLink: true,
                        },
                        {
                            name: "DESIGN.AMOUNT_PACKAGE",
                            prop: "naming_variants",
                            prettify: true,
                        },
                        // { name: 'Контакти', cellTemplate: this.contactTmpl, minWidth: 200},
                        {
                            name: "COMMON.SUBMIT_DATE",
                            prop: "created",
                            maxWidth: 150,
                            isDate: true,
                        },
                        {
                            name: "",
                            prop: "",
                            sortable: false,
                            isViewBtn: true,
                        },
                    ],
                },
                {
                    title: "DESIGN.BRANDBOOK_LIST_TITLE",
                    name: "brandbook",
                    path: "/admin/profit/brandbook/list",
                    filters: { user_id: this.userId },
                    columns: [
                        {
                            name: "COMMON.NUMBER",
                            prop: "name",
                            maxWidth: 110,
                            isLink: true,
                        },
                        {
                            name: "DESIGN.COMPANY",
                            prop: "company_name",
                            maxWidth: 240,
                        },
                        {
                            name: "DESIGN.PACKAGE",
                            prop: "package",
                            maxWidth: 110,
                            prettify: true,
                        },
                        // { name: 'Контакти', cellTemplate: this.contactTmpl, minWidth: 150},
                        {
                            name: "COMMON.SUBMIT_DATE",
                            prop: "created",
                            maxWidth: 150,
                            isDate: true,
                        },
                        {
                            name: "",
                            prop: "",
                            sortable: false,
                            isViewBtn: true,
                        },
                    ],
                },
                {
                    title: "DESIGN.IDENTITY_LIST_TITLE",
                    name: "identity",
                    path: "/admin/profit/identity/list",
                    filters: { user_id: this.userId },
                    columns: [
                        {
                            name: "COMMON.NUMBER",
                            prop: "name",
                            maxWidth: 110,
                            isLink: true,
                        },
                        {
                            name: "DESIGN.COMPANY",
                            prop: "company_name",
                            maxWidth: 240,
                        },
                        {
                            name: "DESIGN.PACKAGE",
                            prop: "package",
                            maxWidth: 110,
                            prettify: true,
                        },
                        // { name: 'Контакти', cellTemplate: this.contactTmpl, minWidth: 150},
                        {
                            name: "COMMON.SUBMIT_DATE",
                            prop: "created",
                            maxWidth: 150,
                            isDate: true,
                        },
                        {
                            name: "",
                            prop: "",
                            sortable: false,
                            isViewBtn: true,
                        },
                    ],
                },
                {
                    title: "OBJECTIONS.OBJECTIONS_LIST",
                    name: "objection",
                    path: "/admin/profit/objection-orders/list",
                    filters: { user_id: this.userId },
                    columns: [
                        {
                            name: "COMMON.APP",
                            prop: "tm_name",
                            canAutoResize: false,
                            isLink: true,
                        },
                        {
                            name: "COMMON.OWNER",
                            prop: "owner_info",
                            cellClass: "pre-wrap",
                        },
                        // { name: 'Клієнт', cellTemplate: this.contactPersonTmpl},
                        {
                            name: "COMMON.CREATED",
                            prop: "created",
                            isDate: true,
                        },
                        {
                            name: "",
                            prop: "",
                            sortable: false,
                            isViewBtn: true,
                        },
                    ],
                },
                {
                    title: "TM_TRANSFER.TRANSFERS_LIST",
                    name: "tm_transfer",
                    path: "/admin/profit/tm_transfer/list/all",
                    filters: { user_id: this.userId },
                    columns: [
                        {
                            name: "COMMON.APP",
                            prop: "tm_name",
                            canAutoResize: false,
                            isLink: true,
                        },
                        {
                            name: "COMMON.BUYER",
                            prop: "buyer_contact_person",
                            cellClass: "pre-wrap",
                        },
                        // { name: 'Користувач', cellTemplate: this.contactPersonTmpl},
                        {
                            name: "COMMON.CREATED",
                            prop: "created",
                            isDate: true,
                        },
                        {
                            name: "",
                            prop: "",
                            sortable: false,
                            isViewBtn: true,
                        },
                    ],
                },
            ];

            this.userForm = this.formBuilder.group({
                field_phone: ["", Validators.required],
                mail: ["", Validators.required],
                field_forename: [""],
                field_surname: [""],
                field_po_batkovi: [""],
                field_country: ["UA"],
                pass: [""],
                field_viber_number: [""],
                field_person: [""],
                preferred_langcode: ["uk"],
            });

            this.countriesService.getAllCountries().subscribe(
                (data) => {
                    this.countries = data;
                },
                (err) => console.log(err)
            );
            this.userForm.patchValue(this.user);
        }
    }

    public updateUser() {}

    public save() {
        if (!this.userForm.valid) {
            this.translate
                .get("USERS.ENTER_PHONE_AND_EMAIL")
                .subscribe((res: string) => {
                    this.toastr.error(res);
                });
            return;
        }
        const result = { ...this.userForm.value };
        this.spinner.show();
        this.service.saveUser(this.userId, result).subscribe(
            (data) => {
                this.spinner.hide();
                this.translate
                    .get("USERS.USER_DATA_SAVED")
                    .subscribe((res: string) => {
                        this.toastr.success(res);
                    });
            },
            (err) => {
                this.spinner.hide();
                console.log(err);
                this.translate
                    .get("USERS.ERROR_SAVING_USER_DATA")
                    .subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                this.toastr.error(err.error ? err.error.message : "");
            }
        );
    }
}
