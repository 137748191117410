import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { DomainsListComponent } from './domains-list/domains-list.component';
import { HostingsListComponent } from './hostings-list/hostings-list.component';

const domainsHostingsRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'domains',
                children: [
                    // {
                    //     path: 'new',
                    //     component: NewApplicationComponent,
                    //     data: {
                    //         title: 'Нова заявка'
                    //     }
                    // },
                    // {
                    //     path: ':id',
                    //     component: AppDetailsComponent,
                    //     data: {
                    //         title: 'Заявка'
                    //     }
                    // },
                    // {
                    //     path: 'clone/:id',
                    //     component: AppCloneFormComponent,
                    //     data: {
                    //         title: 'Клонування заявки'
                    //     }
                    // },
                    // {
                    //     path: 'edit/applicant/:id',
                    //     component: ApplicantEditComponent,
                    //     data: {
                    //         title: 'Редагування заявника'
                    //     }
                    // },
                    {
                        path: '',
                        component: DomainsListComponent,
                        data: {
                            title: 'Домени'
                        }
                    }
                ]
            },
            {
                path: 'hostings',
                children: [
                    {
                        path: '',
                        component: HostingsListComponent,
                        data: {
                            title: 'Хостінги'
                        }
                    }
                ]
            },
            {
                path: '',
                redirectTo: 'domains',
                pathMatch: 'full'
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(domainsHostingsRoutes)],
    exports: [RouterModule]
})
export class DomainsHostingsRoutingModule { }


