import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

import { AreYouSureComponent } from './components/are-you-sure/are-you-sure.component';
import { ClassesDetailsChooserComponent } from './components/classes-details-chooser/classes-details-chooser.component';
import { ClassesEditorComponent } from './components/classes-editor/classes-editor.component';
import { InvoiceChooserComponent } from './components/invoice-chooser/invoice-chooser.component';
import { PaymentRequestEditorComponent } from './components/payment-request-editor/payment-request-editor.component';
import { ReminderFormComponent } from './components/reminder-form/reminder-form.component';
import { SendViberMessageComponent } from './components/send-viber-message/send-viber-message.component';
import { UserEditorBlockComponent } from './components/user-editor-block/user-editor-block.component';
import { UserEditorComponent } from './components/user-editor/user-editor.component';
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UserCabinetComponent } from './user-cabinet/user-cabinet.component';

@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        NgbModule,
        UserCabinetComponent,
        ClassesDetailsChooserComponent,
        ClassesEditorComponent,
        PaymentRequestEditorComponent,
        AreYouSureComponent,
        SendViberMessageComponent,
        UserEditorBlockComponent,
        TranslateModule,
        SafeHtmlPipe
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        TranslateModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        UserEditorComponent,
        UserCabinetComponent,
        ClassesDetailsChooserComponent,
        ClassesEditorComponent,
        PaymentRequestEditorComponent,
        InvoiceChooserComponent,
        AreYouSureComponent,
        SendViberMessageComponent,
        ReminderFormComponent,
        UserEditorBlockComponent,
        SafeHtmlPipe
    ]
})
export class SharedModule {}
