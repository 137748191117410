import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/shared/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    public phoneValid: boolean;
    public phone = '';

    constructor(
        private authService: AuthService,
        public activeModal: NgbActiveModal,
        public spinner: NgxSpinnerService,
        private toastr: ToastrService,
        public translate: TranslateService
    ) { }

    ngOnInit() {
    }

    hasError(e) {
        this.phoneValid = e;
    }

    telInputObject(object) {}

    phoneChange(phone) {
        this.phone = phone;
    }

    sendPassword() {
        if (!this.phoneValid) {
            this.translate.get('LOGIN.WRONG_PHONE_FORMAT').subscribe((res: string) => {
                this.toastr.error(res);
            });
        } else {
            const number = this.phone.replace(/\+/g, '');

            this.spinner.show();
            this.authService.sendPassword(number).subscribe(
                data => {
                    this.translate.get('LOGIN.PASSWORD_CHANGED').subscribe((res: string) => {
                        this.toastr.success(res);
                    });
                    this.spinner.hide();
                    this.activeModal.close();
                },
                err => {
                    this.translate.get('LOGIN.ERROR_CHANGEIN_PASSWORD').subscribe((res: string) => {
                        this.toastr.error(res);
                    });
                    this.toastr.error(err.error ? err.error.message : '');
                    console.log('error during password change ', err);
                    this.spinner.hide();
                    this.activeModal.close();
                }
            );
        }
    }
}
