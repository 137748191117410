export function copyToClipboard(text) {
    return new Promise((resolve, reject) => {
        if (
            typeof navigator !== "undefined" &&
            typeof navigator.clipboard !== "undefined" &&
            typeof navigator.permissions !== "undefined"
        ) {
            const type = "text/plain";
            const blob = new Blob([text], { type });
            const data = [new ClipboardItem({ [type]: blob })];
            setTimeout(() => {
                navigator.clipboard.write(data).then(resolve, (err) => {
                    console.error("Failed to copy text to clipboard", err);
                    reject(err);
                });
            }, 0);
        } else if (
            document.queryCommandSupported &&
            document.queryCommandSupported("copy")
        ) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";
            textarea.style.width = "2em";
            textarea.style.height = "2em";
            textarea.style.border = "none";
            textarea.style.outline = "none";
            textarea.style.boxShadow = "none";
            textarea.style.background = "transparent";
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            try {
                document.execCommand("copy");
                document.body.removeChild(textarea);
                resolve(true);
            } catch (e) {
                document.body.removeChild(textarea);
                reject(e);
            }
        } else {
            reject(
                new Error(
                    "None of copying methods are supported by this browser!"
                )
            );
        }
    });
}
